// External imports
import React from 'react';
import {
  Paper,
  Typography,
  IconButton,
  Grid,
  FormControl,
  Button,
  InputLabel,
  Select,
  Modal,
  withStyles,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// Internal imports
import ButtonSpinner from '#/Common/ButtonSpinner.jsx';
import {
  checkLicensingSetupData,
  INDIVIDUAL,
  BUSINESS,
} from '~/app/Pages/Setup/ManageLicencing/manageLicenseHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import { styles as pagedTableStyles } from '~/app/Components/Common/css/pagedTableStyles.jsx';

/**
 * AssignLicenseModal Component
 * Modal to Assign a license
 * @component
 *
 */
const AssignLicenseModal = props => {
  const [state, setState] = useSetState({
    officeID: 0,
  });

  const licenseSetup =
    props.licenseType === INDIVIDUAL
      ? props.licensingSetup.licensingSetup1040
      : props.licensingSetup.licensingSetupBusiness;
  const SELECTED_LICENSE_VALUE = checkLicensingSetupData(licenseSetup, props.selectedKey);

  const createOfficeDropDownList = () => {
    if (props.offices?.length) {
      const dropdown = [];
      dropdown.push(<option aria-label="None" value="" key={'noneKeyIndexdropdown'} />);
      props.offices
        .filter(office => !office?.isFeeder)
        .map((office, index) => {
          let isLicensedAssigned;
          if (props.licenseType === INDIVIDUAL) {
            isLicensedAssigned = office.licenseID1040 !== '';
          } else {
            isLicensedAssigned = office.licenseIDBusiness !== '';
          }

          const assigned = !isLicensedAssigned ? 'Unassigned' : 'License Assigned';
          // 1040 license must be assigned prior to being able to assign a business license to it
          const canAssignBusinessLicense =
            props.licenseType === BUSINESS ? office.licenseID1040 !== '' : true;

          dropdown.push(
            <option
              value={office.office_id}
              key={`${office.office_id}-${index}`}
              disabled={isLicensedAssigned || !canAssignBusinessLicense}
              className="option-license-assign"
            >
              {office.officeName} - {office.efin} ({assigned})
            </option>,
          );
          return office;
        });

      return dropdown;
    }
  };

  return (
    <Modal open={props.isAssignLicenseModalOpen} disableBackdropClick={true}>
      <Paper elevation={5} style={styles.settingsModals}>
        <Typography id="simple-modal-title" style={styles.modalHeaderBar}>
          Assign License
          <IconButton aria-label="Close" style={styles.closeIconModal} onClick={props.closeModal}>
            <CloseIcon />
          </IconButton>
        </Typography>

        <Grid container>
          <Grid item xs={12} style={styles.gridTableHeader}>
            License Info
          </Grid>

          <Grid item xs={12}>
            <Grid container style={styles.gridSubTable}>
              <Grid item xs={6} style={styles.gridColumnHeader}>
                LICENSE ID
              </Grid>
              <Grid item xs={3} style={styles.gridColumnHeader}>
                Season
              </Grid>
              <Grid item xs={3} style={styles.gridColumnHeader}>
                STATUS
              </Grid>
              <Grid item xs={6} style={styles.gridColumnData}>
                {SELECTED_LICENSE_VALUE.licenseID}
              </Grid>
              <Grid item xs={3} style={styles.gridColumnData}>
                {SELECTED_LICENSE_VALUE.season}
              </Grid>
              <Grid item xs={3} style={styles.gridColumnData}>
                {props.getLicenseStatusText(SELECTED_LICENSE_VALUE.officeID)}
              </Grid>

              <Grid item xs={12} style={styles.modalDivider}>
                <Divider />
              </Grid>
              {props.drillDownLoginRoleID === 10 && (
                <p>
                  By assigning a license, you agree to being charged for assigning the license at
                  the end of the tax season.
                </p>
              )}
              {props.licenseType === BUSINESS && (
                <p>
                  <b>Requirement:</b> An office must have an XLCO license assigned to it before a
                  business license COBU or COBX can be assign.
                </p>
              )}
              <Grid item xs={12}>
                <FormControl style={styles.officeDropdown}>
                  <InputLabel htmlFor="office-select">Assign License To</InputLabel>
                  <Select
                    native
                    value={state.officeID}
                    inputProps={{
                      name: 'office',
                      id: 'office-select',
                    }}
                    disableUnderline
                    onChange={event =>
                      setState({
                        officeID: event.target.value,
                      })
                    }
                  >
                    {createOfficeDropDownList()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container style={styles.footerDivider} alignItems="flex-end" justify="flex-end">
                <Grid item xs={8} style={styles.justifyRight}>
                  {/* Spacer */}
                </Grid>
                <Grid item xs={2} style={styles.justifyRight}>
                  <Button
                    onClick={() => {
                      props.closeAssignOfficeModal();
                      setState({ officeID: 0 }); // cleanup - we do not want the selection to persist when they close or assign a license
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2} style={styles.justifyRight}>
                  <ButtonSpinner
                    buttonText="Save"
                    isLoading={state.editingLicense}
                    onClick={() => {
                      props.assignLicenseToOffice(state.officeID);
                      setState({ officeID: 0 }); // cleanup - we do not want the selection to persist when they close or assign a license
                    }}
                    disabled={state.officeID === 0}
                    spinnerColor="#ffffff"
                    color="primary"
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default withStyles({ ...styles, ...pagedTableStyles })(AssignLicenseModal);
