// External imports
import React, { Component, Fragment } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import {
  Modal,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
  Snackbar,
  TextField,
  MenuItem,
  Typography,
  Menu,
  Slide,
} from '@material-ui/core';
import { jsPDF as JSPDF } from 'jspdf';
import { MuiThemeProvider } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import moment from 'moment';
import { EventEmitter } from 'events';
import update from 'immutability-helper';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';
import get from 'lodash-es/get';
// Internal imports
import ErrorHelpers from '~/app/errorHelpers.js';
import appTheme from '~/themes/GenericTheme.jsx';
import { selectors as returnProfileSelectors } from '~/app/redux/returnProfile/selectors';
import {
  HIERARCHY_TYPE,
  SPINNER_DIALOGS,
  CALC_TYPE,
  FORM_NAMES,
  SIDE_TABS,
  DASHBOARD_TITLE_TABS,
  VERIFY_MODAL_TABS,
  PRINT_TYPES,
  PRINT_PREFS,
  PRINT_ACTION_TYPES,
  SIGNATURE_PAD_TYPES,
  TEXTLINK_ERROR_MESSAGE,
  SIGNEE_TYPE,
  PRINT_TYPES_MAP,
  PERMANENT_DOUBLE_ENTRY_FIELDS,
  PRESET_FORMATTING_TRIMMED,
  PRESET_PERCENT_RATIO,
  DECIMAL_FIELDS,
  REMOVE_FORMATTING_FIELDS,
  ENCRYPTED_PDF_ERROR_MESSAGES,
  FORM_TYPE_VAR,
  REQUEST_PDF_MSG,
  IRREMOVABLE_FORMS,
} from '~/app/constants';
import XlinkAPI from '~/app/api/xlinkAPI';
import CalcAPI from '~/app/api/calcAPI';
import ErrorRejectModal from './components/ErrorRejectModal.jsx';
import AddFormModal from './components/AddFormModal.jsx';
import K1ManagerModal from './components/K1Manager/K1ManagerModal.jsx';
import ChoiceListModal from './components/ChoiceList/ChoiceListModal.jsx';
import RemoteInvoiceModal from './components/RemoteInvoice/RemoteInvoiceModal.jsx';
import SideBarForms from './components/SideBarForms/SideBarForms.jsx';
import HeaderBarForms from './components/HeaderBarForms/HeaderBarForms.jsx';
import Spinner from '#/Common/Spinner.jsx';
import PrintComponents from '~/app/Pages/Returns/components/Print/PrintComponents.jsx';
import PrintStateAcks from '~/app/Pages/Returns/components/Print/PrintStateAcks.jsx';
import PrintStateClientLetters from '~/app/Pages/Returns/components/Print/PrintStateClientLetters.jsx';
import QueueReturn from './components/Efile/QueueReturn.jsx';
import AttachDocumentModal from './components/Document/AttachDocumentModal/AttachDocumentModal.jsx';
import ImportStockTransactions from './components/ImportStockTransactions/ImportStockTransactions.jsx';
import AssetList from './components/Depreciation/AssetList.jsx';
import SignatureCapture from './components/SignatureCapture.jsx';
import TopazSignatureCapture from './components/TopazSignatureCapture.jsx';
import ScriptelSignatureCapture from './components/ScriptelSignatureCapture.jsx';
import TextLinkSendModal from './components/TextLinkSendModal.jsx';
import TextLinkReceivedModal from './components/TextLinkReceivedModal/TextLinkReceivedModal.jsx';
import TaxpassMessageModal from './components/Taxpass/TaxpassMessageModal.jsx';
import PrintDialog from '~/app/Pages/Returns/components/Print/PrintActionsDialog.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import UtilityAPI from '~/app/api/utilityAPI';
import SuggestedPasswordSaveDialog from '~/app/Pages/Returns/components/Print/SuggestedPasswordSave.jsx';
import SuggestedPasswordDialog from '~/app/Pages/Returns/components/Print/SuggestedPasswordDialog.jsx';
import SuggestedPasswordDoc from '~/app/Pages/Returns/components/Print/SuggestedPasswordDoc.jsx';
import CapturePayment from '~/app/Pages/Payments/PaymentModal/CapturePayment.jsx';
import { sendSignatureRequest } from '~/app/Pages/Returns/pages/Signatures/signaturesHelper.js';
import MessageDialog from './components/MessageDialog/MessageDialog.jsx';
import TransferData from '~/app/Pages/Returns/components/TransferData/TransferData.jsx';
import ArchivedDocuments from './components/Document/ArchivedDocuments/ArchivedDocuments.jsx';
import EventLog from './components/Events/EventLog.jsx';
import AddAsset from './components/Depreciation/AddAsset.jsx';
import Signatures from '~/app/Pages/Returns/pages/Signatures/Signatures.jsx';
import SubformViewer from './components/SubFormViewer/SubformViewer.jsx';
import Notifications from '~/app/Components/Common/Notifications/Notifications.jsx';
import RefreshToken from '~/app/Components/Common/RefreshToken.jsx';
import SocketWorker from '~/socket.worker.js';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import { toBMPDataURL, sigTypes } from '~/app/bmpConversionHelpers.js';
import BookmarkField from './components/BookmarkField.jsx';
import Bookmarks from './components/Bookmarks.jsx';
import ReturnNotes from './components/ReturnNotes.jsx';
import VisionAssistModal from './components/VisionAssist/VisionAssistModal.jsx';
import { handleSuddenCloseWindow } from '~/app/webHelpers';
import {
  fieldCharSetValidation,
  isValidCRYFieldFormat,
  checkActionKeys,
  stripSpecialChars,
  fixNegatives,
} from './components/helpers/fieldCharSetValidationHelper.js';
import { updateReadOnlyFieldStatus } from '~app/Pages/Setup/RestrictedFields/helpers/restrictedFieldsHelpers.js';
import { openWallet, getWallet } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
import { tabListDefinitions } from './DataModel/FormViewer';
import AccountInfoBanner from '~/app/Components/Common/AccountInfoBanner/AccountInfoBanner.jsx';
import FieldInfo from '~/app/Pages/Returns/components/FieldInfo/fieldInfo.jsx';
import { isShortScreenRez } from '~/app/Utility/browser';
import { isSuperOrTechUserReadOnly, translateSpecialOccuranceRow } from '~/app/Utility/general.js';
import PreviewTermsAndConditions from '~/app/Pages/TermsAndConditions/PreviewTermsAndConditions.jsx';
// Redux imports
import { connect } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as overviewActions } from '~/app/redux/modules/overview';
import { actions as messageActions } from '~/app/redux/modules/messages';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { actions as remoteSignActions } from '~/app/redux/remoteSign/duck';
import { actions as remoteDocActions } from '~/app/redux/modules/remoteDocRequests.js';
import { actions as returnListActions } from '~/app/redux/returnList/duck';
import { actions as defaultsActions } from '~/app/redux/setupPages/defaultsPage/duck';
import { actions as wizardEstimatorActions } from '~/app/redux/modules/wizardEstimator.js';
import { store } from '~/app/redux/index';
// Styling imports
import { styles } from './css/formViewer.js';
import './css/formViewer.css';
// Image imports
import downloadIcon from '~/images/icons/downloadIcon.svg';
import lockIcon from '~/images/icons/icons8-lock_2.svg';
import menuVertical from '~/images/icons/menu-vertical-filled.png';

const rtfToHTML = require('@iarna/rtf-to-html');

const SPIN_GENERIC = 'Loading...';
const SPIN_SAVE_MSG = 'Saving Tax Return...';
const SPIN_DISCARD_MSG = 'Discarding Tax Return...';
const SPIN_LOAD_MSG = 'Loading Form...';
const SPIN_LOCKED_CLOSE = 'Closing Tax Return...';
const SPIN_LOCKED_DELETE = 'Deleting Tax Return...';
const NOTES_REMINDER = 'INFO: Please review return notes';

// artificial delay for onClose() to allow synchronous DB operations to complete when discarding
const FieldFocusDelay = 500; // ms
const AutoSaveInterval = 120000; // ms
const NotesReminderMax = 8000; // ms
const TopOfFormPage = 0;

// regex used to check if field is part of a table
const tableRegExp = /^[A-Za-z]{2}[A-Za-z0-9`@]{2}/;
// Asset Form format (ex: A00001)- TODO: temporary, used for asset calc flag
const assetFormRegExp = /^[A]{1}[0-9]{5}/;
// Valid characters allowed in input
const validChars = /[a-zA-Z0-9-_ !@#$%^&*()-=+\\/|.,?~`'":;<>]/;

const ButtonMenuElement = ({ buttonItems, btnMenuOpen, openButtonMenu, closeButtonMenu }) => {
  return (
    <Fragment>
      <Button
        className="inputFormBtn"
        id="buttonMenuForReturns"
        aria-owns="btnMenuCtxReturn"
        aria-haspopup="true"
        style={{
          minWidth: '0.5rem',
          maxWidth: '0.5rem',
          minHeight: 'unset',
          padding: '0px 5px 0px 5px',
          border: '1px solid #0077FF',
          borderRadius: '0px 5px 5px 0px',
          boxShadow: 'none',
          position: 'absolute',
          '&:focus': {
            border: 'none',
            outline: 'none',
          },
        }}
        color="primary"
        variant="contained"
        onClick={openButtonMenu}
      >
        <img src={menuVertical} style={{ height: `1em` }} />
      </Button>
      <Menu
        id="btnMenuCtxReturn"
        open={btnMenuOpen}
        onClose={closeButtonMenu}
        anchorEl={document.getElementById('buttonMenuForReturns')}
        PaperProps={{
          style: {
            transform: 'translate(-50%, 2.3rem)',
          },
        }}
      >
        {buttonItems.map((btn, index) => {
          return (
            <MenuItem
              onClick={() => {
                closeButtonMenu();
                btn.func();
              }}
              key={'btnMenu' + index}
            >
              {btn.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    attachedStatesCount: returnProfileSelectors.getAttachedStatesCount(state),
    alert: state.app.alert,
    addFormModalOpen: state.formViewer.addFormModalOpen,
    choiceListModalOpen: state.formViewer.choiceListModalOpen,
    addDocumentModalOpen: state.formViewer.addDocumentModalOpen,
    documentsToBeAttached: state.formViewer.documentsToBeAttached,
    messageDialogOpen: state.formViewer.messageDialogOpen,
    messageDialogInfo: state.formViewer.messageDialogInfo,
    errorRejectModalOpen: state.formViewer.errorRejectModalOpen,
    showVerifySuccessDlg: state.formViewer.showVerifySuccessDlg,
    showVerifyModalSuccessDlg: state.formViewer.showVerifyModalSuccessDlg,
    eventLogModal: state.formViewer.eventLogModalOpen,
    isK1ManagerModalOpen: state.formViewer.isK1ManagerModalOpen,
    addFormList: state.formViewer.addFormList,
    activityList: state.formViewer.activityList,
    addAssetModal: state.formViewer.addAssetModalOpen,
    choiceListTitle: state.formViewer.choiceListTitle,
    choiceListIniField: state.formViewer.choiceListIniField,
    choiceListData: state.formViewer.choiceListData,
    choiceListFields: state.formViewer.choiceListFields,
    choiceListColumns: state.formViewer.choiceListColumns,
    isTransferDataModalOpen: state.formViewer.isTransferDataModalOpen,
    isCurrentYearModalOpen: state.formViewer.isCurrentYearModalOpen,
    pyReturns: state.formViewer.pyReturns,
    cyReturns: state.formViewer.cyReturns,
    existingReturnType: state.formViewer.existingReturnType,
    stateAcks: state.formViewer.stateAcks,
    attachedStates: state.formViewer.attachedStates,
    errorDialog: state.formViewer.errorDialogOpen,
    errorDialogMessage: state.formViewer.errorDialogMessage,
    errorDialogTitle: state.formViewer.errorDialogTitle,
    printDialog: state.formViewer.printDialogOpen,
    signaturePadTypeDialog: state.formViewer.signaturePadTypeDialogOpen,
    signatureCapture: state.formViewer.signatureCaptureModalOpen,
    topazSignatureCapture: state.formViewer.topazSignatureCaptureModalOpen,
    scriptelSignatureCapture: state.formViewer.scriptelSignatureCaptureModalOpen,
    clientName: state.returnProfile.clientName,
    clientFilingStatus: state.returnProfile.clientFilingStatus,
    activeForm: state.returnProfile.initialForm,
    activeFile: state.returnProfile.initialFile,
    attachments: state.returnProfile.attachments,
    returnID: state.returnProfile.returnID,
    friendlyID: state.returnProfile.friendlyID,
    loginID: state.app.loggedInUser.userID,
    isReadOnly: state.returnProfile.isReadOnly,
    lockedBy: state.returnProfile.lockedBy,
    lockedViaAccessLevel: state.returnProfile.lockedViaAccessLevel,
    isNew: state.returnProfile.isNew,
    startInterview: state.loginSetup.loginSettings.startInterview?.value,
    lockedStatus: state.returnProfile.lockedStatus,
    formList: state.returnProfile.formList,
    remoteSignatureSecondDialog: state.formViewer.remoteSignatureSecondDialogOpen,
    remoteSignatureDialog: state.formViewer.remoteSignatureDialogOpen,
    isRMSSigningMethod: state.formViewer.isRMSSigningMethod,
    isTrainingMode: state.loginSetup.isTrainingMode,
    textLinkModalOpen: state.formViewer.textLinkModalOpen,
    textLinkReceivedModalOpen: state.formViewer.textLinkReceivedModalOpen,
    isTextLinkActivated: state.formViewer.isTextLinkActivated,
    textLinkModalContent: state.formViewer.textLinkModalContent,
    textLinkModalContentOriginal: state.formViewer.textLinkModalContentOriginal,
    textLinkNotActivatedDlg: state.formViewer.textLinkNotActivatedDlg,
    textLinkNoClients: state.formViewer.textLinkNoClients,
    textlinkSendSuccess: state.formViewer.textlinkSendSuccess,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    ddh: state.drilldown.drilldownHistory,
    autoSaveAfterMins: state.defaultsPageState.autoSaveAfterMins,
    autoSaveEnabled: state.defaultsPageState.autoSaveEnabled,
    silentlySaveReturnOnClose: state.defaultsPageState.silentlySaveReturnOnClose,
    enableDoubleEntry: state.defaultsPageState.enableDoubleEntry,
    requireAutoAdd1040Sch123: state.defaultsPageState.requireAutoAdd1040Sch123,
    printPDFWarningMessageID: state.defaultsPageState.printPDFWarningMessageID,
    printPDFWarningMessage: state.defaultsPageState.printPDFWarningMessage,
    notificationsCount: state.notifications.notifications.length,
    notificationID: state.notifications.selectedNotificationID,
    notificationReturnID: state.notifications.selectedReturnID,
    estimatorBlob: state.formViewer.estimatorState
      ? state.formViewer.estimatorState.blob
      : undefined,
    estimatorDatastore: state.formViewer.estimatorState
      ? state.formViewer.estimatorState.datastore
      : undefined,
    addFormFirst: state.formViewer.addFormFirst,
    addFormPkg: state.formViewer.addFormPkg,
    attachmentsListMostRecent: state.formViewer.attachmentsListUpdated,
    submitForReviewDlg: state.formViewer.submitForReviewDlg,
    readyForReview: state.returnProfile.readyForReview,
    usingDemoAccount: state.returnProfile.usingDemoAccount,
    suggestedPswDialog: state.formViewer.suggestedPasswordModalOpen,
    encryptedPassword: state.formViewer.encryptedPassword,
    encryptedPdfTitle: state.formViewer.encryptedPdfTitle,
    suggestedPswSaveDialog: state.formViewer.suggestedPasswordSaveModalOpen,
    taxpayerEmailForPdf: state.formViewer.taxpayerEmailForPdf,
    spouseEmailForPdf: state.formViewer.spouseEmailForPdf,
    preparerEmailForPdf: state.formViewer.preparerEmailForPdf,
    encryptedPdfSelected: state.formViewer.encryptedPdfSelected,
    encryptedPdfDialogModalOpen: state.formViewer.encryptedPdfDialogModalOpen,
    emailBlob: state.formViewer.emailBlob,
    attachmentContent: state.formViewer.attachmentContent,
    taxpayerChecked: state.formViewer.taxpayerChecked,
    spouseChecked: state.formViewer.spouseChecked,
    representativeChecked: state.formViewer.representativeChecked,
    taxpayerEPassChecked: state.formViewer.taxpayerEPassChecked,
    spouseEPassChecked: state.formViewer.spouseEPassChecked,
    representativeEPassChecked: state.formViewer.representativeEPassChecked,
    sentSuccessTitle: state.formViewer.sentSuccessTitle,
    sentSuccessMsg: state.formViewer.sentSuccessMsg,
    missingInfoModalOpen: state.formViewer.missingInfoModalOpen,
    taxpayerCellPhoneNumber: state.formViewer.taxpayerCellPhoneNumber,
    taxpayerCellPhoneCarrier: state.formViewer.taxpayerCellPhoneCarrier,
    taxpayerCellPhoneDomain: state.formViewer.taxpayerCellPhoneDomain,
    spouseCellPhoneNumber: state.formViewer.spouseCellPhoneNumber,
    spouseCellPhoneCarrier: state.formViewer.spouseCellPhoneCarrier,
    spouseCellPhoneDomain: state.formViewer.spouseCellPhoneDomain,
    spouseInfoAvailable: state.formViewer.spouseInfoAvailable,
    taxpayerTextChecked: state.formViewer.taxpayerTextChecked,
    spouseTextChecked: state.formViewer.spouseTextChecked,
    representativeTextChecked: state.formViewer.representativeTextChecked,
    taxpayerAllowText: state.formViewer.taxpayerAllowText,
    spouseAllowText: state.formViewer.spouseAllowText,
    pdfModalSuccess: state.formViewer.pdfModalSuccess,
    remoteSignSuccessful: state.formViewer.remoteSignSuccessful,
    isSignatureRequestActive: state.formViewer.isSignatureRequestActive,
    onScreen: state.loginSetup.loginSettings.onScreen.value,
    scriptel: state.loginSetup.loginSettings.scriptel.value,
    topaz: state.loginSetup.loginSettings.topaz.value,
    preferredSignaturePad: state.formViewer.preferredSignaturePad,
    returnOpen: state.app.returnOpen,
    officeProfile: state.officeProfile,
    eroData: state.remoteSign.eroData,
    preparerData: state.remoteSign.preparerData,
    amendedPreparerData: state.remoteSign.amendedPreparerData,
    initialSigneeData: state.remoteSign.initialSigneeData,
    messageBadgeCount: state.overview.messageBadgeCount,
    isTaxPassReturn: state.returnProfile.isTaxPassReturn,
    taxpassMessageModalOpen: state.formViewer.taxpassMessageModalOpen,
    initialForm: state.returnProfile.initialForm,
    initialFile: state.returnProfile.initialFile,
    taxYear: state.returnProfile.year,
    verifyList: state.returnProfile.verifyList,
    rejectList: state.returnProfile.rejectList,
    importErrorList: state.returnProfile.importErrorList,
    bankRejectList: state.returnProfile.bankRejectList,
    alertList: state.returnProfile.alertList,
    errorNumber: state.returnProfile.errorNumber,
    warningNumber: state.returnProfile.warningNumber,
    primarySSNEIN: state.returnProfile.primarySSNEIN,
    isWizard: state.returnProfile.isWizard,
    wizardCompleted: state.returnProfile.wizardCompleted,
    wizardCurrentStep: state.wizardEstimator.wizardCurrentStep,
    returnStatus: state.returnProfile.returnStatus,
    setSubDialogListOpened: state.formViewer.setSubDialogListOpened,
    remoteInvoiceModalOpen: state.formViewer.remoteInvoiceModalOpen,
    activeErrorRejectTab: state.formViewer.activeErrorRejectTab,
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...formViewerActions,
  ...returnProfileActions,
  ...overviewActions,
  ...drilldownActions,
  ...messageActions,
  ...notificationActions,
  ...remoteSignActions,
  ...remoteDocActions,
  ...returnListActions,
  ...defaultsActions,
  ...wizardEstimatorActions,
};

/**
 * Page to display the tax return.
 *
 * @component FormViewer
 * @category Returns
 */
class FormViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleTaxpassReload: true, // Intended to just trigger a reload from formviewer. Instead of lifting state or putting taxpass message list in formviewer and creating more
      // clutter, this boolean will trigger a reload of taxpass messages by toggling true an false.
      websocketStatusConn: true, // TODO --> Change this to be a bit/number to represent the websocket conn status...Ready...Closed...Connecting...etc
      isLoading: true,
      returnPrintQueryLoading: false,
      confirmReturnClose: false,

      // Remove Form/Asset/Pkg dialog
      confirmDeleteDialogOpen: false,
      confirmDeleteReturn: false,
      confirmAssetDelete: false,
      confirmPkgDelete: false,
      removePkgId: null,
      removePkgDesc: null,
      removeFormId: null,
      removeFormDesc: null,
      removeFormDlg: null,
      removeFormIrremovable: false,
      formStack: [],

      readyForReview: this.props.readyForReview,
      noPrintDemoModal: false,
      activeForm: this.props.isWizard ? FORM_NAMES.EST : this.props.initialForm,
      activeFile: this.props.initialFile,

      // Errors, Rejects, Verifies
      activeErrorRejectTab: 0,
      verifyType: '',
      verifyDirty: false, // used to relaunch ErrorRejectModal after a field calc has occurred through any means (field calc, clear override, or choicelist)
      verifyField: '', // when verifyDirty, we will use this field to land on when loading form
      verifyForm: '', // Formname verify is called on
      verifyStmCode: '',
      verifyWksRan: false,

      // Depreciation
      selectedAsset: null,
      assetSnackbar: false,
      assetSnackbarMsg: '',
      assetMode: false,
      reassignAssetList: [],

      activeAddFormTab: 0,
      alert: {
        show: false,
      },
      loadingText: SPIN_GENERIC,
      currFieldBeforeChangeVal: null,
      prevFocusedFieldVal: null,
      prevField: null,
      specialFormLoaded: false,
      specialFormName: '',
      errorSnackbar: false,
      errorMsg: '',
      errVertical: 'top',
      errHorizontal: 'center',
      promptSnackbar: false,
      promptSnackbarMessage: '',

      // K-1 Manager
      k1List: [],
      k1pkgList: [],
      hideK1Flag: '',

      // ProForma / YearToYear
      useYtyOptions: false,

      // Printing related
      print: {
        pType: '',
        formOccur: '',
        prefs: {},
        overridePrefs: {},
        reqSigs: '',
        currentSig: '',
        signatures: {},
        ppSignature: '',
        efinSignature: '',
        ppxSignature: '',
        pAction: '',
        pActionEligible: '',
        val: '',
        menuText: '',
        cltr: 'English', // Default client letter to english
      },
      printStateAcksModal: false,
      isPrintStateAcksAmended: false,
      printStateClientLettersModal: false,
      printComponentsModal: false,
      openPrintDemoMessage: false,
      bookmarks: {
        bookmarkFldEditor: false,
        bookmarkFldID: '',
        bookmarkData: [],
        currBookmarkDesc: '',
        currBookmarkTimestamp: '',
      },
      bookmarkField: '',
      showFinalPDF: true,

      remoteSignature: {
        returnCurrentState: '',
        remoteSignReturnID: '',
        tpFirstName: '',
        tpLastName: '',
        tpDOB: '',
        tpLast4: '',
        tpEmail: '',
        tpCellPhone: '',
        tpCellCarrier: '',
        tpCellDomain: '',
        spFirstName: '',
        spLastName: '',
        spDOB: '',
        spLast4: '',
        spEmail: '',
        spCellPhone: '',
        spCellCarrier: '',
        spCellDomain: '',
      },
      btnCompletedRemoteSignClose: false,

      // Remote Signature Print
      remoteSignPrint: false,

      // Payment Capture
      paymentTerminalStatus: null,
      // Misc
      requireFilingStatusDlg: false,
      initialSpecialFormLoad: false,
      interviewMode: false, // May change this to handle constants instead incase we have more modes.
      transmitItemList: {},
      feeItemList: [],
      feeTotal: '',
      walletBallance: '',
      returnPaymentInfo: {},
      addAttachmentModalOpen: false,
      queueReturnModalOpen: false,
      autoSaveInterval: 0,
      returnPaymentOpen: false,
      menuButtonsChoices: [],
      notes: [],
      menuButtonOpen: false,
      anchorEl: null,
      notesFlag: false,
      notesAlert: false,
      dupeSSNCopyRtnOpen: false,
      showTextLinkSendErrorDialog: false,
      showSwitchReturnDlg: false,
      loadEstimatorState: false,
      showImportStock: false,
      importStockMsg: '',
      menuCommandResp: {},
      btnClickSpinner: false,
      openDemoMessage: false,
      calcErrorPopup: false,
      calcErrorTitle: '',
      calcErrorMsg: '',
      season: '',
      scrollPositionInit: true,
      encryptedPdfPrint: false,
      showDblBlind: false,
      showConsentForm: false,
      addInterviewModalFlag: false,
      addInterviewFlag: false,
      tabListDefinitions,
      bankID: 0,
      fieldFormLinks: {},
      unreadTextMessageCount: 0,
      interviewCurrentEleMap: new Map(), // Keeps track of last visited ele on each form (interview mode)
      currentOpenedAttachment: null,
      isPreviewModalOpen: false,
      isPDFAlertModalOpen: false,
      hasConfirmedClose: false,
      preparerID: 0,
      restrictedFieldData: null,
      isRequestDocumentModalOpen: false,
      returnSaved: false,
      dupeSSNCopyLiveRtnOpen: false,
      textAgreementLinkTerms: 'http://sigreq.tax/s/terms',
      textAgreementLinkPrivacy: 'http://sigreq.tax/s/privacy',
      wndwNote: '',
      isTextlinkPDFOpen: false,
      textlinkPDF: '',
      availablePackages: [],
      isVisionModalOpen: false,
      TVAprogress: 0,
      TVARetrieveCount: 0,
    };

    this.dblBlindList = [];

    this.eventEmitter = new EventEmitter();

    this.clientData = {
      first_name: '',
      last_name: '',
      filing_status: undefined,
    };
    this.socketWorker = undefined;
    this.calcAPI = undefined;
    this.valueMap = {};
    this.stmcode = '';
    this.subFrm = '';
    this.maxTableRow = 0; // Max Table Row # of worksheet
    this.lastRow = 0; // Last row for autoextend forms
    this.maintainAutoExtendFocus = false; // prevent focus change for autoextend form
    this.tabbedField = '';
    this.choices = '';
    this.nextField = null; // nextFld attribute of focused field
    this.previousEl = null; // previous element
    this.currentEl = null; // current Element
    this.dirtyFlag = false;
    this.fieldVal = ''; // value entered by user for a field for comparison
    this.prevFieldVal = ''; // prev value for double blind value restore
    this.colorMap = {};
    this.returnInitialized = false;
    XlinkAPI.canEditReturn().catch(() => {
      this.props.lockReturnByPermissions();
    });
    this.formContainerProps = {};
    this.verifyFormLoadCount = 0;
    this.prevPathName = undefined;
    this.sigDocID = 0;
    this.signatureRequestData = [];
    this.payload = WebHelpers.getJWTPayload();
  }

  /** Handles closing an active return a user is viewing. */
  onCloseActiveReturn = () => {
    const finishUp = async () => {
      this.props.setActiveReturnFormList();
    };

    this.props.resetRMDRequests();
    this.props.closeReturnProfile();
    this.props.clearActiveReturn();
    this.props.closeActiveReturn();

    // Close reject modal if it is open
    if (this.props.errorRejectModalOpen) {
      this.closeErrorRejectModal();
    }

    // Allows to go to another page.
    this.setState({ hasConfirmedClose: true });

    // Brings the user back to Tax Returns
    this.props.history.push({ pathname: '/tax-returns' });

    finishUp();
  };

  openButtonMenu = () => {
    this.setState({
      menuButtonOpen: true,
    });
  };

  closeButtonMenu = () => {
    this.setState({
      menuButtonOpen: false,
    });
  };

  /**
   * Handles closing the Preview PDF Modal is the close button is clicked
   *
   * @function
   */
  closePreviewModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  /**
   * Handles displaying Request PDF Modal message to user if they are attempting to download
   *
   * @function
   */
  openRequestPDFModal = () => {
    this.setState({ isPDFAlertModalOpen: true });
  };

  /**
   * Handles closing Request PDF Modal message if they Accept or Cancel
   *
   * @function
   */
  closeRequestPDFModal = () => {
    this.setState({ isPDFAlertModalOpen: false });
  };

  isLocked = () => {
    // some of these values are truthy/falsey, but we are wanting a boolean
    return !!(
      this.props.lockedBy ||
      this.props.lockedViaAccessLevel ||
      this.props.lockedStatus ||
      isSuperOrTechUserReadOnly(this.payload) ||
      // Feeder office and below cannot edit return that was submitted for review
      (this.props.officeProfile.is_feeder_office && this.state.readyForReview)
    );
  };

  formListReady = () => {
    return this.props.formList !== undefined && this.props.formList.length > 0;
  };

  /**
   * Determine return locked messaged base on locked by user,
   * locked via access level, or locked status.
   *
   * @function
   */
  getLockedMessage = () => {
    let msg;
    let lockedUser = '';

    if (isSuperOrTechUserReadOnly(this.payload)) {
      msg = 'Super and tech support users are restricted from editing the return';
    } else if (this.props.lockedBy) {
      msg = 'Return is being edited by ';
      lockedUser = <span>{this.props.lockedBy.toUpperCase()}</span>;
    } else if (this.props.officeProfile.is_feeder_office && this.state.readyForReview) {
      msg = 'Return has been submitted for review';
    } else if (this.props.lockedViaAccessLevel) {
      msg = 'Access level does not permit editing returns';
    } else if (this.props.lockedStatus) {
      msg = 'Return is in read only mode and has been locked';
    }

    return (
      <div style={{ paddingTop: '0.4em' }}>
        <span style={{ paddingLeft: '2em', textAlign: 'left' }}>{'NOTICE: ' + msg}</span>
        {lockedUser}
      </div>
    );
  };

  lockFieldsIfRequired = async () => {
    if (this.isLocked()) {
      const inputs = Array.from($('#taxform').find(':input'));
      inputs.forEach(x => {
        if (x.id && x.id !== '') {
          const obj = $(`[id="${x.id}"]`);
          // NOTE: id MUST be string-quoted to succesfully select IDs with dollar signs
          obj.addClass('lockedField');
          // obj.css('background-color', 'lightgrey')
          x.disabled = true;
        }
      });
    }
  };

  // checks to if return has a filing status entered on the CDS screen, if not notify user
  hasFLST = () => {
    // depending on what section they are in is what filing status should be used - wizards is masking clientFilingStatus
    const filingStatus = this.props.clientFilingStatus || this.props?.wizardHasFLST;
    if ((!filingStatus || filingStatus === '0') && !this.payload.is_business) {
      return false;
    }
    return true;
  };

  handleRequireFilingStatusDlg = () => {
    this.setState({ requireFilingStatusDlg: true });
  };

  // newSocketInit use this with caution. This will create new workers everytime this is called. Clean up before
  newSocketInit = (returnID = 0) => {
    if (returnID === 0) {
      returnID = this.props.returnID;
    }
    // Async websocket manager
    this.socketWorker = new SocketWorker();
    this.socketWorker.onmessage = this.onSocketResponse;
    this.calcAPI = new CalcAPI(
      this.socketWorker,
      parseInt(returnID),
      this.props.ddh,
      this.returnInitialized,
    );

    if (this.props?.setFormViewerCalcAPI) {
      this.props.setFormViewerCalcAPI(this.calcAPI);
    }
  };

  handleNewTextMessageNotification = async (prevUnreadTextMessageCount = 0) => {
    if (this.props.messageBadgeCount > 0) {
      try {
        const res = await XlinkAPI.getReturnMessagesByReturnID(this.props.returnID);
        if (statusOK(res) && res.data.length > 0) {
          const { data } = res;
          const unreadTextMessageCount = data.filter(m => m.is_read === false).length;

          this.setState({ unreadTextMessageCount });

          data.forEach(val => {
            val.date = moment(new Date(val.date)).format('M/DD/YYYY h:mm a');
          });
          this.props.updateMessageList(data);
          // Only show the new message notification if there is new unread messages
          // and not in the TextLink received modal.
          if (
            unreadTextMessageCount > 0 &&
            !this.props.textLinkReceivedModalOpen &&
            unreadTextMessageCount > prevUnreadTextMessageCount
          ) {
            this.props.showSnackbarMessage(
              `New TextLink Message${unreadTextMessageCount > 1 ? 's' : ''}`,
              'success',
            );
          }
        }
      } catch (e) {
        this.props.showErrorDialog('Error fetching messages list');
      }
    }
  };

  // handles the 'PDWX' global, after user initially clicks print PDF they'll be prompted with a warning
  // message only once and after 'PDWX' will be set to true in the DB.
  handlePrintPDFWarningMessage = async () => {
    const printPDFWarningMessage = {
      configID: this.props.printPDFWarningMessageID,
      configValue: '1',
    };
    const submitData = [printPDFWarningMessage];

    try {
      await XlinkAPI.updateDefaultsSetup(submitData, this.props.loginID);
      store.dispatch(defaultsActions.setPrintPDFWarningMessage(true));
    } catch (err) {
      this.props.showErrorDialog('Error Updating Default Setting');
    }
  };

  // The script isn't working
  componentDidMount = async () => {
    this.handleNewTextMessageNotification();
    // intitially sets email subject title for encrypted pdfs depending on the tax year of the return
    this.props.setEncryptedPdfTitle(this.props.taxYear + ' TAX RETURN');
    window.location.pathname === '/refund-calculator' &&
      (this.prevPathName = window.location.pathname);
    history.pushState(null, null, location.pathname);

    // FormViewer component loaded
    // Need this to determine if a return is open to auto save
    // when there is a new app version released.
    this.props.setReturnOpen(true);

    let demoAccountFlag = false;
    const accountCode = this.payload.account_code;
    if (accountCode === 'CLODMO') {
      demoAccountFlag = true;
    }
    this.props.setAccountType(demoAccountFlag);
    this.props.setReadyForReviewFilter(false);
    // Do not allow user to close window abruptly
    window.addEventListener('beforeunload', handleSuddenCloseWindow);
    // Fetch FormContainer properties
    const bootstrapJquery = document.createElement('script');
    bootstrapJquery.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js';
    bootstrapJquery.integrity =
      'sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa';
    bootstrapJquery.crossOrigin = 'anonymous';
    bootstrapJquery.id = 'btstrapJquerySrc';
    document.head.appendChild(bootstrapJquery);
    //
    // Button Element Hide on mounting formviewer and add a listener on window resize for moving the button.
    const el = document.getElementById('buttonMenuForReturns');
    el.style.display = 'none';
    window.addEventListener('resize', this.renderButtonChoice);

    if (
      this.props.currentView.role === HIERARCHY_TYPE.EFIN ||
      this.props.currentView.role === HIERARCHY_TYPE.PREPARER
    ) {
      XlinkAPI.isTextLinkActivated()
        .then(res => {
          this.props.setTextLinkActivationStatus(res.data.isConfigured);
        })
        .catch(() => {
          this.props.setTextLinkActivationStatus(false);
        });
    }
    this.newSocketInit();

    this.setState({ season: this.payload.season }, () => {
      // get header bar and verify for Guided Estimator
      if (this.props?.isWizard && this.props?.setFormViewerHeader) {
        this.props.setFormViewerHeader(this.renderHeaderBar());
      }
    });

    XlinkAPI.lockReturnByReturnID(this.props.returnID).catch(err => {
      // TODO: see msg
      console.log(
        'CRITICAL: failed to lock return, continuing anyway but this needs to be handled',
      );
      console.log(err);
    });

    this.handleTextAgreementLink();
  };

  componentWillUnmount = () => {
    this.prevPathName === '/refund-calculator' && window.history.back();

    this.calcAPI.CloseSession();
    window.removeEventListener('resize', this.renderButtonChoice);
    clearInterval(this.state.autoSaveInterval);
    document.body.classList.remove('bodyStyleOverflowHidden');
    this.props.removeFrontEndNotification();
    // Remove the state selected (this.props.statePackageSelected) for add new form
    this.props.setStatePackageSelected('');
    window.removeEventListener('beforeunload', handleSuddenCloseWindow);
    this.props.setAccountType(false); // To set demo account value back to default value

    // Reset to false so if there is a new app version but not viewing
    // a return, then no need to perform save.
    if (sessionStorage.getItem('formViewerCompLoaded')) {
      sessionStorage.setItem('formViewerCompLoaded', false);
    }
    this.props.setUpdatedAttachmentList([]);
    // Reset Signature page data back to initial state
    this.props.signaturesNeededSet('');
    this.props.setPreparerID('');
    this.props.setAmendedPreparerID('');

    // close the Y2Y dialogue if open
    this.props.closeYTYDialog();

    this.props.toggleTextLinkSendModalOriginal(false, '');
  };

  componentDidUpdate(prevProps, prevState) {
    /**
     * When a new message comes in and increments the message badge count,
     * check if that new message is for the open return and if so, display
     * the new text message notification.
     */
    this.props.messageBadgeCount > prevProps.messageBadgeCount &&
      this.handleNewTextMessageNotification(prevState.unreadTextMessageCount);

    // When navigating between sections, IF they navigate to a form, that is already saved to local state via 'activeForm' ->
    // We need to re-render the activeForm so IF they were any updates, that the results will be loaded/updated on the other form ->
    // If the wizard return is marked as complete, and they re-enter the return, we do NOT want to make a double request for the form
    if (this.returnInitialized && !prevProps?.wizardCompleted && this.props.wizardCompleted) {
      this.getTaxForm(this.state.activeForm, '', '', '');
    }

    if (
      prevProps?.activeErrorRejectTab === null &&
      prevProps?.activeErrorRejectTab !== this.props.activeErrorRejectTab
    ) {
      this.setState({
        activeErrorRejectTab: this.props.activeErrorRejectTab,
      });
    }
  }

  handleTextAgreementLink = () => {
    if (this.payload.account_code.includes('RIVEDG')) {
      // This URL is only for the UTAX text message agreement modal
      this.setState({
        textAgreementLinkTerms: 'https://mytaxofficeportal.com/',
        textAgreementLinkPrivacy: 'https://mytaxofficeportal.com/',
      });
    }
  };

  getPrintingPrefs = () => {
    XlinkAPI.getPrintingSetup(WebHelpers.getJWTPayload().login_id)
      .then(res => {
        const data = res.data;
        const newPrefs = update(this.state.print, {
          prefs: { $set: data.config },
        });
        this.setState({
          print: newPrefs,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  convertPrintPrefs = (prefs, printType) => {
    const newPrefsToSend = {};
    switch (printType) {
      case PRINT_TYPES.PREVIEW_RETURN:
      case PRINT_TYPES.FINAL_RETURN:
        for (const pref in prefs) {
          if (
            (this.payload.is_business ? PRINT_PREFS.CORP_FINAL : PRINT_PREFS.FINAL).includes(pref)
          ) {
            newPrefsToSend[pref] = prefs?.[pref]?.configValue === '1' ? 'X' : '';
          }
        }
        break;
      case PRINT_TYPES.BANK_DOCUMENTS:
        newPrefsToSend[PRINT_PREFS.BANK] = prefs?.[PRINT_PREFS.BANK]?.configValue === 1 ? 'X' : '';
        break;
      case PRINT_TYPES.DISCLOSURE:
        newPrefsToSend[PRINT_PREFS.DISCLOSURE] =
          prefs?.[PRINT_PREFS.DISCLOSURE]?.configValue === '1' ? 'X' : '';
        break;
      case PRINT_TYPES.ORGANIZER:
        // LSRQ DOA temporarily
        // newPrefsToSend[PRINT_PREFS.ORGANIZER] = (prefs[PRINT_PREFS.ORGANIZER].configValue == 1) ? 'X' : ''
        break;
      case PRINT_TYPES.EF_DOCUMENTS:
        for (const pref in PRINT_PREFS.EF) {
          const key = PRINT_PREFS.EF[pref];
          newPrefsToSend[key] = prefs?.[key]?.configValue === '1' ? 'X' : '';
        }
        break;
      // No Prefs needed
      case PRINT_TYPES.AMENDED_RETURN:
      case PRINT_TYPES.CURRENT_FORM:
      case PRINT_TYPES.REF_COUPONS:
      case PRINT_TYPES.ACK_LETTER:
      case PRINT_TYPES.STATE_ACK_LETTER:
      case PRINT_TYPES.AMENDED_ACK_LETTER:
      case PRINT_TYPES.CLIENT_LETTER:
      case PRINT_TYPES.PAYMENT_VOUCHER:
      case PRINT_TYPES.EXT_REQUEST:
      case PRINT_TYPES.EXT_ACK_LETTER:
      case PRINT_TYPES.ATTACHED_FORMS:
      case PRINT_TYPES.REMOTE_SIG:
      case PRINT_TYPES.PAPER_FILE_RT:
      case PRINT_TYPES.STMT199A:
      case PRINT_TYPES.PREVIEW_1040:
      case PRINT_TYPES.CORP_SCHEDULE_K1S:
      case PRINT_TYPES.RRB_1042S_SIGN_DOC:
        break;
      default:
        throw ErrorHelpers.createSimpleError('Print Type Not Recognized');
    }

    return newPrefsToSend;
  };

  sortAvailablePackages = packageData => {
    const sortedPackageData = [];

    for (let i = 0; i < packageData.length; i++) {
      if (this.state.availablePackages.includes(packageData[i].ListItem[0].COL)) {
        sortedPackageData.push(packageData[i]);
      }
    }
    return sortedPackageData;
  };

  getAvailablePackages = async () => {
    try {
      const res = await XlinkAPI.getStatePackages(this.payload?.is_business);
      if (statusOK(res)) {
        this.setState({
          availablePackages: res.data?.sort() || [],
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Fetch Error', error);
    }
  };

  isPackageAvailable = packageString => {
    const statePackage = packageString.toUpperCase();
    return this.state.availablePackages.includes(statePackage);
  };

  handleMenuCommand = (cmd, resp = null) => {
    try {
      switch (cmd) {
        case 'refundCalcCreate':
          this.DOM().returnSave(
            [
              {
                stateName: 'FEDERAL',
                refundOrBalDue: this.getRefundOrBalDue(),
                season: 2019,
              },
            ],
            this.props.isReadOnly,
          );
          break;
        case 'refundCalcExit':
          this.setState({
            showExitDialog: true,
          });
          break;
        case 'stockImport':
          this.setState({
            showImportStock: true,
            menuCommandResp: resp.arg,
          });
          break;
        case 'print199a':
          this.handlePrint(PRINT_TYPES.STMT199A, {}, 'STMT 199A');
          break;
        case 'prt1042S':
          this.handlePrint(PRINT_TYPES.RRB_1042S_SIGN_DOC, {}, 'Sign Declaration');
          break;
        case 'prev1040':
          // Call return_print directly instead of going through return_print_query to mimic behavior with desktop
          this.calcAPI.ReturnPrint(
            PRINT_TYPES.PREVIEW_1040,
            {},
            '',
            {},
            PRINT_TYPES.PREVIEW_1040,
            '',
            '',
            this.props.isReadOnly,
            resp?.arg?.PKG,
          );
          break;
        case 'resetUST1':
          this.calcAPI.ReturnFormLoad('UST101', '', '', '');
          break;
        default:
          throw ErrorHelpers.createSimpleError('Unable to complete action');
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to complete action', error);
    }
  };

  setWebSocketStatusConn = status => {
    this.setState({
      websocketStatusConn: status,
    });
  };

  doInitFormLoad = () => {
    // TODO: Temporary front-end handling for special form load from return profile, due to infinite loop bug
    // If this is WizardMode and it is completed, allow regular form_loads
    if (!this.props.isWizard || this.props.wizardCompleted) {
      switch (this.state.activeForm) {
        case 'retn04':
        case 'retn09':
          this.getTaxForm(FORM_NAMES.CDS, '', '', '');
          this.verifyReturn(VERIFY_MODAL_TABS.REJECTS);
          break;
        case 'retn25':
          this.getTaxForm(FORM_NAMES.CDS, '', '', '');
          this.props.toggleEventLogModal(true);
          break;
        case 'retn28':
          this.getTaxForm(FORM_NAMES.CDS, '', '', '');
          this.props.toggleTaxpassMessageModal(true);
          break;
        default:
          if (
            this.props.isNew &&
            this.props.startInterview &&
            !this.props.estimatorBlob &&
            !this.payload?.is_business
          ) {
            this.interviewMode();
          } else {
            this.getTaxForm(this.state.activeForm, '', this.state.activeFile, '');
            if (
              (this.state.activeForm.includes('ret') &&
                (this.state.activeForm !== 'retn09' ||
                  this.state.activeForm !== 'retn04' ||
                  this.state.activeForm !== 'retn25' ||
                  this.state.activeForm !== 'retn28')) ||
              this.state.activeForm === 'form12' ||
              this.state.activeForm === 'prin12' ||
              this.state.activeForm === 'prin17' ||
              this.state.activeForm === 'prin18'
            ) {
              this.setState({
                initialSpecialFormLoad: true,
              });
            } else if (this.props.isNew && this.state.activeForm === FORM_NAMES.CDS) {
              this.calcAPI.ReturnFormLoad('000A00', '', '', '');
            } else if (this.state.activeForm === FORM_NAMES.CDS) {
              this.getTaxForm(FORM_NAMES.CDS, '', '', '');
            }
          }
          break;
      }
    }
  };

  // TODO: store.dispatch can map to props instead
  onSocketResponse = async evt => {
    if (!evt && !evt.data) {
      return;
    }
    if (evt.data === 'CLOSE') {
      this.onCloseActiveReturn();
      // This doesn't really matter
      this.setWebSocketStatusConn(false);
      return;
    } else if (evt.data === 'REFRESH') {
      this.startSpin('Reconnecting. Please Wait...');
      this.calcAPI.CloseSocket();
      this.newSocketInit();
      return;
    } else if (evt.data === 'CRITICAL') {
      this.setWebSocketStatusConn(false);
      this.calcAPI.CloseSocket();
      console.log(
        'CRITICAL state from socket worker, all messages dropped. Attempting to reopen websocket connection...',
      );
      this.newSocketInit();
      return;
    } else if (evt.data === 'SOCKET_OPEN') {
      if (!this.returnInitialized) {
        // We only want to send return_init once. Check if the return has been initialized.

        // emulate CalcMutator's returnInit, since we're not using it in FOrmViewer yet
        // let blob = sessionStorage.getItem("ESTIMATOR_BLOB")
        const blob = this.props.estimatorBlob;
        if (blob && blob !== null) {
          this.setState({
            loadEstimatorState: true,
          });
        }
        await this.calcAPI.ReturnInit(this.props.loginID, false, blob);

        let activeEfin = 0;
        if (this.payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
          activeEfin = this.payload.preparer_info.active_efin_id;
        } else {
          activeEfin = await UtilityAPI.getLastDrilledOfficeEfinID(this.ddh);
        }

        this.getPrintingPrefs();
        this.getAvailablePackages();
        // Grab Asset List for return
        // this.updateAddFormList("BA")
        // Set active efin for remote signature feature
        this.props.setActiveEfin(activeEfin);

        // Silently save return
        // Every 120 seconds when no autoSaveAfterMins value is set under the setup defaults page
        // Every x seconds when autoSaveAfterMins value is set under the setup defaults page
        if (typeof this.payload !== 'undefined') {
          // Autosave is disabled when:
          // * The Return is locked
          //   * Autosaving doesn't get disabled/enabled when manually toggling lock
          //   * TODO --> Disable/Enable autosave when manually toggling lock
          // * The Return is being edited by an admin or tech support login
          // * The Return is being edited by an admin or tech support while impersonating
          if (
            !this.isLocked() &&
            (this.payload.hierarchy_type_id !== HIERARCHY_TYPE.ADMIN ||
              this.payload.hierarchy_type_id !== HIERARCHY_TYPE.TECH_SUPPORT ||
              this.payload.hierarchy_type_id !== HIERARCHY_TYPE.TECH_SUPPORT_SBA) &&
            !this.payload.is_tech_support_and_impersonating &&
            !this.payload.is_super_user
          ) {
            let autoSaveAfterMilisec = 0;
            if (this.props.autoSaveAfterMins === '' || this.props.autoSaveAfterMins === '0') {
              autoSaveAfterMilisec = AutoSaveInterval;
            } else {
              autoSaveAfterMilisec = this.props.autoSaveAfterMins * 60000; // Converting time to miliseconds
            }
            this.setState({
              autoSaveInterval: setInterval(() => {
                if (
                  !this.hasFLST() ||
                  assetFormRegExp.test(this.state.activeForm) ||
                  this.props.autoSaveEnabled === '0'
                ) {
                  // Do not save if filing status has not been set or if currently on an asset form
                } else {
                  try {
                    this.calcAPI.TriggerAutoSave(this.props.isReadOnly);
                  } catch (error) {
                    ErrorHelpers.handleError('Error saving return', error);
                  }
                }
              }, autoSaveAfterMilisec),
            });
          }
        }
      }
      return;
    }

    const wsCommand = $.parseJSON(evt.data);
    this.sessionid = wsCommand.sessionid;
    let form = {};
    let formtoLoad = '';
    let response = {};
    let calcres = {};

    try {
      switch (wsCommand.command) {
        case 'invalid_token':
          this.stopSpin();
          this.calcAPI.CloseSession();
          break;
        case 'NOTIFY_USER': // Use this to notify the user of any hangups/connection issues with the websocket. TODO: This should be refactored to be cleaner.
          switch (wsCommand.promptType) {
            case 'SOCKET_START_SPINNER':
              this.startSpin(wsCommand.message);
              break;
            case 'SOCKET_STOP_SPINNER':
              this.stopSpin();
              break;
            case 'SOCKET_SNACKBAR_ERROR':
              this.showErrorSnackbar(wsCommand.message);
              break;
            case 'SOCKET_MESSAGEBOX':
              // Do Something
              break;
          }
          break;
        case 'echo':
          break;
        case 'alert':
          break;
        case 'error': {
          this.stopSpin();
          const errorMsg = get(wsCommand, 'data', 'Unable to complete action');
          this.showErrorSnackbar(errorMsg);
          break;
        }
        case 'textlink_error':
          this.setState({ textlinkErrorMessage: '' }); // reset string if previously set
          // not all calls to textlink_error send back a response.
          if (wsCommand?.data) {
            this.setState({ textlinkErrorMessage: wsCommand?.data });
          }
          this.setState({ showTextLinkSendErrorDialog: true });
          break;
        case 'form_load': {
          formtoLoad = wsCommand.form;
          form = JSON.parse(this.trimNull(wsCommand.formdata));
          let currentFormName;
          let currentFileName = '';
          if (form.error) {
            this.props.showErrorDialog(form.error);
            this.setState({
              verifyStmCode: '',
              verifyForm: '',
              verifyDirty: false,
              verifyWksRan: false,
            });
            break;
          }
          if (Object.prototype.hasOwnProperty.call(form, 'arg')) {
            // Show message box if present; New form should still load as the box is being shown
            if (form.arg.MSGBOX) {
              store.dispatch(formViewerActions.openMsgDialog(form.arg.MSGBOX));
            }
            // Normal form form_load response has format {"formdata": {..., "arg":{...}} }
            currentFormName = form.arg.form + form.arg.occurrence + form.arg.subFrm;
            currentFileName = form.arg.frmName;
            this.subFrm = form.arg.subFrm;
            if (Object.prototype.hasOwnProperty.call(form.arg, 'NavPanelItems')) {
              // TODO: Possibly pass NavPanelItems with every form_load request?-refactor to handle form or interview mode
              store.dispatch(returnProfileActions.setActiveReturnFormList(form.arg.NavPanelItems));
            }

            if (Object.prototype.hasOwnProperty.call(form.arg, 'lastRow')) {
              this.lastRow = translateSpecialOccuranceRow(form.arg.lastRow);
            }

            if (Object.prototype.hasOwnProperty.call(form.arg, 'nextFld')) {
              this.goToField(this.state.activeForm, form.arg.nextFld);
            } else {
              this.scrollFormTo(TopOfFormPage);
            }
            if (Object.prototype.hasOwnProperty.call(form, 'frmSwitch')) {
              // get last six letters of frmswitch to get field we are jumping to and its occurrence #
              let fieldFocus = form.frmSwitch.substr(form.frmSwitch.length - 6);
              fieldFocus += '00';
              // load the form name to jump to
              const formFocus = form.frmSwitch.substr(0, 6);
              this.goToField(formFocus, fieldFocus);
            }

            this.dblBlindList = [];
            if (Object.prototype.hasOwnProperty.call(form.arg, 'fields')) {
              for (const i in form.arg.fields) {
                const j = form.arg.fields[i];
                if (j.dblBlind) this.dblBlindList.push(i.substring(0, 4));
              }
            }
            // To mimic desktop, must make bank account number a permanent double entry, push field onto list
            this.dblBlindList.push('bdan');

            form = form.arg;

            if (this.state.maintainAutoExtendFocus && this.currentEl.id) {
              this.goToField(this.state.activeForm, this.currentEl?.id);
            }
          } else {
            // Interview mode form_load response has format {"formdata": {...}}

            // We are in interview mode NavPanelItems are at a higher level make sure to load them so forms display attached
            if (Object.prototype.hasOwnProperty.call(form, 'NavPanelItems')) {
              store.dispatch(returnProfileActions.setActiveReturnFormList(form.NavPanelItems));
            }

            currentFormName = form.form + form.occurrence + form.subFrm;
            currentFileName = form.frmName;
            this.subFrm = form.subFrm;
          }
          if (this.state.interviewMode) {
            this.setState(
              { specialFormLoaded: false },
              this.setTaxForm(formtoLoad, currentFormName, currentFileName, this.subFrm),
              this.UpdateForm(form),
            );
            // Retrieves the current element on a interview mode form so the field can be refocused again after the intial form load
            // on an interview mode form occurs
            const currFormElement = this.state.interviewCurrentEleMap.has(this.state.activeForm)
              ? this.state.interviewCurrentEleMap.get(this.state.activeForm).id
              : false;

            if (currFormElement) {
              this.goToField(this.state.activeForm, currFormElement);

              // There is a special case where initial form input will not validate correctly.
              // Instead of expecting an update_form from calcServer when sending a calc command
              // we are instead given another form_load. Re-request calculation and expect
              // update_form in the next response.
              const lookupField = form.fields[currFormElement];
              const prevLookupField = form.fields[this.previousEl.id];

              // Even though input is valid, form_load responds with invalid input
              // Revalidate again, depending on how user unfocused from input.
              if (lookupField?.color === '90' && lookupField.value) {
                // User has clicked out of focused input
                this.handleReturnFieldCalc(this.currentEl.id, this.currentEl.value);
              } else if (prevLookupField?.color === '90' && prevLookupField.value) {
                // User tabbed into new input, but we are requesting previous
                this.handleReturnFieldCalc(this.previousEl.id, this.previousEl.value);
              }
            }
            this.lockFieldsIfRequired();
          } else {
            this.setState(
              { specialFormLoaded: false },
              this.setTaxForm(formtoLoad, currentFormName, currentFileName, this.subFrm),
              this.PopulateForm(form),
            );

            // special block for auto extend forms,
            // readds missing value if you cab on last work of worksheet
            if (
              this.state.maintainAutoExtendFocus &&
              this.currentEl.id === this.state.tabbedField
            ) {
              this.setState({
                maintainAutoExtendFocus: false,
              });
              const jqueryID = this.formatIDForJquery(this.currentEl?.id);
              $('#' + jqueryID).val(this.state.autoExtendTabValue);
              this.lockFieldsIfRequired();
            }
          }
          if (this.state.notesFlag) {
            this.doNotesAlert();
          }
          if (this.state.loadEstimatorState) {
            this.props.clearEstimatorState();
            this.setState({
              loadEstimatorState: false,
            });
          }

          if (this.payload.season >= 2024) {
            const formContainerTarget = document.getElementsByClassName('form-page')[0];
            if (formContainerTarget !== null) {
              const formName = form?.form?.substring(0, 2);
              // if the `showdraft` field is true, add a 'DRAFT' overlay on the form
              // Also Prevent Car/Asset Depreciation from showing overlay
              // Depreciation Forms always start as 'A0'
              if (form?.showdraft === 'true' && formName !== 'A0') {
                formContainerTarget.classList.add('showdraft');
              }
            }
          }
          break;
        }
        case 'return_init': {
          const returnInit = JSON.parse(this.trimNull(wsCommand.data));
          this.colorMap = returnInit.colorMap;
          const calcResponse = JSON.parse(this.trimNull(returnInit.calcResponse));

          if (calcResponse.arg.frmSwitch && calcResponse.arg.frmSwitch !== '') {
            switch (calcResponse.arg.frmSwitch) {
              case 'stat03': // Notes Window
                this.calcAPI.ReturnFormLoad('retn19', '', '', '');
                break;
              case 'stat02': // Reject Window
                this.doInitFormLoad();
                this.verifyReturn(VERIFY_MODAL_TABS.REJECTS);
                break;
              case 'stat01': // CMDNOOP (NO OPeration)
              case '000000':
              case '0000000000':
                this.doInitFormLoad();
                break;
              default: {
                this.calcAPI.ReturnFormLoad(calcResponse.arg.frmSwitch.substring(0, 6), '', '', '');
                // TODO Figure out a way to find out when form load is done and perform a callback of some sort
                // Concept taken from verifyFocus
                let field = calcResponse.arg.frmSwitch.substring(6, 10) + '00';
                let fieldExistCount = 0;
                const fieldExists = setInterval(() => {
                  if (document.getElementById(field) || fieldExistCount >= 10) {
                    if (
                      field === 'RMST00' &&
                      document.getElementById(field).classList.contains('hide') &&
                      document.getElementById('RMST01') &&
                      !document.getElementById('RMST01').classList.contains('hide')
                    ) {
                      // Forward to RMST01
                      field = 'RMST01';
                    }
                    if (document.getElementById(field) != null) {
                      const focusField = document.getElementById(field);
                      focusField.focus();
                      clearInterval(fieldExists);
                    }
                  }
                  if (fieldExistCount > 100) {
                    clearInterval(fieldExists);
                  }
                  fieldExistCount++;
                }, 150);
              }
            }
          } else {
            // Precaution if CalcServer doesn't have CLO-5487 changes (doesn't send frmSwitch)
            this.doInitFormLoad();
          }
          if (calcResponse.arg.notes && calcResponse.arg.notes.length !== 0) {
            this.setState({ notesFlag: true });
            this.handlePreparerNotesReq(calcResponse.arg?.notes);
          }
          if (returnInit.taxpassReturnID > 0) {
            store.dispatch(returnProfileActions.setIsTaxPassReturnFlag(true));
          } else {
            store.dispatch(returnProfileActions.setIsTaxPassReturnFlag(false));
          }
          store.dispatch(
            returnProfileActions.setActiveReturnFormList(calcResponse.arg.NavPanelItems),
          );
          // Lock the return based on what's coming back from the API. Since return_init is always the first transaction throughout the
          // lifetime of an open return, the logic for determining a lock should always come from here.
          store.dispatch(returnProfileActions.setReadOnlyMode(returnInit?.readyOnlyMode || false));
          // Alter Body style...Moved from component Did mount to avoid race condition
          document.body.classList.add('bodyStyleOverflowHidden');

          if (Object.prototype.hasOwnProperty.call(returnInit, 'locked_by')) {
            store.dispatch(
              returnProfileActions.lockReturn(returnInit.locked_by, returnInit.lockedByLoginID),
            );
          }
          this.props.needingVerifiedSet(true);
          this.returnInitialized = true;
          this.calcAPI.SetReturnInitStatus(this.returnInitialized);
          if (this.props.isSelfPreparing !== returnInit?.isSelfPreparing) {
            this.props.setIsSelfPreparingIndicator(returnInit?.isSelfPreparing);
          }
          break;
        }
        case 'return_save':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.error) {
            this.props.showErrorDialog(response.error);
            this.stopSpin();
            break;
          }
          this.props.showSnackbarMessage('Tax Return Saved', 'success', 1500, {
            vertical: 'top',
            horizontal: 'center',
          });
          if (Object.prototype.hasOwnProperty.call(response, 'arg')) {
            if (
              typeof response.arg.MSGBOX === 'undefined' &&
              response.arg.TaxReturnDetails !== undefined
            ) {
              if (response.arg.TaxReturnDetails.Business !== undefined) {
                store.dispatch(
                  formViewerActions.taxpayerInformationSet({
                    BusinessName: response.arg.TaxReturnDetails.Business.BusinessName,
                    SSN: response.arg.TaxReturnDetails.Business.EIN,
                    email: response.arg.TaxReturnDetails.Business.email,
                    cellPhone: response.arg.TaxReturnDetails.Business.Phone,
                    cellCarrier: response.arg.TaxReturnDetails.Business.CellCarrier,
                    cellDomain: response.arg.TaxReturnDetails.Business.CellDomain,
                    signeeType: SIGNEE_TYPE.TAXPAYER, // TODO (CLO-BUSINESS): Revisit with signature
                    zipCode: response.arg.StatusRecord.ZipCode,
                  }),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerName(
                    response.arg.TaxReturnDetails.Business.BusinessName,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerEmail(response.arg.TaxReturnDetails.Business.email),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneNumber(
                    response.arg.TaxReturnDetails.Business.Phone,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneCarrier(
                    response.arg.TaxReturnDetails.Business.CellCarrier,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneDomain(
                    response.arg.TaxReturnDetails.Business.CellDomain,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerAllowText(
                    response.arg.TaxReturnDetails.Business.AllowText,
                  ),
                );
              }
              if (response.arg.TaxReturnDetails.Taxpayer !== undefined) {
                const remoteSignatureState = update(this.state.remoteSignature, {
                  returnCurrentState: {
                    $set: response.arg,
                  },
                  remoteSignReturnID: {
                    $set: response.rtnId,
                  },
                  tpFirstName: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.FirstName,
                  },
                  tpLastName: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.LastName,
                  },
                  tpDOB: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.DOB,
                  },
                  tpLast4: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.SSN,
                  },
                  tpEmail: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.email,
                  },
                  tpCellPhone: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.CellPhone,
                  },
                  tpCellCarrier: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.CellCarrier,
                  },
                  tpCellDomain: {
                    $set: response.arg.TaxReturnDetails.Taxpayer.CellDomain,
                  },
                });
                this.setState({
                  remoteSignature: remoteSignatureState,
                });
                store.dispatch(
                  formViewerActions.taxpayerInformationSet({
                    firstName: response.arg.TaxReturnDetails.Taxpayer.FirstName,
                    lastName: response.arg.TaxReturnDetails.Taxpayer.LastName,
                    DOB: response.arg.TaxReturnDetails.Taxpayer.DOB,
                    SSN: response.arg.TaxReturnDetails.Taxpayer.SSN,
                    email: response.arg.TaxReturnDetails.Taxpayer.email,
                    cellPhone: response.arg.TaxReturnDetails.Taxpayer.CellPhone,
                    cellCarrier: response.arg.TaxReturnDetails.Taxpayer.CellCarrier,
                    cellDomain: response.arg.TaxReturnDetails.Taxpayer.CellDomain,
                    signeeType: SIGNEE_TYPE.TAXPAYER,
                    zipCode: response.arg.StatusRecord.ZipCode,
                  }),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerName(
                    response.arg.TaxReturnDetails.Taxpayer.FirstName +
                      ' ' +
                      response.arg.TaxReturnDetails.Taxpayer.LastName,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerEmail(response.arg.TaxReturnDetails.Taxpayer.email),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneNumber(
                    response.arg.TaxReturnDetails.Taxpayer.CellPhone,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneCarrier(
                    response.arg.TaxReturnDetails.Taxpayer.CellCarrier,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerCellPhoneDomain(
                    response.arg.TaxReturnDetails.Taxpayer.CellDomain,
                  ),
                );
                store.dispatch(
                  formViewerActions.setTaxpayerAllowText(
                    response.arg.TaxReturnDetails.Taxpayer.AllowText,
                  ),
                );
              }
              if (response.arg.TaxReturnDetails.Spouse !== undefined) {
                const remoteSignatureStateSpouse = update(this.state.remoteSignature, {
                  spFirstName: {
                    $set: response.arg.TaxReturnDetails.Spouse.FirstName,
                  },
                  spLastName: {
                    $set: response.arg.TaxReturnDetails.Spouse.LastName,
                  },
                  spDOB: {
                    $set: response.arg.TaxReturnDetails.Spouse.DOB,
                  },
                  spLast4: {
                    $set: response.arg.TaxReturnDetails.Spouse.SSN,
                  },
                  spEmail: {
                    $set: response.arg.TaxReturnDetails.Spouse.email,
                  },
                  spCellPhone: {
                    $set: response.arg.TaxReturnDetails.Spouse.CellPhone,
                  },
                  spCellCarrier: {
                    $set: response.arg.TaxReturnDetails.Spouse.CellCarrier,
                  },
                  spCellDomain: {
                    $set: response.arg.TaxReturnDetails.Spouse.CellDomain,
                  },
                });
                this.setState({
                  remoteSignature: remoteSignatureStateSpouse,
                });
                store.dispatch(
                  formViewerActions.spouseInformationSet({
                    firstName: response.arg.TaxReturnDetails.Spouse.FirstName,
                    lastName: response.arg.TaxReturnDetails.Spouse.LastName,
                    DOB: response.arg.TaxReturnDetails.Spouse.DOB,
                    SSN: response.arg.TaxReturnDetails.Spouse.SSN,
                    email: response.arg.TaxReturnDetails.Spouse.email,
                    cellPhone: response.arg.TaxReturnDetails.Spouse.CellPhone,
                    cellCarrier: response.arg.TaxReturnDetails.Spouse.CellCarrier,
                    cellDomain: response.arg.TaxReturnDetails.Spouse.CellDomain,
                    signeeType: SIGNEE_TYPE.SPOUSE,
                    zipCode: response.arg.StatusRecord.ZipCode,
                  }),
                );
                store.dispatch(formViewerActions.setSpouseInfoAvailable(true));
                store.dispatch(
                  formViewerActions.setSpouseName(
                    response.arg.TaxReturnDetails.Spouse.FirstName +
                      ' ' +
                      response.arg.TaxReturnDetails.Spouse.LastName,
                  ),
                );
                store.dispatch(
                  formViewerActions.setSpouseEmail(response.arg.TaxReturnDetails.Spouse.email),
                );
                store.dispatch(
                  formViewerActions.setSpouseCellPhoneNumber(
                    response.arg.TaxReturnDetails.Spouse.CellPhone,
                  ),
                );
                store.dispatch(
                  formViewerActions.setSpouseCellPhoneCarrier(
                    response.arg.TaxReturnDetails.Spouse.CellCarrier,
                  ),
                );
                store.dispatch(
                  formViewerActions.setSpouseCellPhoneDomain(
                    response.arg.TaxReturnDetails.Spouse.CellDomain,
                  ),
                );
                store.dispatch(
                  formViewerActions.setSpouseAllowText(
                    response.arg.TaxReturnDetails.Spouse.AllowText,
                  ),
                );
              }
              if (this.state.remoteSignPrint === true) {
                this.handleSigningRemotely();
              } else if (this.state.encryptedPdfPrint === true) {
                // Open second step for emailing the encrypted pdf document
                this.props.toggleSuggestedPassword(true);
                this.setState({
                  encryptedPdfPrint: false,
                });
                this.stopSpin();
              }
              this.stopSpin();
            } else {
              if (response.arg.MSGBOX) {
                this.props.openMsgDialog(response.arg.MSGBOX);
              }
              this.stopSpin();
            }
          } else {
            if (response.arg.MSGBOX) {
              this.props.openMsgDialog(response.arg.MSGBOX);
            }
            this.stopSpin();
          }

          this.setState({ returnSaved: true });

          break;
        case 'return_auto_save':
          // Show Message auto save worked? Stop Spinner for auto save? Do Something to indicate autosave worked?
          break;
        case 'return_close':
          this.stopSpin();
          this.calcAPI.CloseSession();
          break;
        case 'return_saveclose':
          this.stopSpin();
          this.calcAPI.CloseSession();
          break;
        case 'return_openpdfattach':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(formViewerActions.setDocumentsAttach(response));
          this.openAddAttachmentModal();
          break;
        case 'return_openVisionAssist':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.disable_tva) {
            this.setState({ openTVADisallow: true });
          } else {
            this.setState({
              isVisionModalOpen: true,
              TVAargType: response?.type?.replace(/^0+/, ''), // Strip Leading Zeroes
            });
          }

          break;

        case 'return_buttonpress': {
          response = JSON.parse(this.trimNull(wsCommand.data));
          const msgbox = response.arg.MSGBOX;
          if (!msgbox) {
            const cmd = response.arg.menuCmd;
            if (!cmd) {
              throw ErrorHelpers.createSimpleError('Unable to complete action');
            }
            this.handleMenuCommand(cmd, response);
            return;
          }
          store.dispatch(formViewerActions.openMsgDialog(msgbox));
          break;
        }
        case 'return_msgresp':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.escCmd) {
            if (response.escCmd === 'X') {
              this.restorePreviousValue();
            }
          } else if (response.errorPop) {
            this.restorePreviousValue();
            this.setState({
              calcErrorPopup: true,
              calcErrorTitle: response.title,
              calcErrorMsg: response.error,
            });
          } else if (response.arg?.visionAssistContinued) {
            if (response.arg?.formLoad) {
              // dropping here means form was added
              this.setState({ TVAprogress: 100 });
              // Artifical Delay to allow user to see bar reach 100% before closing modal
              setTimeout(() => {
                this.setState({ isVisionModalOpen: false, TVAprogress: 0 });
              }, 500);
              this.calcAPI.ReturnFormLoad(response.arg.formLoad, '', '', '');
            } else {
              this.setState({ TVAprogress: 100 });
              this.UpdateForm(response.arg);
              // Artifical Delay to allow user to see bar reach 100% before closing modal
              setTimeout(() => {
                this.setState({ isVisionModalOpen: false, TVAprogress: 0 });
              }, 500);
            }
          } else if (response.arg) {
            if (response.arg.PDF) {
              this.displayPrint(response.arg.PDF.pdfBLOB);
            }
            if (response.arg.formLoad) {
              this.calcAPI.ReturnFormLoad(response.arg.formLoad, '', '', '');
            } else if (response.arg.fields) {
              this.UpdateForm(response.arg);
            }
          }
          this.props.needingVerifiedSet(true);
          break;
        case 'return_newasset':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (typeof response.error !== 'undefined') {
            this.props.showErrorDialog(response.error);
          }
          this.props.needingVerifiedSet(true);
          break;
        case 'return_verify':
          response = JSON.parse(wsCommand.data);
          store.dispatch(returnProfileActions.setAllVerifiesAndErrors(response.arg));

          if (this.state.verifyType === 'queueReturn') {
            this.props.openErrorRejectModal();
            if (response.arg.errors === 0 && response.arg.warnings === 0) {
              this.props.toggleVerifyModalDialog(true);
            }
          } else if (
            this.state.verifyType === 'verify' &&
            response.arg.errors === 0 &&
            response.arg.warnings === 0
          ) {
            this.props.toggleVerifyDialog(true);
            this.setVerifyType('');
          } else {
            store.dispatch(formViewerActions.setRejectTab(null));
            this.props.openErrorRejectModal();
          }
          this.stopSpin();
          break;
        case 'update_form':
          this.props.needingVerifiedSet(true);
          form = JSON.parse(this.trimNull(wsCommand.data));
          if (form.error) {
            // TODO: Currently fail silently
          } else {
            if (form.arg.MSGBOX) {
              store.dispatch(formViewerActions.openMsgDialog(form.arg.MSGBOX));
            }
            this.UpdateForm(form.arg);

            const selfPrepVal1040 = form?.arg?.fields?.SELF00?.value;
            const selfPrepValCorp = form?.arg?.fields?.['847000']?.value;
            const isSelfPreparing = !!(selfPrepVal1040 || selfPrepValCorp);

            // compare with the current state to reduce updates
            if (this.props.isSelfPreparing !== isSelfPreparing) {
              this.props.setIsSelfPreparingIndicator(isSelfPreparing);
            }

            if (form.arg.NavPanelItems) {
              store.dispatch(returnProfileActions.setActiveReturnFormList(form.arg.NavPanelItems));
            }
            if (this.state.verifyDirty) {
              this.setState({ verifyDirty: false });
              this.verifyReturn(1);
            } else if (this.currentEl) {
              const focused = document.activeElement;

              // only attempt to focus on another field if there is currently no field selected, or if selected field is the same as the previously selected field
              if (form.arg && form.arg.nextFld !== focused.id && form.arg.nextFld !== '') {
                this.goToField(this.state.activeForm, form.arg.nextFld);
              }
            }
            const { command } = form;
            // Only load the Client Data Screen if a year to year transfer was iniated.
            // If Office has "Start new returns in interview mode" setting checked, start interview mode
            // Else load up CDS screen like a regular return
            if (command === 'return_yty' && this.props.startInterview) {
              this.interviewMode();
            } else if (command === 'return_yty') {
              this.calcAPI.ReturnFormLoad('000A00', '', '', '');
            }
          }
          break;
        case 'return_choicelist':
          response = JSON.parse(wsCommand.data);
          if (response?.arg?.ExplicitType?.Window?.Title === 'State Package') {
            const sortedData = this.sortAvailablePackages(response.arg.ExplicitType?.Data);
            response.arg.ExplicitType.Data = sortedData;
          }
          store.dispatch(formViewerActions.setChoiceList(response.arg.ExplicitType));
          this.initAutocomplete(response.arg.ExplicitType);
          break;
        case 'return_choicelist_calc':
          response = JSON.parse(wsCommand.data);
          this.props.needingVerifiedSet(true);
          break;
        case 'return_formslist': {
          // remove escape characters
          const strippedJSON = wsCommand?.data?.replaceAll('\t', '')?.replaceAll('\n', '');
          response = JSON.parse(strippedJSON);

          if (response.arg.forms) {
            // Determine which list to pass
            let formsAsReceived = response.arg.forms;
            if (this.props.addFormPkg === 'US' && !this.payload?.is_business) {
              // Rearrange list of forms returned by calc server to show W-2 form as first option
              const indexOfW2 = formsAsReceived.findIndex(i => i.formId === 'US02');
              if (indexOfW2 !== 1) {
                formsAsReceived = formsAsReceived.splice(indexOfW2, 1).concat(formsAsReceived);
              }
            }
            store.dispatch(formViewerActions.setAddFormList(formsAsReceived));
            // Add flag here to determine if the values for the list are already populated
            await this.PopulateFormsAndActivitiesList(formsAsReceived);
            // Anything here, (after the await) will execute after PopulateFormsAndActivitiesList(response.arg.forms) completes
            store.dispatch(formViewerActions.setSelectedFormToAdd(formsAsReceived[0]));
          }
          if (response.arg.activities) {
            store.dispatch(formViewerActions.setActivityList(response.arg.activities));
            // Add flag here to determine if the values for the list are already populated
            await this.PopulateFormsAndActivitiesList(response.arg.activities);
            store.dispatch(formViewerActions.setSelectedFormToAdd(response.arg.activities[0]));
          }
          break;
        }
        case 'return_listk1s':
          response = JSON.parse(wsCommand.data);
          if (response.arg.k1List) {
            this.setState({
              k1List: response.arg.k1List,
              k1pkgList: response.arg.stateList,
              hideK1Flag: response.arg.hideK1Flag === '1', // hideK1Flag type needs to be verified and update logic to threeequals
            });
          }
          break;
        case 'return_addk1':
          response = JSON.parse(wsCommand.data);
          if (response.arg.NavPanelItems) {
            store.dispatch(
              returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems),
            );
          }
          if (response.arg.formOccur) {
            this.calcAPI.ReturnFormLoad(response.arg.formOccur, '', '', '');
          }
          break;
        case 'return_deletek1':
          response = JSON.parse(wsCommand.data);
          if (response.arg.k1List) {
            this.setState({ k1List: response.arg.k1List });
          }
          if (response.arg.NavPanelItems) {
            store.dispatch(
              returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems),
            );
          }
          break;
        case 'return_hidek1s':
          response = JSON.parse(wsCommand.data);
          if (response.arg.NavPanelItems) {
            store.dispatch(
              returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems),
            );
          }
          break;
        case 'return_emailk1':
          response = JSON.parse(wsCommand.data);
          if (response.arg.emailBlob && response.arg.emailBody && response.arg.sendTo) {
            // Shim existing email function for sending K-1 PDF
            XlinkAPI.emailEncryptedDoc(
              response.arg.sendTo, // tpEmail
              '', // spEmail
              '', // repEmail
              'Schedule K1', // emailSubject
              '', // password (implicit from email body, same as desktop behavior)
              response.arg.emailBody,
              response.arg.emailBlob,
              this.state.preparerID,
              true, // tpEmailChecked
              false, // spEmailChecked
              false, // repEmailChecked
              true, // tpEmailPassChecked
              true, // spEmailPassChecked
              true, // repEmailPassChecked
            )
              .then(() => {
                this.props.toggleEncryptedPdf(false);
                this.props.setSentSuccessTitle('Schedule K1 Sent');
                this.props.setSentSuccessMsg('The attached document has been sent successfully');
                this.props.setEncryptedPDFSuccessDialog(true);
                this.setState({ showFinalPDF: true });
              })
              .catch(error => {
                ErrorHelpers.handleError('Error Sending Schedule K1 PDF.', error);
              });
          }
          break;
        case 'return_deleteasset':
          response = JSON.parse(wsCommand.data);
          if (response.arg.activities) {
            store.dispatch(formViewerActions.setActivityList(response.arg.activities));
          }
          this.toggleAssetSnackbar('Asset Deleted', true);
          this.props.needingVerifiedSet(true);
          break;
        case 'return_reassignassetlist':
          response = JSON.parse(wsCommand.data);
          if (response.arg.activities) {
            this.setState({
              reassignAssetList: response.arg.activities,
            });
          }
          this.props.needingVerifiedSet(true);
          break;
        case 'return_reassignasset':
          response = JSON.parse(wsCommand.data);
          if (response.arg.activities) {
            store.dispatch(formViewerActions.setActivityList(response.arg.activities));
          }
          this.toggleAssetSnackbar('Asset Assigned', true);
          this.props.needingVerifiedSet(true);
          break;
        case 'return_addform':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems));
          this.props.needingVerifiedSet(true);
          break;
        case 'return_removeform':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems));
          this.props.needingVerifiedSet(true);
          break;

        case 'return_removepackage':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems));
          this.props.needingVerifiedSet(true);
          break;
        case 'return_prompt_prioryear': {
          // TODO --> The flow for importing a current year return is broken right now. We should ask the user outside of a return
          // when they first add an ssn if they want to use an existing return.
          const promptPrior = JSON.parse(this.trimNull(wsCommand.data));
          this.colorMap = promptPrior.colorMap;
          response = JSON.parse(this.trimNull(promptPrior.calcResponse));
          store.dispatch(formViewerActions.setYTYReturns(response.pyReturns, response.cyReturns));
          this.props.primarySSNEIN !== '999999999' // Bypass YTY prompt for W7 returns
            ? store.dispatch(formViewerActions.openYTYDialog())
            : this.calcAPI.ReturnFormLoad('000A00', '', '', '');

          if (Object.prototype.hasOwnProperty.call(promptPrior, 'locked_by')) {
            store.dispatch(
              returnProfileActions.lockReturn(promptPrior.locked_by, promptPrior.lockedByLoginID),
            );
          }
          break;
        }
        case 'return_prioryear_info': {
          const prioryearInfo = JSON.parse(this.trimNull(wsCommand.data));
          this.colorMap = prioryearInfo.colorMap;
          response = JSON.parse(this.trimNull(prioryearInfo.calcResponse));
          store.dispatch(formViewerActions.setYTYReturns(response.pyReturns, response.cyReturns));
          this.doInitFormLoad();
          break;
        }
        case 'return_profile':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems));
          break;
        case 'load_list':
          break;
        case 'get_statement':
          break;
        case 'return_print_query':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.error) {
            this.props.showErrorDialog(response.error);
            this.stopSpin();
            break;
          }
          this.stopSpin();

          if (Object.prototype.hasOwnProperty.call(response, 'arg')) {
            const cmd = response.arg.menuCmd;
            if (cmd) {
              this.handleMenuCommand(cmd, response);
              break;
            }

            if (!response.arg.MSGBOX) {
              this.props.signaturesNeededSet(response.arg.signquery);
              this.props.bankAppAttachedSet(!!response.arg.bankDoc);
            }

            const printState = update(this.state.print, {
              reqSigs: { $set: response.arg.signquery },
              ppSignature: { $set: response.arg.pp },
              efinSignature: { $set: response.arg.efin },
              ppxSignature: { $set: response.arg.ppx },
              pActionEligible: {
                $set: response.arg.pActionEligible,
              },
              cltr: { $set: response.arg.cltr },
            });
            this.props.setPreparerID(response.arg.pp);
            this.props.setAmendedPreparerID(response.arg.ppx);
            this.setState({
              bankID: response.arg.bankDoc,
              print: printState,
            });
            // Save the suggested password to redux state
            if (response.arg.suggestedPwd !== undefined) {
              this.props.setSuggestedPassword(response.arg.suggestedPwd);
            }

            !this.props.isSignatureRequestActive &&
              this.props.togglePrintDialog(!response.arg.MSGBOX && true);

            if (response.arg.MSGBOX) {
              this.props.openMsgDialog(response.arg.MSGBOX);
            }
          }
          this.setState({
            returnPrintQueryLoading: false,
          });
          break;
        case 'return_print':
          response = JSON.parse(this.trimNull(wsCommand.data));

          if (response.error) {
            this.props.showErrorDialog(response.error);
            this.stopSpin();
            break;
          }
          !this.props.isSignatureRequestActive && this.stopSpin();

          if (Object.prototype.hasOwnProperty.call(response, 'arg')) {
            const { PDF, emailBlob, MSGBOX, NavPanelItems } = { ...response.arg };
            const { pdfBLOB, pdfBLOBEnc, GUID, msgBlob } = { ...PDF };
            if (PDF) {
              // Add code to save the client letter content, which will also be used within the Mandrill template
              if (emailBlob) {
                const receivedEmailBlob = '{' + atob(emailBlob) + '}'; // Adding missing {}
                // Need to convert rtf email blob to html content
                rtfToHTML.fromString(receivedEmailBlob, (_err, html) => {
                  // Save emailBlob contents to global state
                  this.props.setEmailBlob(html);
                });
                // Save encrypted pdf contents as well
                this.props.setEncryptedDocumentContent(pdfBLOBEnc !== '' ? pdfBLOBEnc : msgBlob);
              }
              // Always display print and archive document, when sign doc, or remote sign are selected
              if (this.state.showFinalPDF === true) {
                // If one of the signing methods is 'Remote Signature' then do not render the pdf
                if (!this.props.isRMSSigningMethod) {
                  this.displayPrint(pdfBLOB);
                }

                this.props.setRMSflag(false);
                // Show copy of signed pdf on Document Archive Tab
                this.getLatestArchivedDocuments();
                this.props.inOfficeSignSuccessfulSet(true);
              } else {
                // Show copy of signed pdf on Document Archive Tab
                this.getLatestArchivedDocuments();

                if (this.props.isSignatureRequestActive && MSGBOX === undefined) {
                  sendSignatureRequest(
                    this.props.returnID,
                    this.state.print.reqSigs,
                    pdfBLOB,
                    GUID,
                    msgBlob,
                    this.signatureRequestData,
                    this.props.eroData,
                    this.props.preparerData,
                    this.props.amendedPreparerData,
                    this.handleSigningInOffice,
                    this.state.bankID,
                    this.props.initialSigneeData?.signatureDocID,
                    this.state.season,
                    this.stopSpin,
                    this.payload?.is_business,
                  );
                }
                if (!this.props.encryptedPdfSelected) {
                  this.setState({ showFinalPDF: true });
                } else {
                  this.props.setEncryptedPdfRequest(false);
                  this.props.toggleEncryptedPdf(true);
                }
              }
            }

            // remove the if block to bypass wallet
            if (MSGBOX !== undefined) {
              // Always display a message box whether or not a pdf is included or not
              this.props.openMsgDialog(response.arg.MSGBOX);
            } else {
              store.dispatch(returnProfileActions.setActiveReturnFormList(NavPanelItems));
            }

            this.props.inOfficeSignSuccessfulSet(false);
            this.stopSpin();
          }
          break;
        case 'start_spin':
          this.startSpin();
          break;
        case 'stop_spin':
          this.stopSpin();
          break;
        case 'invalid_request':
          console.log('Invalid Request with data: ' + wsCommand.data);
          break;
        case 'special_form_load':
          this.handleSpecialFormLoadResponse(JSON.parse(this.trimNull(wsCommand.data)));
          this.stopSpin();
          break;
        case 'return_add_document':
          response = JSON.parse(this.trimNull(wsCommand.data));

          if (Object.prototype.hasOwnProperty.call(response, 'calcres')) {
            calcres = JSON.parse(this.trimNull(response.calcres));
            store.dispatch(returnProfileActions.setActiveReturnFormList(calcres.arg.NavPanelItems));
          } else {
            store.dispatch(
              returnProfileActions.addDocument(
                response.document_id,
                response.document_type,
                response.document_name,
                response.document_description,
                response.create_date,
                response.document_status_var,
                response.document_media_type,
              ),
            );
          }
          this.props.showSnackbarMessage('Document has been saved.', 'success', 2500, {
            vertical: 'top',
            horizontal: 'center',
          });
          this.getLatestArchivedDocuments();
          break;
        case 'return_remove_document':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.props.removeDocument(response.document_id.toString());
          this.getLatestArchivedDocuments();
          break;
        case 'return_document_detach':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(
            returnProfileActions.detachDocument(response.document_id, response.document_status_var),
          );
          calcres = JSON.parse(this.trimNull(response.calcres));
          store.dispatch(returnProfileActions.setActiveReturnFormList(calcres.arg.NavPanelItems));
          break;
        case 'return_stateacks':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.stopSpin();
          if (typeof response.arg.MSGBOX === 'undefined') {
            this.props.setStateAcks(response.arg.States);
            this.openPrintStateAcksModal(false);
          } else {
            this.props.openMsgDialog(response.arg.MSGBOX);
          }
          break;
        case 'return_amended_stateacks':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.stopSpin();
          if (typeof response.arg.MSGBOX === 'undefined') {
            this.props.setStateAcks(response.arg.States);
            this.openPrintStateAcksModal(true);
          } else {
            this.props.openMsgDialog(response.arg.MSGBOX);
          }
          break;
        case 'return_attachedstates':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.stopSpin();
          if (typeof response.arg.MSGBOX === 'undefined') {
            this.props.setAttachedStates(response.arg.States);
            this.openPrintStateClientLetters();
          } else {
            this.props.openMsgDialog(response.arg.MSGBOX);
          }
          break;
        case 'return_xmitlist':
          response = JSON.parse(this.trimNull(wsCommand.data));

          if (Object.prototype.hasOwnProperty.call(response, 'arg')) {
            const cmd = response.arg.menuCmd;
            if (cmd) {
              this.handleMenuCommand(cmd, response);
            } else if (response.arg.MSGBOX) {
              this.props.openMsgDialog(response.arg.MSGBOX);
            } else {
              this.setState(
                {
                  transmitItemList: response.arg.XmitListItems,
                  feeItemList: response.arg?.FeeListItems || [],
                  feeTotal: response.arg?.FeeTotal || '',
                  walletBalance: response.arg?.WalletBalance || '',
                  wndwNote: response.arg?.WndwNote || '',
                },
                this.openQueueReturnModal,
              );
            }
          }
          break;
        case 'return_tvasend':
          response = JSON.parse(this.trimNull(wsCommand.data));
          // proceed with next TVA step
          this.setState({ TVAprogress: 25 });
          if (response.arg?.MSGBOX) {
            // if we are in here an error has occured (Ai information retrieval send)
            this.setState({ isVisionModalOpen: false, TVAprogress: 0 });
            this.props.openMsgDialog(response.arg.MSGBOX);
          } else {
            // delay to allow TVA AI time to process image
            setTimeout(() => {
              this.calcAPI.TVARetreive(response.arg.tvaID);
            }, 1000);
          }
          break;
        case 'return_tvaretrieve':
          response = JSON.parse(this.trimNull(wsCommand.data));
          // proceed with next TVA step
          this.setState({ TVAprogress: 50 });
          if (response.arg?.MSGBOX) {
            // if we are in here an error has occured (AI information retreival)
            this.setState({ isVisionModalOpen: false, TVAprogress: 0, TVARetrieveCount: 0 });
            this.props.openMsgDialog(response.arg.MSGBOX);
          } else if (this.state.TVARetrieveCount > 10) {
            // Transaction was tried 10 times (30 second wait), error out
            this.props.showErrorDialog(
              'Service is currently unavailable. Please contact technical support for assistance',
            );
            this.setState({ isVisionModalOpen: false, TVAprogress: 0, TVARetrieveCount: 0 });
          } else {
            // retry transaction
            setTimeout(() => {
              this.setState({ TVARetrieveCount: ++this.state.TVARetrieveCount });
              this.calcAPI.TVARetreive(response.arg.tvaID);
            }, 3000);

            // Currently unused, keeping in case we want to re-seperate the transactions
            // setTimeout(() => {
            //   this.calcAPI.TVAUpdate(response.arg.tvaID);
            // }, 3000);
          }
          break;
        case 'return_tvaupdate':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.setState({ TVAprogress: 75 });
          if (response.arg?.MSGBOX) {
            // single button indicates error
            if (response.arg.MSGBOX?.Btns.length === 1) {
              this.setState({ isVisionModalOpen: false, TVAprogress: 0, TVARetrieveCount: 0 });
            } else {
              // user choice
              this.setState({
                tvaObject: response.arg?.tvaObject,
                tvaID: response.arg?.tvaID,
                TVARetrieveCount: 0,
              });
            }
            this.props.openMsgDialog(response.arg.MSGBOX);
          } else if (response.arg?.formLoad) {
            // dropping here means w-2 was added
            this.setState({ TVAprogress: 100 });
            // Artifical Delay to allow user to see bar reach 100% before closing modal
            setTimeout(() => {
              this.setState({ isVisionModalOpen: false, TVAprogress: 0, TVARetrieveCount: 0 });
            }, 500);
            this.calcAPI.ReturnFormLoad(response.arg.formLoad, '', '', '');
          } else {
            this.setState({ TVAprogress: 100 });
            this.UpdateForm(response.arg);
            // Artifical Delay to allow user to see bar reach 100% before closing modal
            setTimeout(() => {
              this.setState({ isVisionModalOpen: false, TVAprogress: 0, TVARetrieveCount: 0 });
            }, 500);
          }
          break;
        case 'return_xmitqueue':
          // success if nothing return in data field
          if (wsCommand.data === '') {
            this.props.showSnackbarMessage('Tax Return Queued For Transmission', 'info');
            this.closeQueueReturnModal();
            this.closeAndSaveReturn();
            break;
          }

          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response?.arg?.MSGBOX) {
            store.dispatch(formViewerActions.openMsgDialog(response.arg.MSGBOX));
          }
          break;
        case 'submit_for_review':
          this.props.showSnackbarMessage(
            `Tax Return ${this.state.readyForReview ? 'submitted for' : 'retracted from'} review`,
            'info',
          );
          break;
        case 'return_bill_reload':
          response = JSON.parse(this.trimNull(wsCommand.data)); // Currently do nothing with the response, just trigger current active form reload
          this.getTaxForm(this.state.activeForm, '', this.state.activeFile, '');
          break;
        case 'return_stockImport':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.error) {
            this.setState({
              importStockMsg: response.error,
              btnClickSpinner: false,
            });
          } else {
            this.setState({
              showImportStock: false,
              importStockMsg: '',
              btnClickSpinner: false,
            });
            this.getTaxForm(this.state.activeForm, '', this.state.activeFile, '');
          }
          break;
        case 'return_form_error':
          try {
            response = JSON.parse(this.trimNull(wsCommand.data));
            if (response.error && response.initialField) {
              this.onUpdateFormError(response);
            } else if (
              response.error.includes(
                'Another return with the same SSN is already using a filing status of ',
              )
            ) {
              this.handleFilingStatusError(response.error);
            } else if (response.error) {
              this.showErrorSnackbar(response.error);
            } else {
              this.showErrorSnackbar('Return form error');
            }
          } catch (error) {
            ErrorHelpers.handleError('Unable to complete action', error);
          }
          this.stopSpin();
          break;
        case 'return_request_acknowledged':
          console.log('Request Acknowledged');
          break;
        case 'return_open_hyperlink':
          window.open(wsCommand.data);
          break;
        case 'queueReturn':
          this.handleQueueReturn();
          break;
        case 'printFinal':
          this.handlePrintFinal();
          break;
        case 'verifyReturn':
          this.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
          break;
        case 'return_payments_query':
          response = JSON.parse(this.trimNull(wsCommand.data));
          this.setState(
            {
              returnPaymentInfo: response.arg,
            },
            () => this.stopSpin(),
          );
          break;
        case 'return_payments':
          this.props.showSnackbarMessage(`Payment Details Saved`, 'success');
          break;
        case 'return_bookmark_query':
          // This CS transaction needs refactored. Currently it has two different functionalities that is then ovewritting our local state.
          // It is handling fetching all bookmarks and fetching a single bookmark. We already fetch all bookmarks so we should have been filtering our local state for the single bookmark
          // This functionality/logic is already intertwined.
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.arg.bookmarks.length === 0) {
            this.setBookmarkFldEditor(true);
            return;
          }
          this.handleBookmarkField(response.arg.bookmarks);
          break;
        case 'return_bookmark_upsert':
          response = JSON.parse(this.trimNull(wsCommand.data));
          store.dispatch(returnProfileActions.setActiveReturnFormList(response.arg.NavPanelItems));
          break;
        case 'return_toggle_status_lock':
          this.toggleFieldLock(!this.props.lockedStatus);
          try {
            await XlinkAPI.setReturnLockStatus(this.props.returnID, this.props.lockedStatus);
          } catch (err) {
            console.log('Error with locking/unlocking return: ', err);
          }
          this.stopSpin();
          break;
        case 'return_notes_upsert':
          response = JSON.parse(this.trimNull(wsCommand.data));
          // Reload notes
          this.handlePreparerNotesReq(response.arg.notes);
          this.props.showSnackbarMessage('Note updated', 'success');
          break;
        case 'return_notes_delete':
          response = JSON.parse(this.trimNull(wsCommand.data));
          // Reload notes
          this.handlePreparerNotesReq(response.arg.notes);
          this.props.showSnackbarMessage('Note deleted', 'success');
          break;
        case 'return_copy_to_training_dupe_check':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.dupessn) {
            // Opens the dialog asking if user wants to overwrite the return
            this.setState({
              dupeSSNCopyRtnOpen: true,
            });
          } else {
            this.copyToTraining();
          }
          break;
        case 'return_copy_to_live_dupe_check':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.dupessn) {
            // Opens the dialog asking if user wants to overwrite the return
            this.setState({
              dupeSSNCopyLiveRtnOpen: true,
            });
          } else {
            this.copyToLive();
          }
          break;
        case 'send_textlink_message':
          // Check if we did not send an email for encrypted pdf documents
          if (
            (this.props.taxpayerChecked ||
              this.props.taxpayerEPasshecked ||
              this.props.spouseChecked ||
              this.props.spouseEPassChecked) === false
          ) {
            store.dispatch(formViewerActions.toggleTextlinkConfirmSentDlg(true));
          }
          break;
        case 'send_taxpass_message':
          this.props.showSnackbarMessage('Message Sent', 'success');
          this.setState({ toggleTaxpassReload: !this.state.toggleTaxpassReload });
          break;
        case 'payjunction_terminal_status':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response.status) {
            this.setPaymentTerminalStatus(response);
          }
          break;
        case 'get_sms_message':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response?.arg?.txtSmsBlob) {
            this.props.toggleTextLinkSendModalOriginal(true, response.arg.txtSmsBlob);
          } else {
            console.log('Missing text body client letter');
          }
          break;
        case 'return_linkage':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response && response.arg) {
            this.setState({ fieldFormLinks: response.arg });
          }
          break;
        case 'return_linkPick':
          response = JSON.parse(this.trimNull(wsCommand.data));
          if (response && response.arg && response.arg.navTo) {
            this.calcAPI.ReturnFormLoad(response.arg.navTo, '', '', '');
          }
          break;
        case 'return_restricted_field':
          form = JSON.parse(this.trimNull(wsCommand.data));
          if (Object.prototype.hasOwnProperty.call(form, 'arg')) {
            this.updateRestrictedFields(form.arg);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('error in on socket response', error);
      if (ENVIRONMENT === 'local' || ENVIRONMENT === 'development') {
        this.props.showErrorDialog('Internal CLO Error: Please check console for error details');
      }

      const wsCommandStr = JSON.stringify(wsCommand);
      if (error.stack) {
        this.calcAPI.ReturnRaiseError(wsCommandStr, error.toString(), error.stack.toString());
      } else if (error.response) {
        this.calcAPI.ReturnRaiseError(
          wsCommandStr,
          error.response.data.toString(),
          error.response.toString(),
        );
      }
      // Silently fails right now
    }

    // This needs to run last so that the HTML has time to load for us to be able to restrict fields if the return is locked
    this.lockFieldsIfRequired();
  };

  restorePreviousValue = () => {
    const vMap = this.valueMap;
    const preVal = vMap[this.previousEl.id].value;
    const jqueryID = this.formatIDForJquery(this.previousEl.id);
    const prevEl = $('#' + jqueryID);
    prevEl[0].value = preVal;
    // Focus the field
    prevEl.focus();
  };

  // when recieving an error from the backend relating to a duplicate filing status, restore preivous value and render snackbarerror
  handleFilingStatusError = error => {
    this.restorePreviousValue();
    this.switchFocusToNext(this.previousEl);

    setTimeout(() => {
      this.showErrorSnackbar(error);
    }, 250);
  };

  handleSpecialFormLoadResponse = data => {
    // if MSGBOX is present, display the message, load form regardless
    if (
      data.calcResponse &&
      data.calcResponse.arg !== undefined &&
      data.calcResponse.arg.MSGBOX !== undefined
    ) {
      store.dispatch(formViewerActions.openMsgDialog(data.calcResponse.arg.MSGBOX));
    }

    // All Binary attachment form names start with "DOX"
    if (data.form.slice(0, 3) === 'DOX') {
      this.displayPrint(data.calcResponse.PDFBlob);
      return;
    }

    const activeForm = data.form;
    switch (data.form) {
      case 'retn18':
        this.handleBookmarkField(data.calcResponse.arg.bookmarks, false);
        break;
      case 'retn22':
        this.calcAPI.ReturnFormLoad('000A00', '', '', '');
        this.props.toggleK1ManagerModal(true);
        break;
      case 'form12':
        this.updateAddFormList('BA');
        break;
      case 'prin12':
      case 'prin17':
      case 'prin18':
        this.handleRemoteSignSelected(activeForm);
        break;
      case 'retn19':
        // If the return is locked, notes will come back empty in this response.
        // We already save the notes to local state from the 'return_init'
        if (data.calcResponse.arg?.notes?.length > 0) {
          this.handlePreparerNotesReq(data.calcResponse.arg.notes);
        }
        break;
    }
    this.setState({
      specialFormLoaded: true,
      activeForm,
      assetMode: false,
    });
  };

  // TODO -- Pass this down to HeaderBarForms and only handle verify setting in FormViewer?
  handleQueueReturn = () => {
    this.setVerifyType('queueReturn');
    this.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
  };

  // TODO -- Pass this down to HeaderBarForms and only handle verify setting in FormViewer?
  handlePrintFinal = () => {
    this.setVerifyType('printFinal');
    this.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
  };

  handleSignReturn = () => {
    this.setVerifyType('signReturn');
    this.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
  };

  handleRequestDocs = () => {
    this.setVerifyType('requestDocs');
    this.verifyReturn(VERIFY_MODAL_TABS.VERIFY);
  };

  toggleFromBookmark = (toggle, field) => {
    this.setState({
      bookmarkField: field,
    });
  };

  handleBookmarkField = (bookmarkData, fieldEditor = true) => {
    let newBookmark = {};
    if (!fieldEditor) {
      newBookmark = update(this.state.bookmarks, {
        bookmarkData: { $set: bookmarkData },
      });
    } else {
      newBookmark = update(this.state.bookmarks, {
        bookmarkFldEditor: { $set: fieldEditor },
        currBookmarkDesc: { $set: bookmarkData[0].desc },
        currBookmarkTimestamp: { $set: bookmarkData[0].timestamp },
      });
    }
    this.setState({
      bookmarks: newBookmark,
    }); // Go through special formload if we are receiving more than one bookmark(SideBar)
  };

  setBookmarkFldEditor = val => {
    this.setState({
      bookmarks: {
        ...this.state.bookmarks,
        bookmarkFldEditor: val,
        currBookmarkDesc: '',
        currBookmarkTimestamp: '',
      },
    });
  };

  deleteBookmark = fldID => {
    const newBookmark = this.state.bookmarks.bookmarkData.filter(bookmark => {
      return bookmark.field !== fldID;
    });
    this.setState({
      bookmarks: {
        ...this.state.bookmarks,
        bookmarkData: newBookmark,
        currBookmarkDesc: '',
        currBookmarkTimestamp: '',
      },
    });
  };

  setBookmarkByFldID = (desc, timestamp, bookmarkDelete = false) => {
    this.calcAPI.ReturnBookmarkUpsert(
      this.state.activeForm,
      this.state.bookmarks.bookmarkFldID,
      desc,
      timestamp,
      bookmarkDelete,
    );
    this.setState({
      bookmarks: {
        ...this.state.bookmarks,
        currBookmarkDesc: '',
        currBookmarkTimestamp: '',
      },
    });
    this.setBookmarkFldEditor(false);
  };

  handleNoteUpsert = (note, deleteFlag = false) => {
    this.calcAPI.ReturnNotesUpsert(note, deleteFlag);
  };

  handleBookmarkField = (bookmarkData, fieldEditor = true) => {
    let newBookmark = {};
    if (!fieldEditor) {
      newBookmark = update(this.state.bookmarks, {
        bookmarkData: { $set: bookmarkData },
      });
    } else {
      newBookmark = update(this.state.bookmarks, {
        bookmarkFldEditor: { $set: fieldEditor },
        currBookmarkDesc: { $set: bookmarkData[0].desc },
        currBookmarkTimestamp: { $set: bookmarkData[0].timestamp },
      });
    }
    this.setState({
      bookmarks: newBookmark,
    }); // Go through special formload if we are receiving more than one bookmark(SideBar)
  };

  // Handles sorting and saving the calc response or an updated set of notes to local state.
  handlePreparerNotesReq = notes => {
    // notes = [{id: "2", timestamp: "11/24/1991 11:24:22", note: "222222This is a note, idk what to write, but this is a note bla bla bla bla bla", owner: "Person 11"},{id: "3", timestamp: "11/24/1991 11:24:22", note: "This is a note, idk what to write, but this is a note bla bla bla bla bla", owner: "Person 123"},
    // {id: "1", timestamp: "11/24/1991 11:24:22", note: "11111This is a note, idk what to write, but this is a note bla bla bla bla bla", owner: "adriane rambaran"}]
    // Sort the ids that way we can ensure the new
    notes.sort((a, b) => {
      return a.id - b.id;
    });
    this.setState({
      notes,
    });
  };

  // TODO: async safe?
  updateFieldValueCache = (field, value, nextField) => {
    const vMap = this.valueMap;
    if (vMap[field] === undefined) {
      this.valueMap = {
        ...vMap,
        [field]: {
          previousValue: '',
          value,
          nxtFld: nextField,
        },
      };
    } else {
      const pVal = vMap[field].value;
      const pNxtFld = vMap[field].nxtFld;
      if (pVal === value && pNxtFld === nextField) return; // do nothing on no update
      vMap[field] = {
        previousValue: pVal,
        value,
        nxtFld: nextField,
      };
      this.valueMap = vMap;
    }
  };

  onError = field => {
    if (field) {
      const jqueryID = this.formatIDForJquery(field);
      const errfld = $('#' + jqueryID);
      errfld.addClass('error');
      if (errfld?.[0]?.type === 'checkbox' || errfld?.[0]?.type === 'radio') {
        errfld[0].checked = false;
      }
    }
  };

  componentWillUnmount() {
    // Faster to name the three elements and remove by ID rather than by class.
    $('#taxprepJS').remove();
    $('#btstrapJquerySrc').remove();
  }

  loadSpecialForm = () => {
    if (this.state.activeForm.length >= 7) {
      // Worksheet/Overflow/Statement Form, populate slider instead
      return (
        <Slide direction="up" in={this.state.activeForm.length >= 7} mountOnEnter unmountOnExit>
          <SubformViewer
            viewAsset={this.viewAsset}
            getTaxForm={this.getTaxForm}
            activeForm={
              this.state.activeForm.slice(0, 3) === 'ovl'
                ? this.state.formStack[
                    this.state.formStack.length >= 1 ? this.state.formStack.length - 1 : 0
                  ].form
                : this.state.activeForm
            }
            activeFile={this.state.activeFile}
            // fromField keeps track of the field formload is called from
            // Used when calling getTaxForm from a nested worksheet/overflow/statement
            fromField={
              this.state.formStack.length >= 2
                ? this.state.formStack[this.state.formStack.length - 2].field
                : ''
            }
          />
        </Slide>
      );
    }
    switch (this.state.activeForm) {
      case 'retn24':
        return (
          <ArchivedDocuments
            getDocumentTypeList={this.getDocumentTypeList}
            openDocumentAttachModal={this.openDocumentAttachModal}
            attachments={this.props.attachments}
            detachDocument={this.onClickDetachDocument}
            closeAddDocumentModalOk={this.addOrAttachDocument}
            showSnackBarErr={this.showErrorSnackbar}
            locked={this.payload.is_tech_support_and_impersonating}
            returnID={parseInt(this.props.returnID)}
            newestAttachments={this.props.attachmentsListMostRecent}
            includeCompletedRS={this.getLatestArchivedDocuments}
            updatedDocList={this.props.setUpdatedAttachmentList}
            verifyReturn={() => this.handleRequestDocs()}
            toggleRequestDocsModal={this.toggleRequestDocsModal}
            isRequestDocumentModalOpen={this.state.isRequestDocumentModalOpen}
            formList={this.props.formList}
            readyForReview={this.state.readyForReview}
            isFeederOffice={this.props.officeProfile.is_feeder_office}
            isBusiness={this.payload?.is_business}
            saveReturn={this.saveReturn}
            getWalletToken={this.getWalletToken}
            removeDocument={this.onClickDeleteDocument}
          />
        );
      case 'prin12':
      case 'prin17':
      case 'prin18':
        return (
          <Signatures
            signatureRequestData={this.signatureRequestData}
            verifyReturn={() => this.handleSignReturn()}
            efinNumber={this.state.print.efinSignature}
            handleSigningInOffice={(sigDocID, remoteRequestSignatures, docType) =>
              this.handleSigningInOffice(sigDocID, remoteRequestSignatures, docType)
            }
            handleSigningRemotely={docType => this.handleSigningRemotely(docType)}
            isLocked={this.isLocked}
            isLoading={this.state.isLoading}
            returnPrintQueryLoading={this.state.returnPrintQueryLoading}
            handlePrint={(type, prefs, formOccur, menuText) =>
              this.handlePrint(type, prefs, formOccur, menuText)
            }
            saveReturn={this.saveReturn}
            returnSaved={this.state.returnSaved}
            readyForReview={this.state.readyForReview}
            isFeederOffice={this.props.officeProfile.is_feeder_office}
            printPrefs={this.state.print?.prefs}
            bankID={this.state.bankID}
          />
        );
      case 'form12':
        return (
          <AssetList
            updateActivities={pkg => this.updateAddFormList(pkg)}
            openAddFormList={(tab, index) => this.openAddFormList(tab, index)}
            viewAsset={id => this.viewAsset(id)}
            deleteAsset={id => this.deleteAsset(id)}
            fetchReassignAssetList={id => this.calcAPI.ReturnReassignAssetList(id)}
            reassignAssetList={this.state.reassignAssetList}
            reassignAsset={(astID, actvID) => this.reassignAsset(astID, actvID)}
            toggleSnackbar={(message, toggle) => this.toggleAssetSnackbar(message, toggle)}
            activityList={this.props.activityList}
            showSnackbar={this.state.assetSnackbar}
            snackbarMessage={this.state.assetSnackbarMsg}
            calcAPI={this.calcAPI}
            locked={this.isLocked()}
            tabListDefinitions={this.state.tabListDefinitions}
            readyForReview={this.state.readyForReview}
            isFeederOffice={this.props.officeProfile.is_feeder_office}
          />
        );
      case 'retn18':
        return (
          <Bookmarks
            bookmarks={this.state.bookmarks.bookmarkData}
            setBookmark={(a, b, c, d, e) => this.calcAPI.ReturnBookmarkUpsert(a, b, c, d, e)}
            viewBookmark={(a, b) => this.calcAPI.ReturnBookmarkQuery(a, b)}
            showErrorDialog={this.props.showErrorDialog}
            getTaxForm={this.getTaxForm}
            deleteBookmark={this.deleteBookmark}
            snackbarToggle={this.showPromptSnackbar}
            toggleFromBookmark={(toggle, field) => this.toggleFromBookmark(toggle, field)}
            locked={this.payload.is_tech_support_and_impersonating}
          />
        );
      case 'retn19':
        return (
          <ReturnNotes
            notes={this.state.notes}
            noteUpsert={this.handleNoteUpsert}
            locked={this.payload.is_tech_support_and_impersonating}
            readyForReview={this.state.readyForReview}
            isFeederOffice={this.props.officeProfile.is_feeder_office}
          />
        );
      default:
        return <div id="taxform" />;
    }
  };

  onUpdateFormError = data => {
    const field = data.initialField;
    const jqueryID = this.formatIDForJquery(field);
    const element = $('#' + jqueryID);
    const pval = this.valueMap[field].value;
    const pNextField = this.valueMap[field].nxtFld;
    element[0].value = pval;

    element.focus();
    element.addClass('error');
    this.updateFieldValueCache(field, pval, pNextField);
    if (data.error) {
      this.showErrorSnackbar(data.error);
    }
  };

  // unbind all listeners
  unbindFormEvents() {
    $('.form_field').off();
  }

  // TODO --> Insert element within the grid instead of calculating position. It may increase performance by removing the listener on window resize.
  renderButtonChoice = fieldID => {
    this.formContainerProps = document
      .getElementsByClassName('formContainer')[0]
      .getBoundingClientRect();
    this.formContainerProps.scrollTop = document.getElementsByClassName(
      'formContainer',
    )[0].scrollTop;
    const messageButton = document.getElementById('buttonMenuForReturns');
    let field = null;
    if (typeof fieldID !== 'string') {
      const focusedFldID = messageButton.getAttribute('focusedField');
      field = document.getElementById(focusedFldID);
      if (focusedFldID == null || focusedFldID === '') {
        return; // Do Nothing
      }
    } else {
      field = document.getElementById(fieldID);
    }

    const scrollLeft = document.getElementsByClassName('formContainer')[0]?.scrollLeft;

    const coords = field.getBoundingClientRect();
    const left = coords.left - this.formContainerProps.x + coords.width + scrollLeft + 'px';

    const top = coords.top + this.formContainerProps.scrollTop - this.formContainerProps.y + 'px';

    messageButton.style.left = left;
    messageButton.style.top = top;
    messageButton.style.height = coords.height + 'px';
    messageButton.style.setProperty('display', 'flex');
  };

  scrollPositionInit = () => {
    this.formContainerProps = document
      .getElementsByClassName('formContainer')[0]
      .getBoundingClientRect();
    this.formContainerProps.scrollTop = document.getElementsByClassName(
      'formContainer',
    )[0].scrollTop;
    if (this.formContainerProps.scrollTop === 0) {
      this.props.setInitScrollPositionTrue();
    } else {
      this.props.setInitScrollPositionFalse();
    }
  };

  // Bind jquery event listeners
  bindFormEvents() {
    this.handleFormButtonEleEvent(this);
    this.handleFormButtonIconEvent(this);
    this.handleFieldLookupEvent(this);
    this.handleWorksheetEvent(this);
    this.handleStatementEvent(this);
    this.handleFieldOverflowEvent(this);
    this.handleCheckboxDoubleClickEvent(this);

    // Form Fields Changing
    const formField = $('.form_field');
    this.handleFormFieldChangeEvent(this, formField);
    this.handleFormFieldFocusInEvent(this, formField);
    this.handleFormFieldFocusOutEvent(this, formField);
    this.handleFormFieldKeyDownEvent(this, formField);
  }

  handleFormButtonEleEvent = _ => {
    // Prevents from firing off multiple times .off('click')
    $('.form-button-element')
      .off('click')
      .on('click', e => {
        e.preventDefault();
        _.updateFormStack();

        // Edge case for the "Copy GUID" button at the bottom of the Info and Status page.
        if (e.currentTarget.id === '000a739') {
          navigator.clipboard.writeText(_.valueMap.GUID00.value).then(
            () => {
              _.props.showSnackbarMessage(
                _.valueMap.GUID00.value + ' copied to clipboard',
                'success',
                1500,
                {
                  vertical: 'top',
                  horizontal: 'center',
                },
              );
            },
            () => {
              _.showErrorSnackbar('Error copying to clipboard');
            },
          );
        } else {
          // This is being called on any button being pressed. We need to send the ID for it to map back to a command
          _.calcAPI.ReturnButtonPress(e.currentTarget.id, _.state.activeForm);
        }
      });
  };

  handleFormButtonIconEvent = _ => {
    $('.form-button-icon')
      .off('click')
      .on('click', e => {
        e.preventDefault();
        const containsActiveClass = e.currentTarget.classList.contains('form-button-icon-active');
        const svgid = e.currentTarget.getAttribute('svgid');
        if (containsActiveClass) {
          $(e.currentTarget).val('');
          e.currentTarget.classList.remove('form-button-icon-active');
          document.querySelector('#' + svgid + ' g').setAttribute('fill', '#FEFEFE'); // Set back to defualt Color
        } else {
          $(e.currentTarget).val('X');
          e.currentTarget.classList.add('form-button-icon-active');
          document.querySelector('#' + svgid + ' g').setAttribute('fill', '#d7ecf7'); // Set to selected color
        }

        _.calcAPI.ReturnCalc(
          _.state.activeForm,
          assetFormRegExp.test(_.state.activeForm) ? 'X' : '',
          e.currentTarget.id,
          $(e.currentTarget).val(),
          CALC_TYPE.FIELD_CALC,
        );
      });
  };

  handleFieldLookupEvent = scope => {
    $('.field_lookup')
      .keydown(e => {
        if (e.target.classList.contains('read-only') || e.target.classList.contains('restricted')) {
          return;
        }
        if (e.altKey && e.which === 67) {
          // alt+c
          scope.openChoiceList(scope.state.activeForm, e.target.id);
        }
      })
      .change('focus', e => {
        // Checks if field is a choicelist field and has an exclusive flag
        // if field is exclusive, clear out any value not found within
        // the relevant choicelist
        if (e.target.classList.contains('exclusive')) {
          const isMissingFromList = scope.checkChoiceListForValue(e.target);
          if (isMissingFromList) {
            e.target.value = '';
          }
        }
      })
      .keyup(
        _.debounce(e => {
          if (
            !(
              e.target.classList.contains('read-only') || e.target.classList.contains('restricted')
            ) &&
            e.target.value?.length > 2
          ) {
            scope.calcAPI.ReturnChoiceList(scope.state.activeForm, e.target.id, e.target.value);
          }
        }, 500),
      );
  };

  handleWorksheetEvent = _ => {
    // Worksheet handling
    $('.field_wks').keyup(e => {
      if (e.altKey && e.which === 87) {
        // alt+w
        const form = _.state.activeForm + e.target.getAttribute('stmcode');
        _.getTaxForm(form, 'wks', '', _.currentEl.id);
      }
    });
  };

  handleStatementEvent = _ => {
    // Statement handling
    $('.field_stm').keyup(e => {
      if (e.altKey && e.which === 87) {
        const form = _.state.activeForm + e.target.getAttribute('stmcode');
        _.getTaxForm(form, 'stm', '', _.currentEl.id);
      }
    });
  };

  handleFieldOverflowEvent = _ => {
    // Overflow handling
    $('.field_ovl').keyup(e => {
      if (e.altKey && e.which === 79) {
        // alt+o
        const hasStm = e.target.classList.contains('field_stm');
        const hasWks = e.target.classList.contains('field_wks');
        if (hasStm) {
          const form = _.state.activeForm + e.target.getAttribute('stmcode');
          _.getTaxForm(form, 'stm', '', _.currentEl.id);
        } else if (hasWks) {
          const form = _.state.activeForm + e.target.getAttribute('stmcode');
          _.getTaxForm(form, 'wks', '', _.currentEl.id);
        } else {
          _.getTaxForm(_.state.activeForm, 'ovl', '', _.currentEl.id);
        }
      }
    });
  };

  handleChangeHideK1s = flag => {
    this.setState({
      hideK1Flag: flag,
    });
  };

  handleToggleK1ManagerModal = toggle => {
    // 0 = show, 1 = do not show, see corpnav, :NAVLINE
    this.calcAPI.ReturnHideK1s(this.state.hideK1Flag ? '1' : '0');
    this.props.toggleK1ManagerModal(toggle);
  };

  handleCheckboxDoubleClickEvent = _ => {
    // DoubleClick handling
    $('.form-checkbox-input').dblclick(e => {
      e.preventDefault();
      let value = '';
      if (e.target.value === '') {
        value = 'X';
      }
      _.performCalcProcess(
        this.state.activeForm,
        assetFormRegExp.test(_.state.activeForm) ? 'X' : '',
        e.target.id,
        value,
        CALC_TYPE.FIELD_CALC,
        1,
      ); // set vfyTab to 1 to go to "Verify" Tab if in verify mode (verifyDirty)
    });
  };

  handleFormFieldChangeEvent = (_, formField) => {
    formField.change(e => {
      const jqueryID = this.formatIDForJquery(e.target.id);
      // do calc here
      if (_.id !== _.state.prevField) {
        _.setState({
          prevField: _.id,
          prevFocusedFieldVal: $('#' + jqueryID).data('val'),
          currFieldBeforeChangeVal: $('#' + jqueryID).val(),
        });
      }
    });
  };

  handleFormFieldFocusInEvent = (_, formField) => {
    formField.focusin(e => {
      if (_.previousEl === e.target) {
        return;
      }

      const el = document.getElementById('buttonMenuForReturns');
      el.style.setProperty('display', 'flex');
      el.setAttribute('focusedField', e.target.id);

      _.prevFieldVal = e.target.value;

      // normalize value for edit
      _.fieldVal = e.target.value; // store an unnormalized value
      const ctl = document.getElementById(e.target.id);
      // trim value
      ctl.value = ctl.value.trim();
      _.dirtyFlag = true;

      // Options for focused in field.
      const fieldButtonMenuChoices = [];
      // Each field will always have a Clear Override and Bookmark Field option.
      fieldButtonMenuChoices.push(
        {
          name: 'Clear Override',
          func: () =>
            _.performCalcProcess(
              _.state.activeForm,
              assetFormRegExp.test(_.state.activeForm) ? 'X' : '',
              e.target.id,
              e.target.value,
              CALC_TYPE.OVERRIDE_CLEAR,
              1,
            ), // set vfyTab to 1 to go to "Verify" Tab if in verify mode (verifyDirty)
        },
        {
          name: 'Bookmark Field',
          func: () => {
            // eslint-disable-next-line no-unused-expressions
            _.calcAPI.ReturnBookmarkQuery(_.state.activeForm, e.target.id);
            _.setState({
              bookmarks: {
                ..._.state.bookmarks,
                bookmarkFldID: e.target.id,
              },
            });
          },
        },
      );

      // Display field prompt
      const spanEl = $(e.target).siblings('span');
      spanEl.addClass('transition');
      spanEl.parent('div').css('z-index', '6');
      _.nextField = $(e.target).attr('nextfld');
      _.currentEl = e.target;

      if ($(e.target).attr('stmcode') !== undefined) {
        _.stmcode = true;
      } else {
        _.stmcode = false;
      }

      if (e.target.getAttribute('stmcode')) {
        const form = _.state.activeForm + e.target.getAttribute('stmcode');
        const wks = e.target.classList?.contains('field_wks');
        const manualEnableWksButton = e.target.classList?.contains('manual_enable_wks_btn');
        const stm = e.target.classList.contains('field_stm') ? 'stm' : 'wks';
        fieldButtonMenuChoices.push({
          name: 'Worksheet',
          func: () => _.getTaxForm(form, stm, '', _.currentEl.id),
        });
        if ((form && _.state.activeForm.length < 7) || wks || manualEnableWksButton)
          _.showPromptSnackbar('Press alt+w for worksheet');
      } else {
        _.hidePromptSnackbar();
      }
      if (
        e.target.getAttribute('choices') &&
        !(e.target.classList.contains('read-only') || e.target.classList.contains('restricted'))
      ) {
        fieldButtonMenuChoices.push({
          name: 'Choices',
          func: () => _.openChoiceList(_.state.activeForm, e.target.id),
        });
        _.showPromptSnackbar('Press alt+c for choices');
        _.choices = true;
      } else {
        _.hidePromptSnackbar();
        _.choices = false;
      }
      _.setState({
        menuButtonsChoices: fieldButtonMenuChoices,
      });

      // Reset fieldFormLinks for the active input
      // if linkage attribute does not exist.
      if (!e.target.getAttribute('linkage')) {
        _.setState({ fieldFormLinks: {} });
      }

      const jqueryID = this.formatIDForJquery(e.target.id);

      $('#' + jqueryID).data('val', $('#' + jqueryID).val());

      // Move the form button to the target element
      _.renderButtonChoice(e.target.id);

      // grabs current field on interview forms so it can be refocused onto after a form load
      if (_.state.interviewMode) {
        const mapInterviewCurrEle = this.state.interviewCurrentEleMap;
        _.setState({
          interviewCurrentEleMap: mapInterviewCurrEle.set(this.state.activeForm, _.currentEl),
        });
      }
    });
  };

  // add escape characters to special occurance characters for jquery selectors
  formatIDForJquery = rawID => {
    let cleanID = rawID.replace('@', '\\@');
    cleanID = cleanID.replace('`', '\\`');

    return cleanID;
  };

  handleFormFieldFocusOutEvent = (_, formField) => {
    // using on blur to detect click and tab events
    formField.on('blur', e => {
      // There are times where input validation does not execute when previousEL matches current input.
      // Ignore if the input in question has a different value than what was contained
      if (
        _.previousEl === e.target &&
        _.valueMap &&
        _.valueMap[e.target.id].value === e.target.value &&
        !(e.target.id === 'TEXT00' || e.target.id === 'STXT00') // texting consent field, always run validation for them
      ) {
        return;
      }

      _.previousEl = e.target;
      const jqueryID = this.formatIDForJquery(e.target.id);

      $('#' + jqueryID).removeClass('error'); // remove any error that may exist on the field
      // Hide field prompt
      const spanEl = $(e.target).siblings('span');
      spanEl.removeClass('transition');
      spanEl.parent('div').css('z-index', '1');

      if (
        _.dirtyFlag &&
        (e.target.value.trim() === _.fieldVal.trim() ||
          (!e.target.value && !_.fieldVal.trim()) ||
          (PRESET_FORMATTING_TRIMMED.indexOf(_.fieldVal.trim()) > -1 &&
            e.target.value.length === 0))
      ) {
        // Restore un-normalized value for unmodified field
        e.target.value = _.fieldVal;
        _.dirtyFlag = false;
      }

      const dataFieldType = e.target.getAttribute('data-field-type');
      const fieldCharSet = e.target.getAttribute('data-field-charset');

      if (
        _.dirtyFlag &&
        !isValidCRYFieldFormat(
          dataFieldType,
          e.target.value,
          e.target.id,
          fieldCharSet,
          _.state.activeForm,
        ) &&
        !e.target.getAttribute('linkage')
      ) {
        let errorMsg = 'Invalid Data Value/Format.';
        const fieldLength = e.target.getAttribute('maxlength');
        switch (dataFieldType) {
          case 'D':
            if (parseInt(fieldLength) === 4) errorMsg += ' Use format MMYY.';
            if (parseInt(fieldLength) === 6) errorMsg += ' Use format MMDDYY.';
            if (parseInt(fieldLength) === 8) errorMsg += ' Use format MMDDYYYY.';
            break;
          case 'E':
          case 'S':
          case 'T':
            errorMsg += ' Expected NNNNNNNNN.';
            break;
          case 'Y':
            if (parseInt(fieldLength) === 4) errorMsg += ' Use format YYYY.';
            if (parseInt(fieldLength) === 6) errorMsg += ' Use format MMYYYY.';
            if (parseInt(fieldLength) === 8) errorMsg += ' Use format MMDDYYYY.';
            break;
          default:
            break;
        }
        _.showErrorSnackbar(errorMsg);
        e.target.value = _.fieldVal;
        _.dirtyFlag = false;
        _.fieldVal = '';
        return;
      }

      // consent form
      if (
        (e.target.id === 'TEXT00' || e.target.id === 'STXT00') &&
        e.target.value?.toUpperCase() === 'X' // manually entering lowercase creates a false negative, set to uppercase
      ) {
        _.setState({ showConsentForm: true });
      }

      const confrimedDblEntry =
        (this.props.enableDoubleEntry && this.isFieldDblBlind(e)) ||
        PERMANENT_DOUBLE_ENTRY_FIELDS.includes(e.target.id);

      // double blind field
      if (
        confrimedDblEntry &&
        (this.dirtyFlag || this.prevFieldVal !== e.target.value) && // User modified field value in some way
        e.target.value && // User inputted a value to store (blanking out will not trigger)
        (this.fieldVal.replace(/[-/,]|\s/g, '').trim() !==
          e.target.value.replace(/[-/,]|\s/g, '').trim() ||
          this.fieldVal.trim() !== e.target.value.trim()) && // before and after normalized values are different
        !this.state.showDblBlind
      ) {
        this.setState({ showDblBlind: true });
      }

      this.dirtyFlag = false;
      this.fieldVal = '';

      // State Interview Mode, Reset Selection if the user manually enters an invalid state.
      if (
        e.target.value &&
        (e.target.title === 'Non-Resident State Package' ||
          e.target.title === 'Resident State Package') &&
        !this.isPackageAvailable(e.target.value)
      ) {
        this.handleReturnFieldCalc(_.currentEl.id, 'XX');
        _.stmcode = false;
        _.choices = false;
        _.hidePromptSnackbar();
        return;
      }

      // Dont uncomment this. See Louie before doing this. Thank you
      // if (pVal !== e.target.value.replace(inputFilterFieldRegEx, "")) {
      if (
        _.valueMap &&
        _.valueMap[e.target.id].value !== e.target.value &&
        !this.state.showDblBlind
      ) {
        this.handleReturnFieldCalc(_.currentEl.id, _.currentEl.value);

        // If on a statement subform and editing the last table row of the form,
        // reload the form to get autoextended rows if they exist
        let currentRow = 0;
        if (tableRegExp.test(e.target.id)) {
          currentRow = translateSpecialOccuranceRow(e.target.id.substring(2, 4));
        }
        if (currentRow === _.maxTableRow && _.lastRow > 0) {
          const form = _.state.activeForm;

          this.setState({
            maintainAutoExtendFocus: true,
          });

          if (_.subFrm !== '' && _.subFrm !== 'ovl') {
            _.getTaxForm(form, 'stm', _.state.activeFile, _.currentEl.id, false);
          } else {
            _.getTaxForm(form, '', _.state.activeFile, '');
          }
        }
      }

      _.stmcode = false;
      _.choices = false;
      _.hidePromptSnackbar();
      // Keypress handlers
    });
  };

  handleReturnFieldCalc = (target, val) => {
    this.calcAPI.ReturnCalc(
      this.state.activeForm,
      assetFormRegExp.test(this.state.activeForm) ? 'X' : '',
      target,
      val,
      CALC_TYPE.FIELD_CALC,
    );
  };

  handleDblindOk = () => {
    // If the Double blind validation modal is hidden,
    // just return, no further execution needed.
    if (!this.state.showDblBlind) {
      return;
    }

    // normalize input for comparing
    let cField;
    let dbTextField;
    if (document.getElementById(this.currentEl.id) && document.getElementById('dblindTextField')) {
      cField = document.getElementById(this.currentEl.id);
      dbTextField = document.getElementById('dblindTextField');
    } else {
      this.showErrorSnackbar('Failed to find a double entry field');
      this.setState({ showDblBlind: false });
      return;
    }

    const dbTextInput = dbTextField.value.replace(/[-/,]|\s/g, '').trim();
    if (!dbTextInput) return;

    if (dbTextInput !== cField.value.replace(/[-/,]|\s/g, '').trim()) {
      this.showErrorSnackbar('Double entry mistached');
      dbTextField.focus();
      return;
    }

    this.setState({ showDblBlind: false });
    this.handleReturnFieldCalc(this.currentEl.id, this.currentEl.value);
  };

  handleDblindCancel = () => {
    let cField;

    if (document.getElementById(this.currentEl.id)) {
      cField = document.getElementById(this.currentEl.id);
    } else {
      this.showErrorSnackbar('Double blind entry field not found.');
      this.setState({ showDblBlind: false });
      cField.focus();
      return;
    }

    cField.value = this.prevFieldVal;
    this.setState({ showDblBlind: false });
    cField.focus();
  };

  isFieldDblBlind = e => {
    let isDblBlind = false;

    if (this.props.enableDoubleEntry === '1') {
      if (e.target.id) {
        isDblBlind = this.dblBlindList.includes(e.target.id.slice(0, 4));
      } else if (e.id) {
        isDblBlind = this.dblBlindList.includes(e.id.slice(0, 4));
      }
    }

    return isDblBlind;
  };

  handleFormFieldKeyDownEvent = (_, formField) => {
    formField.keydown(e => {
      const field = e.target.id;
      switch (e.which) {
        case 32: // spacebar
          if (e.target.classList.contains('form-checkbox-input')) {
            e.preventDefault();
            if (e.target.value === 'X') {
              $(e.target).val('');
              _.switchFocusToNext(e.target);
            } else {
              $(e.target).val('X');
              _.switchFocusToNext(e.target);
            }
          }
          break;
        case 9: // tab
          e.preventDefault();
          // shift + tab should focus backwards
          if (e.shiftKey) {
            _.switchFocusToPrev(e.target);
          } else {
            _.handleFocusBehavior(field, e.target);
          }
          break;
        case 13: // enter
          e.preventDefault();
          _.handleFocusBehavior(field, e.target);
          break;
        case 38: // up arrow
          // if field is being edited and field has an autocomplete lookup, fallback to default behavior
          if (_.isEditedField(e.target, field) && e.target.classList.contains('field_lookup')) {
            break;
          }
          e.preventDefault();
          e.stopImmediatePropagation();
          _.switchFocusToPrev(e.target);
          break;
        case 40: // down arrow, handled explicitily for Safari and other browsers on OSX
          // if field is being edited and field has an autocomplete lookup, fallback to default behavior
          if (_.isEditedField(e.target, field) && e.target.classList.contains('field_lookup')) {
            break;
          }
          e.preventDefault();
          e.stopImmediatePropagation();
          _.handleFocusBehavior(field, e.target);
          break;
        case 37: // left arrow, handled explicitily for Safari and other browsers on OSX
          // if field is read only or it is at begining of field we jump back
          if (
            e.target.classList.contains('read-only') ||
            e.target.classList.contains('restricted') ||
            e.target.selectionStart <= 0
          ) {
            e.preventDefault();
            e.stopImmediatePropagation();
            _.switchFocusToPrev(e.target);
          } else if (PRESET_FORMATTING_TRIMMED.indexOf(e.target.value) > -1) {
            // clear field such as date, ssn, EIN to remove preformatting
            e.target.value = '';
          }
          break;
        case 39: // right arrow
          // do not check for autocomplete lookup, pressing left or right should move fields regardless
          // if at end of field or read only continue to next field
          if (
            e.target.selectionStart >= e.target.value.length ||
            e.target.classList.contains('read-only') ||
            e.target.classList.contains('restricted')
          ) {
            e.preventDefault();
            e.stopImmediatePropagation();
            _.handleFocusBehavior(field, e.target);
          }
          break;
        case 27: // esc
          // if we've just tabbed onto the field (field value === cache value), restore previous value
          // if we're editing the field, (field value !=== cache value), restore current cache value
          if (_.isEditedField(e.target, field)) {
            e.target.value = _.valueMap[field].value;
          } else {
            e.target.value = _.valueMap[field].previousValue;
          }
          // }
          // recalc occurs on focusout
          break;
        case 46: // delete key
          // delete key deletes entire field
          if (
            !e.target.classList.contains('read-only') &&
            !e.target.classList.contains('restricted')
          ) {
            e.target.value = '';
          }
          break;
        case 189:
          if (e.target.getAttribute('data-field-type') === 'L' && e.target.value.length > 0) {
            stripSpecialChars(e);
          }
          break;
        case 8: // Backspace
          // Remove special characters (Anything not alphanumeric and spaces) when a user hits backspace to allow for editing of fields that have special characters. i.e. Date fields and SSN
          if (
            !e.target.classList.contains('read-only') &&
            !e.target.classList.contains('restricted')
          ) {
            if (PRESET_FORMATTING_TRIMMED.indexOf(e.target.value) > -1) {
              e.target.value = '';
            }
            // in case of negative to simplify validation we remove special characters but hold on to '-' to maintain negative value
            else if (e.target.getAttribute('data-field-type'.toUpperCase()) === 'L') {
              if (e.target.value[0] === '(' || e.target.value[0] === '-') {
                fixNegatives(e);
              } else {
                stripSpecialChars(e);
              }
            } else if (
              REMOVE_FORMATTING_FIELDS.indexOf(
                e.target.getAttribute('data-field-type').toUpperCase(),
              ) > -1
            ) {
              stripSpecialChars(e);
            }
          }
          break;
        default: {
          const input = e.key;
          if (checkActionKeys(e) && validChars.test(input) && !e.altKey) {
            if (
              DECIMAL_FIELDS.indexOf(e.target.getAttribute('data-field-type').toUpperCase()) > -1
            ) {
              const maxLen = e.target.getAttribute('maxLength');
              const len = e.target.value.length;
              if (parseInt(maxLen) === len) {
                const cur = e.target.selectionStart;
                const text1 = e.target.value.slice(0, cur);
                const text2 = e.target.value.slice(cur, len);
                e.target.value = text1.concat(input, text2);
              }
              if (PRESET_PERCENT_RATIO.indexOf(e.target.value) > -1) {
                e.target.value = '';
              }
            } else if (e.target.getAttribute('data-field-type').toUpperCase() === 'L') {
              if (e.target.value[0] === '(' || e.target.value[0] === '-') {
                fixNegatives(e);
              } else {
                stripSpecialChars(e);
              }
            } else if (
              PRESET_FORMATTING_TRIMMED.indexOf(e.target.value) > -1 ||
              e.target.value === '  /  /'
            ) {
              e.target.value = '';
            }

            fieldCharSetValidation(e);
          }

          // save the target value and the field ID
          // these state are used for autoextend + tab  value cases
          // see CLO-7937 for details
          this.setState({
            autoExtendTabValue: e.target.value + input,
            tabbedField: e.target.id,
          });

          break;
        }
      }
    });
  };

  // isEditedField checks if the given focused element is being edited. Checks the focusedEl's value against the value found in valueMap[fieldID]
  isEditedField = (focusedEl, fieldID) => {
    if (!this.valueMap[fieldID]) {
      return false;
    }
    const currValue = focusedEl.value;
    const currCacheValue = this.valueMap[fieldID].value;
    let isEdit = currValue !== currCacheValue;
    // need the following logic to account for blank date, zip and phone # fields
    if (
      currCacheValue === '   -   -' ||
      currCacheValue === '  /  /' ||
      currCacheValue === '     -    -'
    ) {
      isEdit = false;
    }

    return isEdit;
  };

  // Handle change focus behavior, requires focused field ID + focused element
  // if we've just tabbed onto then off the field (field value === cache value), handle next field/focus behavior
  // if we're editing the field, (field value !=== cache value), blur() off the field to trigger calc, next field/focus behavior is handled in calc response handler
  handleFocusBehavior = (fieldID, focusedEl) => {
    if (this.valueMap[fieldID]) {
      if (this.isEditedField(fieldID, focusedEl)) {
        $(focusedEl).blur();
      } else {
        if (this.valueMap[fieldID].nxtFld.length > 0) {
          this.handleNextFld(this.currentEl);
        } else {
          this.switchFocusToNext(this.currentEl);
        }
      }
    }
  };

  // Focus on next field found in 'nextfld' attribute of a field
  handleNextFld = el => {
    const nextField = this.valueMap[el.id].nxtFld;

    const jqueryID = this.formatIDForJquery(nextField);
    if (nextField.length > 0) {
      $('#' + jqueryID).focus();
    } else {
      this.switchFocusToNext(el);
    }
  };

  // Focus on next cellitem input, given that you are currently focused in a cellitem input
  switchFocusToNext = el => {
    const nextInputs = $(el).parent().nextUntil('.form-cellitem').children('.form_field');
    let nextInput = nextInputs[0];
    if (this.state.activeForm === 'BC0601' && el.id === 'BD0300' && nextInputs[1].id === 'BA0400') {
      // CLO-6607 .children() does not get the correct order for this field
      nextInput = nextInputs[1];
    }
    // initial check to skip, no point in checking for hidden items
    if (nextInput !== undefined) {
      let counter = 1;
      // loop until the next input is not a hidden field
      while (nextInput?.classList.contains('hide') && counter !== nextInputs.length) {
        nextInput = nextInputs[counter];
        counter++;
      }
      // If the input contains 'hide' in classList, there is still a chance nextInput could get updated to undefined
      if (nextInput !== undefined) {
        nextInput.focus();
      }
    }
  };

  switchFocusToPrev = el => {
    const prevInputs = $(el).parent().prevUntil('.form-cellitem').children('.form_field');
    let prevInput = $(el).parent().prevUntil('.form-cellitem').children('.form_field')[1];
    if (this.state.activeForm === 'BC0601' && el.id === 'BA0400' && prevInputs[1].id === 'BD0300') {
      // CLO-6607 .children() does not get the correct order for this field
      prevInput = prevInputs[1];
    }
    let i = 1;
    if (prevInput !== undefined) {
      while (prevInput.classList.contains('hide')) {
        // Handle cases where the next element is hidden with the 'hide' class
        prevInput = $(el).parent().prevUntil('.form-cellitem').children('.form_field')[i];
        i++;
      }
      prevInput.focus();
    }
  };

  // Autocomplete (similar to crosslink "tracking" feature)
  initAutocomplete = fldData => {
    const choices = fldData.Data;
    const suggestions = choices.map(data => {
      return data.ListItem[0].COL;
    });
    const _ = this;
    const jqueryID = this.formatIDForJquery(fldData.InitialField.value);
    $('#' + jqueryID)
      .autocomplete({
        source: suggestions,
        change: (event, ui) => {
          // only run if value has changed (field value != cache value)
          if (event.target.value !== _.valueMap[event.target.id].value) {
            const selectedIndex = suggestions.indexOf(event.target.value);
            _.getChoicelistRow(choices, selectedIndex, event.target.id);
          }
        },
      })
      .autocomplete('search'); // retrigger refresh to update ui selection
  };

  getChoicelistRow = (choiceListData, selectedIndex, field) => {
    const selectedRow = choiceListData[selectedIndex];
    if (typeof selectedRow !== 'undefined') {
      this.handleChoiceListItem(selectedRow.ListItem, field);
    }
  };

  hideGroups = argString => {
    const args = argString.split(':');
    for (let i = 0; i < args.length; i++) {
      this.setGroupVisibility(args[i], false);
    }
  };

  // Guarantee all groups have some initial value set
  //  DefaultGroupState is a map of the form [groupID]->{checked: [true or false], args: []groupID }
  initializeGroups = defaultGroupState => {
    // iterate through all group trees to search for some checked value
    //  if no default exists in the group, default to the first member
    const excluded = [];
    for (const key in defaultGroupState) {
      if (excluded.includes(key)) continue; // we already checked this key, either directly or in an arg chain
      const value = defaultGroupState[key];
      if (!value.args.includes(':')) {
        // empty fld group case (i.e UST1grpC)
        const args = value.args.split(':');
        // is this value checked?
        if (value.checked) {
          // yes, so the entire group has a default - exclude all of them
          excluded.push(key);
          args.forEach(a => excluded.push(a));
          continue;
        } else {
          // no, are any of our args checked?
          let argchecked = false;
          for (const argIdx in args) {
            const jqueryID = this.formatIDForJquery(args[argIdx]);
            const argField = $('#' + jqueryID);
            if (argField.length) {
              if (argField[0].checked) {
                argchecked = true;
                break;
              }
            }
          }
          if (argchecked) {
            // yes, so the entire group has a default - exclude all of them
            excluded.push(key);
            args.forEach(a => excluded.push(a));
          } else {
            // no, so this group has no default - choose the default and exclude our args
            const field = $('#' + key)[0];
            field.click();
            args.forEach(a => excluded.push(a));
          }
        }
      } else {
        const field = $('#' + key)[0];
        field.click(); // default
      }
    }
  };

  // Toggles group when tax form is set
  toggleGroup = (e, toggleGroupNumber, event) => {
    if (event.target.value !== 'x' && event.target.value !== 'X' && event.target.value !== '') {
      // Reset Value to blank if not an x.
      const grp = document.getElementById(event.target.id);
      grp.value = '';
      this.showErrorSnackbar('Invalid Keypress. Enter X or Blank Only.');
      return;
    }
    if (e) {
      const args = e.getAttribute('args').split(':');
      // uncheck all groups in args
      for (let i = 0; i < args.length; i++) {
        this.setGroupVisibility(args[i], false);
      }
      // toggle this group
      const members = $('.form-group-' + toggleGroupNumber);
      if (event.target.value === 'X') {
        members.addClass('hide');
        // if we unchecked everything, set default to the next exclusive group
        this.setGroupVisibility(args[0], true);
      } else {
        members.removeClass('hide');
      }
    }
  };

  // Sets visibility of group items
  setGroupVisibility(groupID, isVisible) {
    const grpNum = groupID.substring(3, 4); // use grp number (grp<num><occurence>)
    const members = $('.form-group-' + grpNum);
    if (isVisible) {
      members.removeClass('hide');
      members.closest('.form-textitem').removeClass('hide');
    } else {
      members.addClass('hide');
      members.closest('.form-textitem').addClass('hide');
    }
  }

  handleGroupFields = (field, value) => {
    if (value === 'X') {
      this.setGroupVisibility(field, true);
    } else {
      this.setGroupVisibility(field, false);
    }
  };

  // accepts array of {field:string, value:string}
  UpdateForm = data => {
    // document.querySelectorAll(".form_field").forEach((function(obj, i){obj.style.setProperty("--background","green")}))
    if (!data.fields) {
      // Nothing to update.
      return;
    }
    // eslint-disable-next-line no-unused-vars
    let popCount = 0;
    // eslint-disable-next-line no-unused-vars
    let skipCount = 0;
    for (const fld in data.fields) {
      const field = fld;
      const value = data.fields[fld].value;
      let nxtFld = data.fields[fld].nextFld;
      if (typeof nxtFld === 'undefined') {
        nxtFld = '';
      }

      this.updateFieldValueCache(field, value, nxtFld);
      this.InspectFormData(field, value);
      const element = document.getElementById(field);

      if (element) {
        // eslint-disable-next-line no-unused-vars
        popCount++;
        element.value = value;
        element.style.setProperty('--background', this.colorMap[data.fields[fld].color]);
        const containsButtonIconClass = element.classList.contains('form-button-icon');
        if (containsButtonIconClass) {
          const svgid = element.getAttribute('svgid');
          element.querySelector('svg').setAttribute('width', '100%');
          const svgText = element.querySelector('#' + svgid + ' text');
          if (svgText) {
            svgText.setAttribute('font-size', '11');
          }
          element.value === 'X'
            ? element.classList.add('form-button-icon-active')
            : element.classList.remove('form-button-icon-active');
          const containsActiveClass = element.classList.contains('form-button-icon-active');
          const svgIDg = document.querySelector('#' + svgid + ' g');
          // CLO-3071 -> special exclusion for collapse icon buttons to be visible
          if (svgIDg && svgid !== 'ivmcollap0' && svgid !== 'ivmcollap1') {
            if (containsActiveClass) {
              svgIDg.setAttribute('fill', '#d7ecf7'); // Set to selected color
            } else {
              svgIDg.setAttribute('fill', '#FEFEFE'); // Set back to defualt Color
            }
          }
        }
        // handle grp items on form update - should not need to call initializeGroups() since CS should return all necessary values on form updates
        if (field.includes('grp')) {
          this.handleGroupFields(field, value);
        }
        // Keep track of the max table row for subFrm type forms - used for automatic form_load for autoextend forms
        if (tableRegExp.test(field)) {
          const curTableRow = translateSpecialOccuranceRow(field.substring(2, 4));
          if (curTableRow > this.maxTableRow) {
            this.maxTableRow = curTableRow;
          }
        }
        // handle grp items NOT on the form
      } else if (field.includes('grp')) {
        this.handleGroupFields(field, value);
      } else {
        // eslint-disable-next-line no-unused-vars
        skipCount++;
      }
    }
  };

  PopulateForm = async data => {
    const flds = data.fields;
    // eslint-disable-next-line no-unused-vars
    let popCount = 0;
    // eslint-disable-next-line no-unused-vars
    let skipCount = 0;
    const defaultGroupState = {};
    for (const fld in flds) {
      const elementID = fld;
      const aVAL = flds[fld].value;
      let nxtFld = flds[fld].nextFld;
      const restricted = flds[fld]?.restricted;
      if (typeof nxtFld === 'undefined') {
        nxtFld = '';
      }
      this.InspectFormData(elementID, aVAL);
      // NOTE: elementID MUST be string-quoted to succesfully select IDs with dollar signs
      const element = $(`[id="${elementID}"]`)[0];
      if (element) {
        element.style.setProperty('--background', this.colorMap[flds[fld].color]);
        this.updateFieldValueCache(elementID, aVAL, nxtFld);
        if (restricted) {
          element.classList.add('restricted');
          element.setAttribute('readonly', '');
          element.setAttribute('restricted', true);
        } else {
          element.setAttribute('restricted', false);
        }
        // eslint-disable-next-line no-unused-vars
        popCount++;
        if (element.classList.contains('form-checkbox-input') || element?.type === 'radio') {
          element.value = aVAL === 'X' ? 'X' : '';
          // Does this checkbox control a group?
          if (element.id.includes('grp')) {
            // If it's checked, make group visible by default
            if (element.value === 'X') {
              // this.hideGroups(element.getAttribute("args"))
              this.setGroupVisibility(element.id, true);
              defaultGroupState[element.id] = {
                value: 'X',
                args: element.getAttribute('args'),
              };
            } else {
              defaultGroupState[element.id] = {
                value: '',
                args: element.getAttribute('args') == null ? '' : element.getAttribute('args'),
              };
            }
          }
        }
        element.value = aVAL;
        // Keep track of the max table row for subFrm type forms - used for automatic form_load for autoextend forms
        if (tableRegExp.test(elementID)) {
          const curTableRow = translateSpecialOccuranceRow(elementID.substring(2, 4));
          if (curTableRow > this.maxTableRow) {
            this.maxTableRow = curTableRow;
          }
        }
        // handle group items NOT on form
      } else if (elementID.includes('grp')) {
        this.handleGroupFields(elementID, aVAL);
      } else {
        // eslint-disable-next-line no-unused-vars
        skipCount++;
      }
    }
    this.initializeGroups(defaultGroupState);
  };

  /**
   * If user clicks "Add New Documents" Btn, the AddDocumentModal opens.
   */
  toggleRequestDocsModal = () => {
    this.setState({ isRequestDocumentModalOpen: !this.state.isRequestDocumentModalOpen });
  };

  InspectFormData = (field, value) => {
    // store fname and lname for client data
    switch (field) {
      case 'FLST00':
        if ((value || value === '') && value !== this.clientData.filing_status) {
          this.clientData.filing_status = value;
          store.dispatch(
            returnProfileActions.setClientData({
              filing_status: this.clientData.filing_status,
            }),
          );
        }
        break;
      case 'BKAD00': // business name 1120/1120S/990
      case 'NMCD00': // business name 1041
      case 'PNMA00' && this.payload?.is_business: // business name 1065
        if (value !== this.clientData.clientName) {
          store.dispatch(
            returnProfileActions.setClientData({
              name: value,
            }),
          );
        }
        break;
      case 'PNMA00':
        if (value !== this.clientData.last_name) {
          this.clientData.first_name = value;
          if (this.payload.is_business) {
            store.dispatch(
              returnProfileActions.setClientData({
                name: value,
              }),
            );
          } else {
            store.dispatch(
              returnProfileActions.setClientData({
                first_name: value,
                last_name: this.clientData.last_name,
              }),
            );
          }
        }
        break;
      case 'PNMC00':
        if (value !== this.clientData.last_name) {
          this.clientData.last_name = value;
          store.dispatch(
            returnProfileActions.setClientData({
              first_name: this.clientData.first_name,
              last_name: value,
            }),
          );
        }
        break;
      case 'CLTR00':
        if (value !== this.state.print.cltr) {
          const printState = update(this.state.print, {
            cltr: { $set: value },
          });
          this.setState({
            print: printState,
          });
        }
        break;
    }
  };

  updateRestrictedFields = data => {
    const fields = data.fields;

    updateReadOnlyFieldStatus(fields);
    this.setState({ restrictedFieldData: data }, () => {
      this.removeChoiceMenuButton();
    });
  };

  removeChoiceMenuButton = () => {
    const element = this.currentEl;
    if (element?.classList?.contains('restricted')) {
      const fieldButtonMenuChoices = this.state.menuButtonsChoices.filter(item => {
        return item?.name !== 'Choices';
      });
      this.setState({
        menuButtonsChoices: fieldButtonMenuChoices,
      });
    }
  };

  openChoiceList = (form, field) => {
    this.props.resetChoiceList();
    this.calcAPI.ReturnChoiceList(form, field);
    this.props.toggleChoiceList(true);
  };

  /**
   * Checks to see if the value from the targeted field is missing
   * within the relevant choice list column.
   *
   * @function
`  * @param {Object} field the current event.target html element
   * @return {boolean} true / false.
   */
  checkChoiceListForValue = field => {
    const { choiceListData, choiceListFields } = this.props;
    // slices field depending on type to retrieve column name
    // which is then used to find the correct choicelist column
    const colName = field.getAttribute('stmcode') ? field.id.slice(0, 2) : field.id.slice(0, 4);
    const colIndex = choiceListFields.indexOf(colName);

    if (colIndex === -1) {
      return false;
    }

    // compares field value to each choicelist item under the relevant column
    // returns true if none of the items match the field value
    return choiceListData.every(
      item => item.ListItem[colIndex].COL.toLowerCase() !== field.value.toLowerCase(),
    );
  };

  openAddFormList = (tab, index) => {
    if (!this.hasFLST()) {
      this.handleRequireFilingStatusDlg();
    } else {
      this.setState(
        {
          activeAddFormTab: index,
        },
        () => {
          if (tab.label === 'Depreciation') {
            this.props.setAddFormPackage('BA');
            this.props.setFirstAddForm(true);
            this.calcAPI.ReturnFormList('BA', this.props.isReadOnly);
          } else {
            // Setting the package that is being used for getting the forms list
            this.props.setAddFormPackage('US');
            // Need flag to know if the function this.calcAPI.ReturnFormList is being called for the first time, which means we need to open the add form modal
            this.props.setFirstAddForm(true);
            // Getting forms list from calc server
            this.calcAPI.ReturnFormList('US', this.props.isReadOnly);
          }
        },
      );
    }
  };

  PopulateFormsAndActivitiesList = async formsAndActivities => {
    if (this.props.addFormFirst === true) {
      this.props.setFirstAddForm(false);
      this.props.toggleAddFormList();
    }
  };

  // TODO: Need to optimize, seems to be lag when re-rendering on update
  updateAddFormList = pkg => {
    // Setting the package that is being used for getting the forms list
    this.props.setAddFormPackage(pkg);
    if (pkg === 'ST') {
      const emptyFormList = [];
      this.props.setAddFormList(emptyFormList);
    } else {
      this.calcAPI.ReturnFormList(pkg, this.props.isReadOnly);
    }
  };

  stopSpin = () => {
    if (this.state.isLoading) {
      this.hideFormButton();
      this.setState({
        isLoading: false,
      });
    }
  };

  closeMessageDialog = () => {
    this.setState({
      messageDialogOpen: false,
    });
  };

  closeAddAttachmentModal = () => {
    this.setState({ addAttachmentModalOpen: false });
  };

  openAddAttachmentModal = () => {
    this.setState({ addAttachmentModalOpen: true });
  };

  closeErrorRejectModal = () => {
    this.props.closeErrorRejectModal();
    this.setVerifyType('');
  };

  toggleDeleteDialog = (form, desc) => {
    let removeFormDlg = desc + ' has been selected for removal. This cannot be undone.';
    let removeFormIrremovable = false;
    if (IRREMOVABLE_FORMS.includes(form?.var)) {
      removeFormDlg = 'Cannot Delete 1040 Schedule. Entries on this form must be manually removed.';
      removeFormIrremovable = true;
    }

    this.setState({
      removeForm: form,
      removeFormID: form === null ? null : form.var,
      removeFormDesc: desc,
      removeFormDlg,
      removeFormIrremovable,
      confirmDeleteDialogOpen: !this.state.confirmDeleteDialogOpen,
    });
  };

  toggleAssetDeleteDialog = () => {
    this.setState({
      confirmAssetDelete: !this.state.confirmAssetDelete,
    });
  };

  toggleDeletePkgDialog = (pkg, desc) => {
    this.setState({
      removePkgId: pkg,
      removePkgDesc: desc,
      confirmPkgDelete: !this.state.confirmPkgDelete,
    });
  };

  onClickDeleteDocument = docID => {
    this.calcAPI.ReturnRemoveDocument(docID);
  };

  openQueueReturnModal = () => {
    this.setState({
      queueReturnModalOpen: true,
    });
  };

  closeQueueReturnModal = () => {
    this.setState({
      queueReturnModalOpen: false,
    });
  };

  closeQueueReturnModalDemo = () => {
    this.setState({
      queueReturnModalOpen: false,
      openDemoMessage: true,
    });
  };

  onClickDetachDocument = (docID, attachVar) => {
    this.calcAPI.ReturnDetachDocument(docID, attachVar);
  };

  // Include completed remote signature documents as part of the document archive tab
  getLatestArchivedDocuments = async () => {
    XlinkAPI.getReturnProfileByReturnID(this.props.returnID)
      .then(res => {
        let arr = [];
        if (res?.data?.preparer_id) {
          this.setState({ preparerID: res.data.preparer_id });
        }

        if (res?.data?.attachments) {
          arr = res.data.attachments;
          this.props.setUpdatedAttachmentList(arr);
        } else {
          this.props.setUpdatedAttachmentList(arr);
        }
      })
      .catch(() => {
        this.props.showErrorDialog('Failed to update attachments list');
      });
  };

  // TODO --> We should send the button ID around instead and just pass back the User Response Back
  addOrAttachDocument = async (
    e,
    btnid,
    efcode,
    description,
    blob,
    imageDescription,
    imageName,
    imageType,
    attachmentType,
    docid,
  ) => {
    e.persist();
    try {
      if (blob !== undefined || blob != null) {
        // Adding a new document.
        this.calcAPI.ReturnPDFAttach(
          this.props.returnID,
          btnid,
          efcode,
          description,
          this.state.activeForm,
          blob,
          imageName,
          imageDescription,
          imageType,
          attachmentType,
          0,
          this.state.interviewMode,
        );
      } else if (blob == null || blob === undefined) {
        // Attaching an existing document.
        this.calcAPI.ReturnPDFAttach(
          this.props.returnID,
          btnid,
          efcode,
          description,
          this.state.activeForm,
          '',
          imageName,
          imageDescription,
          imageType,
          attachmentType,
          docid,
          this.state.interviewMode,
        );
      }
    } catch (err) {
      this.props.showErrorSnackbar(err);
      ErrorHelpers.handleError('Error: ', err);
    }
  };

  runTVASend = async (blob, imageName, imageDescription, imageType) => {
    try {
      if (blob) {
        //this.startSpin(SPIN_GENERIC);
        this.calcAPI.TVASend(blob, imageName, imageDescription, imageType);
      } else {
        // Else case should never be hit, but if it does throw an error
        throw new Error('Image Blob not attached, Unable to run Vision Assist');
      }
    } catch (err) {
      this.props.showErrorSnackbar(err);
      ErrorHelpers.handleError('Vision Assist: ', err);
    }
  };

  closeWithDelay = discard => {
    if (discard) this.startSpin(SPIN_DISCARD_MSG);
    else this.startSpin(SPIN_SAVE_MSG);
  };

  closeLockedReturn = e => {
    this.startSpin(SPIN_LOCKED_CLOSE);
    this.onCloseActiveReturn();
  };

  closeAndSaveReturn = async () => {
    if (!this.hasFLST()) {
      this.handleRequireFilingStatusDlg();
    } else {
      try {
        // Save Return
        this.startSpin(SPIN_SAVE_MSG);
        this.calcAPI.ReturnSaveAndClose(this.getReturnMetadata(), this.props.isWizard);
      } catch (error) {
        ErrorHelpers.handleError('Error saving return', error);
      }
    }
  };

  saveReturn = async () => {
    if (!this.hasFLST()) {
      this.handleRequireFilingStatusDlg();
    } else {
      try {
        // Save Return
        this.startSpin(SPIN_SAVE_MSG);
        this.calcAPI.ReturnSave(
          this.getReturnMetadata(),
          this.props.isReadOnly,
          this.props.isWizard,
        );
      } catch (error) {
        this.showErrorSnackbar('Error saving return');
        ErrorHelpers.handleError('Error saving return', error);
      }
    }
  };

  closeReturn = async () => {
    try {
      // Close Return
      this.startSpin(SPIN_LOCKED_CLOSE);
      this.calcAPI.ReturnClose(this.props.isReadOnly);
    } catch (error) {
      ErrorHelpers.handleError('Error closing return', error);
    }
  };

  deleteReturn = async () => {
    try {
      // Delete Return
      this.startSpin(SPIN_LOCKED_DELETE);
      this.calcAPI.ReturnDelete();
    } catch (error) {
      ErrorHelpers.handleError('Error deleting return', error);
    }
  };

  getReturnMetadata = () => {
    const metaData = [];

    for (let i = 1; i < this.props.formList.length; i++) {
      if (this.props.formList[i].desc && this.props.formList[i].desc.trim() === 'FEDERAL') {
        let fedRefundOrBalDue;
        if (this.props.formList[i].rfnd && this.props.formList[i].rfnd !== '') {
          fedRefundOrBalDue = parseInt(
            this.props.formList[i].rfnd.substring(1).replace(/,/g, ''),
            0,
          );
        } else if (this.props.formList[i].bdue && this.props.formList[i].bdue !== '') {
          fedRefundOrBalDue = parseInt(
            this.props.formList[i].bdue.replace('$', '-').replace(/,/g, ''),
            0,
          );
        }
        metaData.push({
          stateName: 'FEDERAL',
          refundOrBalDue: fedRefundOrBalDue,
          season: this.payload.season,
        });
      } else if (this.props.formList[i].var.length <= 2) {
        // exclude interview mode forms
        const stateName = this.props.formList[i].desc;
        let stateRefundOrBalDue;
        if (this.props.formList[i].rfnd && this.props.formList[i].rfnd !== '') {
          stateRefundOrBalDue = parseInt(
            this.props.formList[i].rfnd.substring(1).replace(/,/g, ''),
            0,
          );
        } else if (this.props.formList[i].bdue && this.props.formList[i].bdue !== '') {
          stateRefundOrBalDue = parseInt(
            this.props.formList[i].bdue.replace('$', '-').replace(/,/g, ''),
            0,
          );
        }
        metaData.push({
          stateName: stateName.trim(),
          refundOrBalDue: stateRefundOrBalDue,
          season: this.payload.season,
        });
      }
    }
    return metaData;
  };

  showEarlyExitAlert = () => {
    this.setState({
      alert: {
        show: true,
      },
    });
  };

  verifyReturn = async activeTab => {
    try {
      this.startSpin('Checking for errors...');
      await this.calcAPI.ReturnVerify();
      this.setState({
        activeErrorRejectTab: activeTab,
      });
    } catch (error) {
      this.showErrorSnackbar('Error Verifying Return: ', error);
    }
  };

  interviewMode = () => {
    this.calcAPI.ReturnFormLoad('Z00501', '', '', '');

    this.setState({
      interviewMode: true,
    });
  };

  queueReturn = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'return_xmitlist',
        returnID: parseInt(this.props.returnID),
      }),
    );
  };

  getDocumentIDByVar = docvar => {
    let docID = '';
    this.props.attachments.forEach(attach => {
      if (Object.prototype.hasOwnProperty.call(attach, 'document_status_var')) {
        if (attach.document_status_var === docvar) {
          docID = attach.document_id;
        }
      }
    });
    return docID;
  };

  performCalcProcess = (aForm, xIsAssetForm, targetId, targetValue, calcTypeEnum, vfyTab) => {
    this.calcAPI.ReturnCalc(aForm, xIsAssetForm, targetId, targetValue, calcTypeEnum);
    if (this.state.verifyDirty) {
      this.setState({ verifyDirty: false });
      setTimeout(() => {
        this.verifyReturn(vfyTab);
      }, 1000);
    }
  };

  handleAddForm = (form, file) => {
    if (!this.hasFLST()) {
      this.handleRequireFilingStatusDlg();
    } else {
      if (typeof form.MSGBOX === 'undefined') {
        // if we are in the subMenu do not re-set the TORS of an existing form to 00.
        // set Form.TORS to null to prevent override.
        if (this.props.setSubDialogListOpened && form.TORS === '00') {
          form.TORS = null;
        }
        this.calcAPI.ReturnAddForm(form.formOccur, form.TORS);
        this.props.closeFormSubDialog();
        this.props.toggleAddFormList();
        this.getTaxForm(form.formOccur, '', '', '');
        return;
      }

      this.props.openMsgDialog(form.MSGBOX);
    }
  };

  handleAddFormIndex = form => {
    if (!this.hasFLST()) {
      this.handleRequireFilingStatusDlg();
    } else {
      if (typeof form.MSGBOX === 'undefined') {
        this.calcAPI.ReturnAddForm(form.formOccur, form.TORS);
        this.props.toggleAddFormList();
        this.goToField(form.formOccur, form.formId.slice(4));
        return;
      }
      this.props.openMsgDialog(form.MSGBOX);
    }
  };

  handleChoiceListItem = (choiceItem, field) => {
    let tempString = '';

    choiceItem.forEach(data => {
      tempString = tempString + data.COL + '\t';
    });
    // last row, remove last two characters "\t"
    tempString = tempString.slice(0, -1);
    this.calcAPI.ReturnChoiceListCalc(this.state.activeForm, field, tempString);
    this.props.toggleChoiceList(false);
  };

  handleDeleteForm = form => {
    this.setState({
      confirmDeleteDialogOpen: false,
    });
    if (form.actvForm !== '') {
      this.toggleAssetDeleteDialog();
    } else {
      this.getTaxForm(FORM_NAMES.CDS, '', '', '');
      this.calcAPI.ReturnRemoveForm(form.var);
    }
  };

  handleDeleteActivityForm = form => {
    this.setState({
      confirmAssetDelete: false,
    });
    this.getTaxForm(FORM_NAMES.CDS, '', '', '');
    this.calcAPI.ReturnRemoveForm(form.var);
  };

  handleDeletePkg = pkg => {
    this.setState({
      confirmPkgDelete: false,
    });
    this.getTaxForm(FORM_NAMES.CDS, '', '', '');
    this.calcAPI.ReturnRemovePackage(pkg);
  };

  handleSidebarFormClick = (event, name, stmcode, file) => {
    // Checks if called from expand/collapse listitemicon click, prevents attempting getTaxForm for invalid form names
    if (event.target.id.includes('Icon') || name.length < 6) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const flstNavForms = ['Z03001', 'UST101']; // wiz, 104A Filing Stat (FLST input forms)
      if (!this.hasFLST() && !flstNavForms.includes(name)) {
        this.handleRequireFilingStatusDlg();
      } else {
        switch (name) {
          case 'retn04':
          case 'retn09':
            this.verifyReturn(VERIFY_MODAL_TABS.REJECTS);
            break;
          case 'retn22':
            this.props.toggleK1ManagerModal(true);
            break;
          case 'retn25':
            this.props.toggleEventLogModal(true);
            break;
          case 'retn28':
            this.props.toggleTaxpassMessageModal(true);
            break;
          case 'form12':
            this.getTaxForm(name, stmcode, file, '');
            break;
          case 'prin12':
          case 'prin17':
          case 'prin18':
            this.getTaxForm(name, stmcode, file, '');
            break;
          default:
            this.getTaxForm(name, stmcode, file, '');
        }
      }
    }
  };

  handleResponseFromDialog = (domEvent, resp, labelName) => {
    domEvent.preventDefault();
    this.calcAPI.ReturnMessageResponse(resp, this.state.activeForm, labelName, this.state.tvaID);
    if (domEvent.target.innerText === 'My Wallet') {
      this.gotoWallet();
    }
    this.props.closeMsgDialog();
  };

  /* Verify Handling */

  // Set the context in which verify is called (print final, queuing, etc)
  setVerifyType = type => {
    this.setState({
      verifyType: type,
    });
  };

  // similar to goToField(), includes extra logic for handling the verify behavior
  handleVerify = async (form, stmCode, file, field, desc, tab) => {
    this.props.closeErrorRejectModal();

    // Special case handling for CDS
    if (form === '000000') {
      form = FORM_NAMES.CDS;
    }

    // Assign blank stmCode if none is given
    if (!stmCode) {
      stmCode = '';
    }
    this.setState({
      verifyWksRan: false,
      verifyDirty: true,
      verifyField: field,
      activeErrorRejectTab: tab,
      verifyForm: form + stmCode,
      verifyStmCode: stmCode,
    });

    // Verify selected is on currently active form and element exists on the form
    if (this.state.activeForm === form + stmCode && document.getElementById(field)) {
      this.verifyFocus(field, tab);
    } else {
      // Verify selected is on different form, need to load form first
      const formName = form;
      let wks = '';

      // Check for wks or stm based on field name if stmCode is present
      // If field is type asset, reset both stmCode and field (XMF ERROR)
      if (stmCode !== '' && !assetFormRegExp.test(field)) {
        if (tableRegExp.test(field)) {
          wks = 'stm';
        }
        // default to worksheet
        wks = 'wks';
      } else {
        // Do not send field and stmCode in form_load
        field = stmCode = '';
      }

      // If form to load is an asset form, use viewAsset() instead of getTaxForm()
      if (assetFormRegExp.test(formName)) {
        try {
          await this.viewAsset(formName);
          this.setState({ activeForm: formName });
        } catch (error) {
          ErrorHelpers.handleError('Error loading Form', error);
          // temp logs for incorrect formName during form_load issue
          console.log('formName: ', formName, 'error: ', error);
        }
      } else {
        // Load base form first, setTaxForm will handle subforms if necessary
        try {
          await this.getTaxForm(formName + stmCode, wks, file, field);
          this.setState({ activeForm: formName + stmCode });
        } catch (error) {
          ErrorHelpers.handleError('Error loading Form', error);
          // temp logs for incorrect formName during form_load issue
          console.log('formName: ', formName, 'stmCode: ', stmCode, 'wks: ', wks, 'error: ', error);
        }
      }
    }
  };

  // Focus on field called by verify
  verifyFocus = (field, tab) => {
    setTimeout(() => {
      const _ = this;
      const jqueryID = this.formatIDForJquery(field);
      const initBorder = $('#' + jqueryID).css('border');

      // TODO: DO this without an interval. In most cases it will find it first or second try. Should look into using some sort of callback or observable to only focus when the field is present
      let fieldExistCount = 0;

      const fieldExists = setInterval(() => {
        if (document.getElementById(field) || fieldExistCount >= 10) {
          // RMST00 and RMST01 point to the same dataKey in Calc. The form will load one and hide other on desktop as RMST can exist in more than one form, but not in CLO
          // Verify will always send RMST00, do a check if RMST01 exists and is not hidden ( CLO Workaround )
          if (
            field === 'RMST00' &&
            document.getElementById(field).classList.contains('hide') &&
            document.getElementById('RMST01') &&
            !document.getElementById('RMST01').classList.contains('hide')
          ) {
            // Forward to RMST01
            field = 'RMST01';
          }

          const verifyField = document.getElementById(field);
          verifyField.focus();
          clearInterval(fieldExists);
        }
        fieldExistCount++;
      }, 100);

      $('#' + jqueryID)
        .css('border', '2px solid red')
        .change(e => {
          e.preventDefault();
          _.performCalcProcess(
            _.state.activeForm,
            assetFormRegExp.test(_.state.activeForm) ? 'X' : '',
            e.target.id,
            e.target.value,
            CALC_TYPE.FIELD_CALC,
            tab,
          );
          $('#' + jqueryID).css('border', initBorder);
          // We need to unbind all form events since we only want the verify logic to run after being taken to a field through the verify window
          _.unbindFormEvents();
          _.bindFormEvents();
        });
    }, FieldFocusDelay);
  };

  loadVerifyForm = (currentFormName, currentFileName) => {
    if (currentFormName.length > 6) {
      // Form being loaded is a subform- truncate end of name so we just get the base + occurence form name.
      // This handles the case where the form name is different than the field file name
      currentFormName = currentFormName.slice(0, 6);
    }

    let formToLoad = currentFormName + this.state.verifyStmCode; // Form to load- takes into account stmcode in verify info

    // TODO: Verify flow needs to be rewritten; possibly have calcserver always give us frmFile
    // - Current logic is written to handle cases where the field and form combination in the given verify does not exist
    // - Current focus logic will cause some confusing field focus behavior when staying in "verify mode" and loading different forms
    // - If in "verify mode" and form load occurs before verify mode is cleared, if the form happens to have the field in verify, it will focus
    // - If in "verify mode" and field is on a subform, if the parent form does not have the field, this logic will always take them to the worksheet,
    //   even if they are explicitly trying to load the parent form
    // - Attempt to load 3 times
    if (this.verifyFormLoadCount < 3) {
      if (document.getElementById(this.state.verifyField)) {
        // Field is on form, go to field and reset load count
        this.verifyFormLoadCount = 0;
        this.setState({
          verifyWksRan: false,
        });
        this.verifyFocus(this.state.verifyField, this.state.activeErrorRejectTab);
      } else if (this.state.verifyWksRan === false && this.state.verifyForm === formToLoad) {
        // Attempt to load the worksheet if form being loaded is the same as the verify's form
        if (this.state.verifyStmCode === '') {
          formToLoad = formToLoad + this.state.verifyField.slice(0, 1);
        }
        this.setState({ verifyWksRan: true });
        this.verifyFormLoadCount++;
        this.getTaxForm(formToLoad, 'wks', currentFileName, this.state.verifyField);
      } else if (this.state.verifyWksRan) {
        // Field was not on wks subform, attempt to load a stm subform
        this.setState({ verifyWksRan: false }); // Prevent infinite loop
        if (this.state.verifyStmCode === '') {
          formToLoad = formToLoad + this.state.verifyField.slice(0, 1);
        }
        this.verifyFormLoadCount++;
        this.getTaxForm(formToLoad, 'stm', this.state.verifyField);
      }
    } else {
      this.verifyFormLoadCount = 0;
    }
  };

  /**
   * Handles setting the loading spinner state and sending the calcAPI cmd 'return_print'
   *
   * @param {string} type printing type - currently passing in by default '03'
   * @param {object} prefs override printing preferences object
   * @param {string} formOccur form that it is occuring - 'Final Tax Return' -> '0000'
   * @param {object} sigs the signatures being captured
   * @param {string} pAction by default we are passing in '2'
   */
  print = (type, prefs, formOccur, sigs, pAction) => {
    // If one of the sigs are RMS, then we do not need to display the spinner UI
    if (!this.props.isRMSSigningMethod) {
      this.startSpin('Loading PDF');
    }
    this.calcAPI.ReturnPrint(
      type,
      prefs,
      formOccur,
      sigs,
      pAction,
      '',
      this.state.print.cltr,
      this.props.isReadOnly,
    );
  };

  handlePrint = (type, prefs, val, menuText = '') => {
    if (!this.props.usingDemoAccount) {
      let formOccur = type === '10' || type === '11' ? val : this.state.activeForm;
      const value = type === '10' || type === '11' ? val : this.state.activeForm;
      if (type === '11' && val !== 'Federal' && val !== 'Engagement') formOccur = 'State';

      const printState = update(this.state.print, {
        pType: { $set: type },
        formOccur: { $set: formOccur },
        overridePrefs: { $set: prefs },
        val: { $set: value },
        menuText: { $set: menuText },
      });
      this.setState({
        print: printState,
      });
      this.handleGetReqSigs(formOccur, type);
    } else {
      this.handleNoPrintDemo();
    }
  };

  /**
   * Sends a 'return_print_query' command to calc server to get the requested signatures.
   *
   * @function
   * @param {string} formOccur The special form name sent to calc server.
   * @param {string} type The type of document needing signed.
   */
  handleGetReqSigs = (formOccur, type) => {
    const prefs = this.state.print.prefs;
    let LPA5 = '';
    let LPB5 = '';
    if (prefs.LPA5.configValue !== undefined) {
      LPA5 = prefs.LPA5.configValue === '1' ? 'X' : '';
    }
    if (prefs.LPB5.configValue !== undefined) {
      LPB5 = prefs.LPB5.configValue === '1' ? 'X' : '';
    }
    this.calcAPI.ReturnPrintSignatures(formOccur, type, LPA5, LPB5, this.props.isReadOnly);
    this.setState({
      returnPrintQueryLoading: true,
    });
    this.startSpin('Loading');
  };

  /**
   * Sets the preferred signature pad type from login preferences.
   *
   * @function
   */
  handlePreferredSignaturePad = () => {
    let signaturePadType = SIGNATURE_PAD_TYPES.INITIAL_VALUE;

    if (this.props.onScreen) {
      signaturePadType = SIGNATURE_PAD_TYPES.ON_SCREEN;
    } else if (this.props.scriptel) {
      signaturePadType = SIGNATURE_PAD_TYPES.SCRIPTEL;
    } else if (this.props.topaz) {
      signaturePadType = SIGNATURE_PAD_TYPES.TOPAZ;
    } else if (!this.props.onScreen && !this.props.scriptel && !this.props.topaz) {
      signaturePadType = SIGNATURE_PAD_TYPES.ON_SCREEN;
    }
    this.props.setPreferredSignaturePad(signaturePadType);
  };

  handlePrintActionsPrintSelected = () => {
    const type = this.state.print.pType;
    const prefs =
      type === PRINT_TYPES.RETURN_COMPONENTS
        ? this.state.print.overridePrefs
        : this.convertPrintPrefs(this.state.print.prefs, type);
    const formOccur = this.state.print.formOccur;
    const pAction = '1';

    if (formOccur !== 'State') {
      this.calcAPI.ReturnPrint(
        type,
        prefs,
        formOccur,
        {},
        pAction,
        '',
        this.state.print.cltr,
        this.props.isReadOnly,
      );
    } else {
      this.calcAPI.ReturnPrintStateClientLetter(
        type,
        prefs,
        formOccur,
        {},
        pAction,
        '',
        this.state.print.cltr,
        this.state.print.val, // User selected state
        this.props.isReadOnly,
      );
    }

    this.startSpin('Loading PDF');
  };

  /**
   * Handles when there is a signee who is going to sign within the office.
   *
   * @function
   * @param {number} [sigDocID=0] The signature document ID for the document needing signed.
   * @param {number} [remoteRequestSignatures=0]  The signees who are needing to sign the document.
   * @param {string} [docType=''] The type of document needing signed.
   */
  handleSigningInOffice = async (sigDocID = 0, remoteRequestSignatures = 0, docType = '') => {
    const reqSigs = this.state.print.reqSigs;
    const type = Object.keys(PRINT_TYPES_MAP)[Object.values(PRINT_TYPES_MAP).indexOf(docType)];
    this.setState({
      pType: type,
    });

    // Signing Method: Capture Signature (In-office) Only
    // We want to assign sigDocID IF there is an existing signature doc in the DB.
    if (!remoteRequestSignatures.length && reqSigs.length !== 0 && docType !== '') {
      this.sigDocID = parseInt(sigDocID);
      this.openSignaturePad(reqSigs, type);
    } // Signing Method: Capture Signature (In-office) and Remote Signature
    else if (remoteRequestSignatures.length) {
      this.sigDocID = parseInt(sigDocID);
      this.openSignaturePad(remoteRequestSignatures, type);
    } // Signing Method: Current Form Only
    else if (!remoteRequestSignatures.length && docType === '') {
      this.openSignaturePad(reqSigs, this.state.print.pType);
    }
  };

  /**
   * Opens the signature pad depending on the method set in login preferences.
   *
   * @function
   * @param {string} reqSigs The signees who are needing to sign the document.
   * @param {string} type The document print type used for calc server.
   */
  openSignaturePad = (reqSigs, type) => {
    const ppSignature = this.state.print.ppSignature;
    const efinSignature = this.state.print.efinSignature;
    // eslint-disable-next-line react/no-string-refs
    const canvas = this.refs.cnv;
    const ctx = canvas.getContext('2d');
    const curSig = reqSigs.charAt(0);
    const newReqSigs = reqSigs.slice(1);
    const printState = update(this.state.print, {
      pType: { $set: type },
      currentSig: { $set: curSig },
      reqSigs: { $set: newReqSigs },
    });
    this.setState({
      print: printState,
    });
    // Before opening the signature capture modal we need to see if the preparer and ero signatures exist on the db
    // Calc server will return the preparer shortcut id for a particular return (saved on ppSignature variable)
    // Check if the current signature is equal to 3 for preparer and 4 for ero signature
    if (curSig === SIGNEE_TYPE.PREPARER && ppSignature.length !== 0) {
      // Check if we have a signature on db
      this.getPreparerSignature(ppSignature, ctx, canvas, SIGNATURE_PAD_TYPES.ON_SCREEN);
    } else if (curSig === SIGNEE_TYPE.ERO && efinSignature.length !== 0) {
      // Check if we have a signature on db
      this.getEROSignature(efinSignature, ctx, canvas, SIGNATURE_PAD_TYPES.ON_SCREEN);
    } else {
      // We need to decide which signature capture modal to open here, can be on screen, Scriptel, or Topaz signature pad
      if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.ON_SCREEN) {
        this.props.toggleSignatureCaptureModal(true);
      }
      if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.SCRIPTEL) {
        this.props.toggleScriptelSignatureCaptureModal(true);
      }
      if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.TOPAZ) {
        this.props.toggleTopazSignatureCaptureModal(true);
      }
      // Adding default preferred signature pad, which will be equal to on-screen when user has not selected preferred method under login settings
      if (
        this.props.preferredSignaturePad !== SIGNATURE_PAD_TYPES.ON_SCREEN &&
        this.props.preferredSignaturePad !== SIGNATURE_PAD_TYPES.SCRIPTEL &&
        this.props.preferredSignaturePad !== SIGNATURE_PAD_TYPES.TOPAZ
      ) {
        this.props.toggleSignatureCaptureModal(true);
      }
    }
  };

  handleEncryptedPDFSelected = () => {
    this.props.toggleSaveSuggestedPassword(true);
  };

  handleEncryptedPDFSelectedSecondStep = () => {
    this.props.setEncryptedPdfRequest(true);
    this.setState({
      showFinalPDF: false, // Flag that will disable pdf preview
    });
    const type = this.state.print.pType;
    const prefs =
      type === PRINT_TYPES.RETURN_COMPONENTS
        ? this.state.print.overridePrefs
        : this.convertPrintPrefs(this.state.print.prefs, type);
    const formOccur = this.state.print.formOccur;
    const pAction = '04';
    const password = this.props.encryptedPassword;
    if (formOccur !== 'State') {
      this.calcAPI.ReturnPrint(
        type,
        prefs,
        formOccur,
        {},
        pAction,
        password,
        this.state.print.cltr,
        this.props.isReadOnly,
      );
    } else {
      this.calcAPI.ReturnPrintStateClientLetter(
        type,
        prefs,
        formOccur,
        {},
        pAction,
        password,
        this.state.print.cltr,
        this.state.print.val, // User selected state
        this.props.isReadOnly,
      );
    }
  };

  /**
   * Gets the requested signatures from calc server and sets the preferred signature pad type.
   *
   * @function
   * @param {string} printFormName The special form name sent to calc server.
   */
  handleRemoteSignSelected = printFormName => {
    this.handleGetReqSigs(printFormName, '03');
    this.props.signatureRequestActiveSet(true);
    this.handlePreferredSignaturePad();
  };

  handleSaveSuggestedPassword = () => {
    // We need to save the current state of the return, once we get back the response from calc server on return_save, we need to update the state to keep latest return info
    this.setState({ encryptedPdfPrint: true });
    this.props.toggleSaveSuggestedPassword(false);
    // Within save return we will update the values for the taxpayer and spouse email
    // Since we are passing encrypedPdfPrint flag as true, this will open the second modal needed to continue with the encrypted pdf process
    this.saveReturn();
  };

  handleSaveSuggestedPasswordDoc = () => {
    const encrpytedPassword =
      this.props.encryptedPassword !== undefined ? this.props.encryptedPassword : '';
    const sendMessage = 'The document was password protected. The password is ' + encrpytedPassword;
    const newMessage = sendMessage.toString();
    // Check if the text password option was selected and text password to taxpayer/spouse
    if (this.props.taxpayerTextChecked && encrpytedPassword !== '') {
      this.handleSendTextLinkMessage(
        'Name',
        this.props.taxpayerCellPhoneNumber,
        this.props.taxpayerCellPhoneCarrier,
        newMessage,
        this.props.taxpayerCellPhoneDomain,
      );
    }
    if (this.props.spouseTextChecked && encrpytedPassword !== '') {
      this.handleSendTextLinkMessage(
        'Name',
        this.props.spouseCellPhoneNumber,
        this.props.spouseCellPhoneCarrier,
        newMessage,
        this.props.spouseCellPhoneDomain,
      );
    }
    // Add logic to pass empty email address when the option is not selected (empty checkbox)
    if (
      this.props.taxpayerChecked ||
      (this.props.taxpayerEPassChecked && encrpytedPassword !== '') ||
      this.props.spouseChecked ||
      (this.props.spouseEPassChecked && encrpytedPassword !== '')
    ) {
      // Declare variable values needed for endpoint
      const tpEmail =
        this.props.taxpayerChecked || this.props.taxpayerEPassChecked
          ? this.props.taxpayerEmailForPdf
          : '';
      const spEmail =
        this.props.spouseChecked || this.props.spouseEPassChecked
          ? this.props.spouseEmailForPdf
          : '';
      const repEmail =
        this.props.representativeChecked || this.props.representativeEPassChecked
          ? this.props.preparerEmailForPdf
          : '';
      const emailSubject = this.props.encryptedPdfTitle;
      const password = encrpytedPassword;
      const emailBody = btoa(this.props.emailBlob);
      const attachmentContent = this.props.attachmentContent;
      const preparerID = this.state.preparerID;
      const tpEmailChecked =
        this.props.taxpayerChecked !== undefined ? this.props.taxpayerChecked : false;
      const spEmailChecked =
        this.props.spouseChecked !== undefined ? this.props.spouseChecked : false;
      const repEmailChecked =
        this.props.representativeChecked !== undefined ? this.props.representativeChecked : false;
      const tpEmailPassChecked =
        this.props.taxpayerEPassChecked !== undefined ? this.props.taxpayerEPassChecked : false;
      const spEmailPassChecked =
        this.props.spouseEPassChecked !== undefined ? this.props.spouseEPassChecked : false;
      const repEmailPassChecked =
        this.props.representativeEPassChecked !== undefined
          ? this.props.representativeEPassChecked
          : false;

      XlinkAPI.emailEncryptedDoc(
        tpEmail,
        spEmail,
        repEmail,
        emailSubject,
        password,
        emailBody,
        attachmentContent,
        preparerID,
        tpEmailChecked,
        spEmailChecked,
        repEmailChecked,
        tpEmailPassChecked,
        spEmailPassChecked,
        repEmailPassChecked,
      )
        .then(() => {
          this.props.toggleEncryptedPdf(false);
          this.props.setEncryptedPDFSuccessDialog(true);
          this.setState({ showFinalPDF: true });
        })
        .catch(error => {
          ErrorHelpers.handleError('Error Sending Encrypted PDF', error);
        });
    }
  };

  /**
   * Sends a 'return_print' command to xlinkcloud API to insert the document needing
   * signed into the DB and adds it to the document archive in the return.
   *
   * @function
   * @param {string} docType The type of document needing signed.
   */
  handleSigningRemotely = (docType = '') => {
    this.setState({
      remoteSignPrint: false,
      showFinalPDF: false,
    });

    this.startSpin('Sending Request...');
    const type = Object.keys(PRINT_TYPES_MAP)[Object.values(PRINT_TYPES_MAP).indexOf(docType)];
    const prefs = this.state.print.prefs;
    const formOccur = this.state.print.formOccur;
    const pAction = PRINT_ACTION_TYPES.REMOTE_SIGN_ACTION;

    this.calcAPI.ReturnPrint(
      type,
      prefs,
      formOccur,
      {},
      pAction,
      '',
      this.state.print.cltr,
      this.props.isReadOnly,
    );
  };

  setRequiredSignaturesNew = (reqSigs, signaturePadType) => {
    const ppSignature = this.state.print.ppSignature;
    const amendedPreparerSignature = this.state.print.ppxSignature;
    const efinSignature = this.state.print.efinSignature;
    // eslint-disable-next-line react/no-string-refs
    const canvas = this.refs.cnv;
    const ctx = canvas.getContext('2d');
    const curSig = reqSigs.charAt(0);
    const newReqSigs = reqSigs.slice(1);
    const printState = update(this.state.print, {
      currentSig: { $set: curSig },
      reqSigs: { $set: newReqSigs },
    });
    this.setState({
      print: printState,
    });
    // Before opening the signature capture modal we need to see if the preparer and ero signatures exist on the db
    // Calc server will return the preparer shortcut id for a particular return (saved on ppSignature variable)
    // Check if the current signature is equal to 3 for preparer and 4 for ero signature
    if (curSig === '3' && ppSignature.length !== 0) {
      // Check if we have a signature on db
      this.getPreparerSignature(ppSignature, ctx, canvas, signaturePadType);
    } else if (curSig === 'A' && amendedPreparerSignature.length !== 0) {
      this.getPreparerSignature(amendedPreparerSignature, ctx, canvas, signaturePadType);
    } else if (curSig === '4' && efinSignature.length !== 0) {
      // Check if we have a signature on db
      this.getEROSignature(efinSignature, ctx, canvas, signaturePadType);
    } else {
      // We need to decide which signature capture modal to open here, can be on screen, Scriptel, or Topaz signature pad
      if (signaturePadType === SIGNATURE_PAD_TYPES.ON_SCREEN) {
        this.props.toggleSignatureCaptureModal(true);
      }
      if (signaturePadType === SIGNATURE_PAD_TYPES.SCRIPTEL) {
        this.props.toggleScriptelSignatureCaptureModal(true);
      }
      if (signaturePadType === SIGNATURE_PAD_TYPES.TOPAZ) {
        this.props.toggleTopazSignatureCaptureModal(true);
      }
    }
  };

  /**
   * Handles closing the capture modals and also determining how we will save the signature data based on
   * the existence of a signature document record in the DB. Regardless of how we save it, a 'return_print' cmd must be sent to cs.
   *
   * @param {string} sigType the signee - tp, sp, prep
   * @param {string} sigValue
   * @param {string} signaturePadType the type of capture screen that was used
   */
  handleSignatureCapture = async (sigType, sigValue, signaturePadType) => {
    if (signaturePadType === SIGNATURE_PAD_TYPES.ON_SCREEN) {
      this.props.toggleSignatureCaptureModal(false);
    }
    if (signaturePadType === SIGNATURE_PAD_TYPES.SCRIPTEL) {
      this.props.toggleScriptelSignatureCaptureModal(false);
    }
    if (signaturePadType === SIGNATURE_PAD_TYPES.TOPAZ) {
      this.props.toggleTopazSignatureCaptureModal(false);
    }
    const signatures = this.state.print.signatures;
    signatures[sigType] = sigValue;
    const printState = update(this.state.print, {
      signatures: { $set: signatures },
    });
    this.setState({
      print: printState,
    });
    const reqSigs = this.state.print.reqSigs;

    try {
      // If there is an existing signature document record in the DB, we need to update it through the API
      if (this.sigDocID !== 0) {
        const res = await XlinkAPI.postInOfficeSignatures(
          this.sigDocID,
          sigValue,
          this.state.print.currentSig.toString(),
          this.props.returnID,
        );
        if (res.status === 200 && this.state.print.reqSigs.length === 0) {
          this.props.showSnackbarMessage(
            'Signature(s) have been successfully saved.',
            'success',
            3500,
            { vertical: 'top', horizontal: 'center' },
          );
          this.print(
            this.props.isSignatureRequestActive ? this.state.pType : this.state.print.pType,
            this.state.print.overridePrefs,
            this.state.print.formOccur,
            this.state.print.signatures,
            '2',
          );
        }
      }
    } catch (err) {
      this.props.showErrorDialog('Failed to Post In-Office Signature for Remote Signature Request');
    }

    // If document has not been signed yet
    if (this.state.print.reqSigs.length !== 0) {
      this.setRequiredSignaturesNew(reqSigs, signaturePadType);

      // IF there is not a remote signature record for the document being signed, we update it through cs only
    } else if (this.sigDocID === 0) {
      // Signing in Office - invalidate any previous remote signature requests and documents
      const res = await XlinkAPI.invalidateRemoteSignature(this.props.returnID);
      if (res.status === 200 && this.state.print.reqSigs.length === 0) {
        this.props.showSnackbarMessage(
          'Signature(s) have been successfully saved.',
          'success',
          3500,
          { vertical: 'top', horizontal: 'center' },
        );
        this.props.signatureRequestActiveSet(false);
        this.print(
          this.props.isSignatureRequestActive ? this.state.pType : this.state.print.pType,
          this.state.print.overridePrefs,
          this.state.print.formOccur,
          this.state.print.signatures,
          '2',
          this.props.isReadOnly,
        );
      }
    }
  };

  handleOpenTextLinkSendModal = () => {
    // GetTextlinkMessageContent should be run only run time
    if (this.state.season >= 2021 && this.props.textLinkModalContentOriginal === '') {
      this.startSpin('Loading...');
      this.calcAPI.GetTextlinkMessageContent(this.state.print.cltr);
    } else {
      this.props.toggleTextLinkSendModal(true, '');
    }
  };

  handleSendTextLinkMessage = (name, number, carrier, message, carrierDomain) => {
    const msg = {
      name,
      number,
      carrier,
      message,
      carrierDomain,
      returnID: this.props.returnID,
    };
    this.calcAPI.SendTextLinkMessage(msg);
    this.props.toggleTextLinkSendModal(false, '');
  };

  handleSendTaxpassMessage = msg => {
    this.calcAPI.SendTaxpassMessage(msg);
  };

  handleNewEncryptedPassword = async (
    newEncryptedPassword,
    encryptedPdfTitle,
    taxpayerSelected,
    spouseSelected,
    representativeSelected,
    taxpayerSelectedEPass,
    spouseSelectedEPass,
    representativeSelectedEPass,
    taxpayerSelectedText,
    spouseSelectedText,
    representativeSelectedText,
  ) => {
    this.startSpin();
    // Save value for taxpayerSelected, spouseSelected, and RepresentativeSelected
    this.props.setTaxpayerSelected(taxpayerSelected);
    this.props.setTaxpayerSelectedEPass(taxpayerSelectedEPass);
    this.props.setTaxpayerSelectedText(taxpayerSelectedText);
    this.props.setSpouseSelected(spouseSelected);
    this.props.setSpouseSelectedEPass(spouseSelectedEPass);
    this.props.setSpouseSelectedText(spouseSelectedText);
    this.props.representativeSelected(representativeSelected);
    this.props.representativeSelectedEPass(representativeSelectedEPass);
    this.props.representativeSelectedText(representativeSelectedText);
    // Set the encrypted password to the most recently updated value
    await this.props.setSuggestedPassword(newEncryptedPassword);
    // Set encrypted PDF title to the most recently updated value
    this.props.setEncryptedPdfTitle(encryptedPdfTitle);
    // Close the Encrypted PDF modal
    this.props.toggleSuggestedPassword(false);
    // Open second encrypted pdf dialog
    if (taxpayerSelected || spouseSelected || representativeSelected) {
      this.props.setSentSuccessTitle('Attached Document Sent');
      this.props.setSentSuccessMsg('The attached document has been sent successfully');
      this.handleEncryptedPDFSelectedSecondStep();
    } else {
      this.props.setSentSuccessTitle('Password Send');
      this.props.setSentSuccessMsg('The password has been sent successfully');
      this.stopSpin();
      this.handleSaveSuggestedPasswordDoc();
    }
  };

  // Get ERO signature from db, given the efin number
  getEROSignature = (efinSignature, ctx, canvas, signaturePadType) => {
    // Check if we have an ero signature on db
    XlinkAPI.getefinSignature(efinSignature)
      .then(res => {
        const data = res.data;
        // If the data is blank, toggle the Signature Capture modal
        if (
          data.ero_signature === null ||
          data.ero_signature === '' ||
          data.ero_signature === undefined
        ) {
          // We need to decide which signature capture modal to open here, can be on screen, Scriptel, or Topaz signature pad
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.ON_SCREEN) {
            this.props.toggleSignatureCaptureModal(true);
          }
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.SCRIPTEL) {
            this.props.toggleScriptelSignatureCaptureModal(true);
          }
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.TOPAZ) {
            this.props.toggleTopazSignatureCaptureModal(true);
          }
        } else {
          // If the signature data is not blank, then do a data conversion to generate a 1 bit monochrome signature image(convert canvas to bmp)
          this.convertImage(data.ero_signature, ctx, canvas, data.ero_device_used);
        }
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to Fetch ERO Signature Data', error);
      });
  };

  getPreparerSignature = (ppSignature, ctx, canvas, signaturePadType) => {
    // Check if we have a  preparer signature on db
    XlinkAPI.getppSignature(ppSignature, this.props.returnID)
      .then(res => {
        const data = res.data;
        // If the data is blank, toggle the Signature Capture modal
        if (
          data.preparer_signature === null ||
          data.preparer_signature === '' ||
          data.preparer_signature === undefined
        ) {
          // We need to decide which signature capture modal to open here if no signature data found on db, can be on screen, Scriptel, or Topaz signature pad
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.ON_SCREEN) {
            this.props.toggleSignatureCaptureModal(true);
          }
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.SCRIPTEL) {
            this.props.toggleScriptelSignatureCaptureModal(true);
          }
          if (this.props.preferredSignaturePad === SIGNATURE_PAD_TYPES.TOPAZ) {
            this.props.toggleTopazSignatureCaptureModal(true);
          }
        } else {
          // If the signature data is not blank, then do a data conversion to generate a 1 bit monochrome signature image(convert canvas to bmp)
          this.convertImage(data.preparer_signature, ctx, canvas, data.preparer_device_used);
        }
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to Fetch Preparer Signature Data', error);
      });
  };

  convertImage = async (data, ctx, canvas, signaturePadType) => {
    await this.saveCanvasImage(data, ctx);
    // Decide which pad type we need to pass as argument to the toBMPDataURL function
    // We need to pass padType = "screen", when the signature pad type used to capture ERO or preparer signature used
    // Was either Scriptel or On-screen, if Topaz signature pad was used to capture signature, we need to set
    // the padType variable to "topaz"
    let padType = '';
    if (
      signaturePadType === SIGNATURE_PAD_TYPES.ON_SCREEN ||
      signaturePadType === SIGNATURE_PAD_TYPES.SCRIPTEL
    ) {
      padType = 'screen';
    } else {
      padType = 'topaz';
    }
    let sigString = toBMPDataURL(canvas, padType);
    sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info
    // Pass the signatures to calc server
    this.handleSignatureCapture(
      sigTypes[this.state.print.currentSig],
      sigString,
      this.props.preferredSignaturePad,
    );
    // Need to clear the canvas here
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  saveCanvasImage = (data, ctx) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(ctx.drawImage(image, 0, 0));
      image.onerror = reject;
      image.src = data;
    });
  };

  /**
   * Handles displaying Preview of PDF file to be printed.
   *
   * @function
   * @param {string} pdfBlob pdf blob is the pdf file used to display and to download
   */
  displayPrint = pdfBlob => {
    this.setState({
      currentOpenedAttachment: `data:application/pdf;base64,${pdfBlob}`,
      isPreviewModalOpen: true,
    });
  };

  convertToBlob = (pdfString, contentType) => {
    const byteChar = atob(pdfString);

    const byteNum = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteNum[i] = byteChar.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNum);
    const blob = new Blob([byteArray], { type: contentType });

    return blob;
  };

  openPrintComponentsModal = () => {
    if (!this.props.usingDemoAccount) {
      this.setState({
        printComponentsModal: true,
      });
    } else {
      this.handleNoPrintDemo();
    }
  };

  handleNoPrintDemo = () => {
    this.setState({
      noPrintDemoModal: true,
    });
  };

  closePrintComponentsModal = () => {
    this.setState({
      printComponentsModal: false,
    });
  };

  getStateAckList = () => {
    this.calcAPI.ReturnStateAck();
    this.startSpin('Checking State ACK Status...');
  };

  getAmendedStateAckList = () => {
    this.calcAPI.ReturnAmendedStateAck();
    this.startSpin('Checking Amended State ACK Status...');
  };

  openPrintStateAcksModal = isPrintStateAcksAmended => {
    this.setState({
      printStateAcksModal: true,
      isPrintStateAcksAmended,
    });
  };

  closePrintStateAcksModal = () => {
    this.setState({
      printStateAcksModal: false,
      isPrintStateAcksAmended: false,
    });
  };

  getAttachedStateList = () => {
    this.calcAPI.ReturnAttachedStates();
    this.startSpin('Confirming Attached States...');
  };

  openPrintStateClientLetters = () => {
    this.setState({
      printStateClientLettersModal: true,
    });
  };

  closePrintStateClientLetters = () => {
    this.setState({
      printStateClientLettersModal: false,
    });
  };

  handleAttachedFormsPrint = () => {
    if (!this.props.usingDemoAccount) {
      const doc = new JSPDF();
      doc.text('Attached Forms List', 15, 20);
      let currentRow = 30;
      this.props.formList.forEach(category => {
        if (currentRow > 280) {
          doc.addPage();
          doc.text('Attached Forms List', 15, 20);
          currentRow = 30;
        }
        doc.text(category.desc, 15, currentRow);
        if (category.Children !== undefined) {
          currentRow = this.generateAttachedForms(doc, category.Children, currentRow);
        }
        currentRow = currentRow + 8;
      });

      const pdfURI = doc.output('datauristring');
      const pdfString = pdfURI.replace('data:application/pdf;filename=generated.pdf;base64,', '');
      this.displayPrint(pdfString);
    } else {
      this.handleNoPrintDemo();
    }
  };

  generateAttachedForms = (doc, forms, currentRow) => {
    currentRow = currentRow + 8;
    forms.forEach(form => {
      if (currentRow > 280) {
        doc.addPage();
        doc.text('Attached Forms List', 15, 20);
        currentRow = 30;
      }
      doc.text(form.desc, 20, currentRow);
      if (form.GChildren !== undefined) {
        currentRow = this.generateAttachedWorksheets(doc, form.GChildren, currentRow);
      }
      currentRow = currentRow + 8;
    });
    return currentRow;
  };

  generateAttachedWorksheets = (doc, wks, currentRow) => {
    wks.forEach(form => {
      currentRow = currentRow + 8;
      if (currentRow > 280) {
        doc.addPage();
        doc.text('Attached Forms List', 15, 20);
        currentRow = 30;
      }
      doc.text(form.desc, 25, currentRow);
    });
    return currentRow;
  };

  /* End Print Handling */

  // focus on field
  goToField = (form, field) => {
    // Special case handling for CDS
    if (form === '000000') {
      form = FORM_NAMES.CDS;
    }
    // WIZ TODO: scrollIntoView removes the header state for some reason
    if (!this.props.isWizard) {
      setTimeout(() => {
        if (document.getElementById(field)) {
          const gotoField = document.getElementById(field);
          gotoField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          gotoField.focus();
        }
        // $('#' + field).focus();
      });
    }
  };

  scrollFormTo = yCoord => {
    const formContainer = document.getElementsByClassName('formContainer')[0];
    formContainer.scrollTop = yCoord;
  };

  setTaxForm = (data, currentFormName, currentFileName, subFrm) => {
    this.startSpin(SPIN_LOAD_MSG);
    const isAsset = assetFormRegExp.test(currentFormName);

    let curFrmName = currentFormName;
    if (this.payload?.is_business && currentFormName === 'CDSI00') {
      // Edge case for Corp Info status page. CDSI must redirect to 0001 for print current form
      curFrmName = '000100';
    } else if (curFrmName === '000a00') {
      // Case for Entering CDS from a completed interview mode form
      curFrmName = curFrmName.toUpperCase();
    }

    this.setState({
      activeForm: curFrmName,
      activeFile: currentFileName,
      assetMode: isAsset,
    });
    if (subFrm !== ``) {
      $('#subform').html(data);
    } else {
      $('#taxform').html(data);
    }
    for (let i = 1; i <= 9; i++) {
      const grp = $(`#grp${i}`);
      if (grp[0]) {
        grp.change(e => {
          this.toggleGroup(grp[0], i, e);
        });
      }
    }

    this.bindFormEvents();

    // Check for bookmark field, and go to that field if form has been loaded via field bookmark
    // var bkmkElementExists = document.getElementById(this.state.bookmarkField);
    if (document.getElementById(this.state.bookmarkField)) {
      this.goToField(this.state.activeForm, this.state.bookmarkField);
    } else if (this.state.verifyDirty) {
      // Verify mode enabled- form load has occured via a verify, and a calc has not yet been performed
      this.loadVerifyForm(currentFormName, currentFileName);
    } else {
      // Reset verify load count
      this.verifyFormLoadCount = 0;
    }
  };

  hideFormButton = () => {
    if (document.getElementById('buttonMenuForReturns')) {
      const el = document.getElementById('buttonMenuForReturns');
      el.style.setProperty('display', 'none');
    }
  };

  updateFormStack = () => {
    const formStack = this.state.formStack;
    const currentField = this.currentEl !== null ? this.currentEl.id : '';
    const lastForm = formStack[formStack.length - 1];
    if (lastForm === this.state.activeForm) {
      return;
    }
    formStack.push({
      form: this.state.activeForm,
      file: this.state.activeFile,
      field: currentField,
    });
    if (formStack.length > 5) {
      // keep track of last 5 forms, remove bottom of stak
      formStack.shift();
    }
    this.setState({
      formStack,
    });
  };

  getPreviousForm = () => {
    const formStack = this.state.formStack;
    const previousForm = formStack.pop();
    this.setState({
      formStack,
    });
    return previousForm;
  };

  getTaxForm = async (name, stmType = '', frmFile = '', field = '', updateFormStack = true) => {
    this.maxTableRow = 0;
    this.hideFormButton();
    if (updateFormStack) {
      this.updateFormStack();
    }
    // Handle Once If the First form loaded is not an XFM.
    if (this.state.initialSpecialFormLoad) {
      this.setState({ initialSpecialFormLoad: false });
    }
    this.startSpin(SPIN_LOAD_MSG);
    const formName = name.slice(0, 4);
    if (formName === '0000') {
      name = FORM_NAMES.CDS; // Appending the two zeroes for the occurence
    }
    if (formName.charAt(0).toUpperCase() === 'Z') {
      this.setState({
        interviewMode: true,
      });
    } else {
      this.setState({
        interviewMode: false,
      });
    }
    if (name === 'USIN01' && this.state.season > 2022) {
      await this.SendRemotePayment(false);
    }
    this.calcAPI.ReturnFormLoad(name, stmType, frmFile, field.substring(0, 4));
  };

  closeTransferDataModal = () => {
    this.setState({
      isTransferDataModalOpen: false,
      useYtyOptions: false,
    });
  };

  onYesTransferData = rtnID => {
    this.props.closeYTYDialog(); // prevent multiple events
    this.startSpin(SPIN_GENERIC); // Stopped by update_form, then a form_load to CDS occurs with another start/stop spin
    this.calcAPI.ReturnYearToYear(this.state.activeForm, rtnID);
  };

  openTransferDataOptionsModal = () => {
    this.setState({ useYtyOptions: true });
    this.props.openYTYDialog();
  };

  onYesTransferDataOption = (rtnID, option) => {
    // Handle Reload or StateOnly
    this.props.closeYTYDialog(); // prevent multiple events
    this.startSpin(SPIN_GENERIC); // Stopped by update_form, then a form_load to CDS occurs with another start/stop spin
    this.calcAPI.ReturnYearToYearOption(this.state.activeForm, rtnID, option);
  };

  onNoTransferData = () => {
    this.calcAPI.ReturnYearToYear(FORM_NAMES.CDS, 0); // No selected
    this.props.closeYTYDialog();
  };

  /**
   * Closes the year to year transfer modal and then loads the Client Data Screen(000A00).
   *
   * @function
   */
  onCancelTransferData = () => {
    this.props.closeYTYDialog();
    if (!this.state.useYtyOptions) this.calcAPI.ReturnFormLoad('000A00', '', '', '');

    this.setState({ useYtyOptions: false });
  };

  onCancelCurrentYear = () => {
    this.props.closeCurrentYearModal();
  };

  openCurrentYearModal = () => {
    this.props.closeYTYDialog();
    this.props.openCurrentYearModal();
  };

  startSpin = loadingText => {
    if (!loadingText || loadingText === '') {
      loadingText = SPIN_GENERIC;
    }
    this.setState({
      isLoading: true,
      loadingText,
    });
  };

  showErrorSnackbar = msg => {
    this.props.showSnackbarMessage(msg, 'error', 3500, { vertical: 'top', horizontal: 'center' });
  };

  trimNull = aStr => {
    if (!aStr || aStr === '') return aStr;
    const nullByte = '\u0000';
    if (aStr.includes(nullByte)) {
      // eslint-disable-next-line no-control-regex
      return aStr.replace(/\u0000/g, '');
    }
    return aStr;
  };

  doNotesAlert = () => {
    this.setState({
      notesAlert: true,
    });
    setTimeout(() => {
      this.setState({
        notesAlert: false,
        notesFlag: false,
      });
    }, NotesReminderMax);
  };

  showPromptSnackbar = msg => {
    // errors take precedence over prompts
    if (this.state.errorSnackbar) return;
    this.setState({
      notesAlert: false,
      promptSnackbar: true,
      promptSnackbarMessage: msg,
    });
  };

  hidePromptSnackbar = () => {
    if (this.stmcode) {
      return;
    }
    if (this.choices) {
      return;
    }
    this.setState({
      promptSnackbar: false,
      promptSnackbarMessage: '',
    });
  };

  updateTransmitList = (list, comments, isDiagnosticRequest) => {
    this.calcAPI.SetReturnReviewStatus(!this.state.readyForReview);
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'return_xmitqueue',
        returnID: parseInt(this.props.returnID),
        XmitListItems: list,
        comments,
        suspended: isDiagnosticRequest,
      }),
    );
  };

  reloadBilling = () => {
    this.calcAPI.ReturnReloadBilling();
  };

  setReturnReviewStatusDialog = () => {
    this.props.toggleSubmitForReviewDlg(true);
  };

  submitReturnReviewStatus = () => {
    this.calcAPI.SetReturnReviewStatus(!this.state.readyForReview);
    this.setState({ readyForReview: !this.state.readyForReview });
  };

  handleConfirmSubmitForReview = async () => {
    this.submitReturnReviewStatus();
    this.props.toggleSubmitForReviewDlg(false);
    try {
      // Save Return when submitting for review - If the user does not save manually or through other logic, the return data would be lost
      this.startSpin(SPIN_SAVE_MSG);
      await this.calcAPI.ReturnSave(this.getReturnMetadata(), this.props.isReadOnly);
    } catch (error) {
      ErrorHelpers.handleError('Error saving return', error);
    }
  };

  showConfirmClose = () => {
    // Just close the return if the return has not been intialized. No need to prompt for a save.
    if (!this.returnInitialized || this.props.isReadOnly) {
      this.closeReturn();
      return;
    }
    // Get value from the defaults setup page before showing the confirm close return modal
    // If the value in defaults modal is set to true, then call function closeAndSaveReturn
    if (this.props.silentlySaveReturnOnClose === '1') {
      this.closeAndSaveReturn();
    } else {
      this.setState({ confirmReturnClose: true });
    }
  };

  hideConfirmClose = () => {
    this.setState({ confirmReturnClose: false });
  };

  /* Depreciation Handling */

  handleAddAsset = activity => {
    this.setState({
      selectedAsset: activity,
    });
    this.props.toggleAddFormList();
    this.props.toggleAddAssetModal(true);
  };

  deleteAsset = id => {
    this.calcAPI.ReturnDeleteAsset(id);
  };

  reassignAsset = (astID, actvID) => {
    this.calcAPI.ReturnReassignAsset(astID, actvID);
  };

  addNewAsset = (id, type, desc, date, assetCode) => {
    this.calcAPI.ReturnNewAsset(id, type, desc, date, assetCode);
  };

  viewAsset = async id => {
    this.calcAPI.ReturnViewAsset(id);
  };

  toggleAssetSnackbar = (message, toggle) => {
    this.setState({
      assetSnackbar: toggle,
      assetSnackbarMsg: message,
    });
  };

  /* End Depreciation Handling */

  /* Payment Capture */
  toggleReturnPayment = val => {
    if (val) {
      this.startSpin(SPINNER_DIALOGS.PROCESS_REQUEST);
      this.calcAPI.ReturnPaymentQuery();
    }
    this.setState({
      returnPaymentOpen: val,
      paymentTerminalStatus: null,
    });
  };

  setPaymentTerminalStatus = status => {
    this.setState({ paymentTerminalStatus: status });
  };

  showConfirmDeleteReturn = () => {
    this.setState({ confirmDeleteReturn: true });
  };

  hideConfirmDeleteReturn = () => {
    this.setState({ confirmDeleteReturn: false });
  };

  toggleNotesForm = () => {
    if (this.state.activeForm === 'retn19') {
      return;
    }
    this.getTaxForm('retn19');
  };

  toggleReturnStatusLock = () => {
    const spinnerLockMsg = this.props.lockedStatus ? 'Unlocking Return...' : 'Locking Return...';
    this.startSpin(spinnerLockMsg);
    this.calcAPI.ReturnToggleStatusLock();
    this.props.needingVerifiedSet(true);
  };

  copyToTrainingDupeCheck = () => {
    this.calcAPI.CopyToTrainingDupeCheck();
  };

  copyToTraining = () => {
    this.calcAPI.CopyToTraining();
    this.showPromptSnackbar('Tax return copied to training database');
  };

  SendRemotePayment = (showSnackBar = true) => {
    this.calcAPI.SendRemotePayment();
    if (showSnackBar) {
      this.showPromptSnackbar('Remote Invoice has been sent');
    }
  };

  copyToLiveDupeCheck = () => {
    this.calcAPI.CopyToLiveDupeCheck();
  };

  copyToLive = () => {
    this.calcAPI.CopyToLive();
    this.showPromptSnackbar('Tax return copied to live database');
  };

  toggleFieldLock = async fieldLock => {
    const inputs = Array.from($('#taxform').find(':input'));
    inputs.forEach(x => {
      if (x.id && x.id !== '') {
        const obj = $(`[id="${x.id}"]`);
        if (fieldLock) {
          // NOTE: id MUST be string-quoted to succesfully select IDs with dollar signs
          obj.addClass('lockedField');
          x.disabled = true;
        } else {
          obj.removeClass('lockedField');
          x.disabled = false;
        }
      }
    });
    this.props.toggleReturnLock();
  };

  onClickViewReturn = () => {
    this.setState({ showSwitchReturnDlg: true });
  };

  switchReturn = saveReturn => {
    if (saveReturn) {
      this.closeAndSaveReturn();
    } else {
      this.closeReturn();
    }

    this.setState({ showSwitchReturnDlg: false });

    setTimeout(() => {
      this.props.onSelectSidebarTab(SIDE_TABS.DASHBOARD_MAIN);
      this.props.setTitleTabProperties(
        'Tax Returns',
        true,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.LIST_VIEW,
      );
      this.props.setTitleTabProperties(
        '',
        false,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.WIDGETS,
      );
      this.props.setTitleTabProperties(
        '',
        false,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.ACTIVE_RETURN,
      );
    });

    this.props.requestAckNotification(this.props.notificationID);
    this.props.fetchReturnProfileByReturnID(this.props.notificationReturnID);
  };

  addInterviewConfirm = async () => {
    if (!this.state.addInterviewFlag) {
      await this.setState({ addInterviewFlag: true });
      this.interviewMode();
    }
    this.hideAddInterviewModal();
  };

  addInterviewCancel = () => {
    if (this.state.addInterviewFlag) {
      this.setState({ addInterviewFlag: false });
    }
    this.hideAddInterviewModal();
  };

  showAddInterviewModal = () => {
    const interviewModeStarted =
      this.props.formList.find(formType => formType.var === FORM_TYPE_VAR.INTERVIEW_MODE) !==
      undefined; // retn37 == interview mode

    // If interview mode has not been initiated yet, get confirmation from the user, otherwise, redirect to the existing interview mode form
    if (!this.state.addInterviewModalFlag && !interviewModeStarted) {
      this.setState({ addInterviewModalFlag: true });
    } else {
      this.interviewMode();
    }
  };

  hideAddInterviewModal = () => {
    if (this.state.addInterviewModalFlag) {
      this.setState({ addInterviewModalFlag: false });
    }
  };

  triggerUpdateForm = () => {
    this.calcAPI.ReturnCalc(
      this.state.activeForm,
      assetFormRegExp.test(this.state.activeForm) ? 'X' : '',
      this.currentEl.id,
      this.currentEl.value,
      CALC_TYPE.FIELD_CALC,
    );
  };

  hideConsentModal = (disagree = false) => {
    if (this.state.showConsentForm) {
      if (disagree) {
        document.getElementById('TEXT00').value = '';
      } else {
        $(this.currentEl).blur();
      }
      this.setState({ showConsentForm: false });
      // need this function to trigger update form so after we close out consent form, the form can update the field focus
      this.triggerUpdateForm();
    }
  };

  gotoWallet = async () => {
    // TODO -> This may be needed in the future
    // this.setState({ walletMessage: false });
    const isDrilldown = this.props.ddh.length > 1;
    const efinID = this.props.officeProfile.efin_id;
    let walletData;

    try {
      walletData = await getWallet(this.state.season, isDrilldown, efinID, this.props.returnID);
    } catch (err) {
      if (err?.response?.status === 401 || walletData?.walletAccountOverride) {
        this.props.showErrorDialog(
          'Insufficient wallet funds. Please contact a user with wallet access to add funds to your wallet.',
        );
      } else {
        this.props.showErrorDialog(
          'Unable to connect to your wallet at this time. Please contact technical support if the problem persists.',
        );
      }
      return;
    }

    let walletToken;
    let walletSecToken;
    const walletType = 'office';

    // get the correct token for use
    const wallets = walletData?.wallets;
    wallets?.forEach(wallet => {
      if (wallet?.walletType === walletType) {
        walletToken = wallet.token;
        walletSecToken = wallet.secToken;
      }
    });

    // make sure both tokens exist before opening wallet
    if (walletToken && walletSecToken) {
      openWallet(walletToken, walletSecToken, this.state.season, isDrilldown);
    } else {
      this.props.showErrorDialog(
        'Unable to connect to your wallet at this time. Please contact technical support if the problem persists.',
      );
    }
  };

  handleBackButton = (location, action) => {
    if (action === 'POP') {
      // wizard mode action for hitting back
      if (this.props?.isWizard) {
        this.props.closeReturnProfile();
        this.props.clearActiveReturn();
        this.props.closeActiveReturn();
        // allows to for push go to tax returns page
        this.setState({ hasConfirmedClose: true });
        this.props.history.push({ pathname: '/tax-returns' });
      } else {
        history.pushState(null, null, location.pathname);
        this.setState({ confirmReturnClose: true });
      }
    }
  };

  getReturnGUID = async () => {
    try {
      const res = await XlinkAPI.getReturnGUID(this.props.returnID);
      if (statusOK(res)) {
        const returnGUID = res.data;
        // strip hyphen
        const returnGUIDTrimmed = returnGUID.replaceAll('-', '');
        return returnGUIDTrimmed;
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching invoice data: ', error);
    }
  };

  getWalletToken = async (isRemoteInvoice = false) => {
    const efinID = this.props.officeProfile.efin_id;
    try {
      const response = await XlinkAPI.getWallet(efinID, this.props.returnID, isRemoteInvoice, true);
      if (response) {
        let token;
        let secToken;
        let walletType;
        // check if wallet type will be overridden
        // if true account will take care of all payments
        if (response.data?.walletAccountOverride) {
          walletType = 'account';
        } else {
          walletType = 'office';
        }

        // get the correct token for use
        const wallets = response.data?.wallets;
        wallets?.forEach(wallet => {
          if (wallet?.walletType === walletType) {
            token = wallet.token;
            secToken = wallet.secToken;
          }
        });
        return {
          walletToken: token,
          secToken,
        };
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to Fetch Wallet Information properly: ', error);
    }
  };

  renderHeaderBar = () => {
    const header = (
      <HeaderBarForms
        notesAlert={this.state.notesAlert}
        assetMode={this.state.assetMode}
        taxYear={this.props.taxYear}
        clientName={this.props.clientName}
        deleteReturn={this.isLocked() ? this.closeLockedReturn : this.deleteReturn}
        showConfirmDelete={this.showConfirmDeleteReturn}
        closeAndSaveReturn={this.closeAndSaveReturn}
        closeLockedReturn={this.closeLockedReturn}
        closeReturn={this.closeReturn}
        showConfirmClose={this.showConfirmClose}
        saveReturn={this.saveReturn}
        setVerifyType={type => this.setVerifyType(type)}
        verifyReturn={this.verifyReturn}
        prefs={this.state.print.prefs}
        handlePrint={(type, prefs, formOccur, menuText) =>
          this.handlePrint(type, prefs, formOccur, menuText)
        }
        handleOpenTextLinkSendModal={this.handleOpenTextLinkSendModal}
        toggleTextLinkReceivedModal={this.props.toggleTextLinkReceivedModal}
        toggleShowNoTextLinkClientsDlg={this.props.toggleShowNoTextLinkClientsDlg}
        toggleTextLinkNotActivatedDialog={this.props.toggleTextLinkNotActivatedDialog}
        isTextLinkActivated={this.props.isTextLinkActivated}
        handlePrintSignatures={type => this.handleGetReqSigs(type)}
        openPrintReturnComponents={this.openPrintComponentsModal}
        getStateAckList={this.getStateAckList}
        getAmendedStateAckList={this.getAmendedStateAckList}
        getAttachedStates={this.getAttachedStateList}
        handleAttachedFormsPrint={this.handleAttachedFormsPrint}
        interviewMode={this.interviewMode}
        reloadBilling={this.reloadBilling}
        showYearToYear={this.openTransferDataOptionsModal}
        toggleYearToYearOptions={this.props.pyReturns?.length > 0}
        setReturnReviewStatusDialog={this.setReturnReviewStatusDialog}
        toggleReturnPayment={this.toggleReturnPayment}
        toggleNotes={this.toggleNotesForm}
        toggleReturnStatusLock={this.toggleReturnStatusLock}
        isTrainingMode={this.props.isTrainingMode}
        readyForReview={this.state.readyForReview}
        retractReturnForReview={this.handleConfirmSubmitForReview}
        copyToTraining={this.copyToTrainingDupeCheck}
        copyToLiveDupeCheck={this.copyToLiveDupeCheck}
        currentView={this.props.currentView}
        convertPrintPrefs={(prefs, printType) => this.convertPrintPrefs(prefs, printType)}
        season={this.state.season}
        scrollPositionInit={true}
        usingDemoAccount={this.props.usingDemoAccount}
        handleNoPrintDemo={this.handleNoPrintDemo}
        inReviewByTransmitOffice={
          this.props.officeProfile &&
          !this.props.officeProfile.is_feeder_office &&
          this.state.readyForReview
        }
        ddh={this.props.ddh}
        loginID={this.props.loginID}
        returnID={this.props.returnID}
        friendlyID={this.props.friendlyID}
        showAddInterviewModal={this.showAddInterviewModal}
        loadSignaturePage={printFormName => this.calcAPI.ReturnFormLoad(printFormName, '', '', '')}
        officeProfile={this.props.officeProfile}
        isTaxPassReturn={this.props.isTaxPassReturn}
        hasFLST={this.hasFLST}
        handleWizardStep={this.props?.handleStep}
        handleRequireFilingStatusDlg={this.handleRequireFilingStatusDlg}
        handleRemoteSignSelected={printFormName => this.handleRemoteSignSelected(printFormName)}
        isSuperOrTechUserReadOnly={() => isSuperOrTechUserReadOnly(this.payload)}
        isLoading={this.props.isWizard ? null : this.state.isLoading}
        isFeeder={get(this.props.officeProfile, 'is_feeder_office', false)}
        transmissionBlocked={get(this.props.officeProfile, 'transmission_blocked', false)}
      />
    );

    return header;
  };

  render() {
    let loadingContent;
    if (this.state.isLoading) {
      loadingContent = (
        <Spinner
          size={100}
          color="blue"
          loadingText={this.state.loadingText}
          textColor="white"
          bgColor="grey"
          lockActions={true} // do not allow user to click while loading
        />
      );
    } else {
      loadingContent = '';
    }

    const readyForReviewType = this.state.readyForReview ? 'Retract' : 'Submit';

    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={appTheme}>
        <Prompt
          message={(location, action) => {
            this.handleBackButton(location, action);
            if (!this.props.isWizard) {
              return this.state.hasConfirmedClose;
            }
          }}
        />
        <div className={this.props.isWizard ? 'guided-estimator-mainContainer' : 'mainContainer'}>
          {/* This checks if it is a wizard return, other wise load header as usual */}
          {!this.props.isWizard && this.renderHeaderBar()}

          <AccountInfoBanner isInsideReturn={true} />

          <div className="rowC formviewer-container">
            {/* This checks if it is a regular return, other wise load return list as usual */}
            {this.formListReady() && !this.props.isWizard && (
              <div
                id="sideBarFormsContainer"
                style={this.state.assetMode ? { background: '#dddddd' } : { marginTop: '1em' }}
              >
                <SideBarForms
                  assetMode={this.state.assetMode}
                  openForm={this.getTaxForm}
                  className="sidebarForms"
                  formList={this.props.formList}
                  attachedStatesCount={this.props.attachedStatesCount}
                  onClickAddForm={(tab, index) => this.openAddFormList(tab, index)}
                  handleSidebarFormClick={this.handleSidebarFormClick}
                  activeForm={this.state.activeForm}
                  handleDeleteForm={(form, desc) => this.toggleDeleteDialog(form, desc)}
                  handleDeletePkg={(pkg, desc) => this.toggleDeletePkgDialog(pkg, desc)}
                  returnProfile={this.props.returnProfile}
                  detachDocument={this.onClickDetachDocument}
                  getDocumentIDByVar={this.getDocumentIDByVar}
                  locked={this.isLocked()}
                  showAddInterviewModal={this.showAddInterviewModal}
                  addInterviewFlag={this.state.addInterviewFlag}
                  tabListDefinitions={this.state.tabListDefinitions}
                  isDemoAccount={this.props.usingDemoAccount}
                />
              </div>
            )}

            <div style={{ display: 'none' }}>
              {
                // eslint-disable-next-line react/no-string-refs
                <canvas id="cnv" name="cnv" ref="cnv" width="480" height="100" />
              }
            </div>
            <div
              className="formContainer"
              onScroll={this.scrollPositionInit}
              style={
                ['retn24', 'prin12', 'prin17', 'prin18', 'form12'].indexOf(this.state.activeForm) >
                -1
                  ? styles.formStretch
                  : styles.formAuto
              }
            >
              {!this.props.isWizard && this.isLocked() ? (
                <span className="row ovLabel" style={styles.lockedByMessage}>
                  <img
                    src={lockIcon}
                    style={{
                      height: '26px',
                      marginTop: '0.2em',
                      paddingLeft: '1em',
                    }}
                  />
                  {this.getLockedMessage()}
                </span>
              ) : (
                <Fragment />
              )}

              {this.props.notificationsCount > 0 ? (
                <div id="NotificationContainer" style={{ marginBottom: '1em' }}>
                  <Notifications
                    onFormViewer={true}
                    onClickViewReturn={this.onClickViewReturn}
                    removeMargins={true}
                  />
                </div>
              ) : (
                <Fragment />
              )}

              <span className="centered-spinner">{loadingContent}</span>
              {this.loadSpecialForm()}
              <ButtonMenuElement
                buttonItems={this.state.menuButtonsChoices}
                btnMenuOpen={this.state.menuButtonOpen}
                openButtonMenu={this.openButtonMenu}
                closeButtonMenu={this.closeButtonMenu}
              />
            </div>
            {/* Check to see if current form is a specialFormLoad form, if so, we do not need fieldInfo button */}
            {!['retn18', 'retn19', 'retn24', 'form12', 'prin12', 'prin17', 'prin18'].includes(
              this.state.activeForm,
            ) && (
              <FieldInfo
                calcAPI={this.calcAPI}
                startSpin={() => this.startSpin(SPIN_LOAD_MSG)}
                currentView={this.props.currentView}
                currentEl={this.currentEl}
                activeForm={this.state.activeForm}
                restrictedFieldData={this.state.restrictedFieldData}
                fieldFormLinks={this.state.fieldFormLinks}
                getTaxForm={(form, currentEleID, wksType) =>
                  this.getTaxForm(form, wksType, '', currentEleID)
                }
                isWizard={this.props.isWizard}
                taxYear={this.props.taxYear}
              />
            )}

            <Modal
              open={this.state.returnPaymentOpen}
              onClose={() => this.toggleReturnPayment(false)}
              disableBackdropClick={true}
            >
              <CapturePayment
                open={this.state.returnPaymentOpen}
                onClose={() => this.toggleReturnPayment(false)}
                returnPaymentInfo={this.state.returnPaymentInfo}
                pollTerminalStatus={reqID => this.calcAPI.PayJunctionTerminalStatus(reqID)}
                terminalStatus={this.state.paymentTerminalStatus}
                setTerminalStatus={this.setPaymentTerminalStatus}
                processPaymentDetails={(a, b, c, d, e, f, g) => {
                  this.calcAPI.ReturnProcessPaymentDetails(
                    a,
                    b,
                    c,
                    d,
                    e,
                    f,
                    this.state.activeForm,
                    g,
                    this.props.isReadOnly,
                  );
                }}
                snackbarToggle={this.showPromptSnackbar}
                errorHandler={this.props.showErrorDialog}
                returnID={this.props.returnID}
                saveReturn={this.saveReturn}
                sendRemotePayment={this.SendRemotePayment}
                getReturnGUID={this.getReturnGUID}
                getWalletToken={this.getWalletToken}
                season={this.state.season}
              />
            </Modal>

            <Modal
              id="mdlAttachDocumentFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isVisionModalOpen}
              onClose={() => this.setState({ isVisionModalOpen: false })}
              disableBackdropClick
            >
              <Paper elevation={5} classes={{ root: classes.addDocumentModal }}>
                <VisionAssistModal
                  runTVASend={this.runTVASend}
                  handleCloseVisionModal={() => this.setState({ isVisionModalOpen: false })}
                  buttonID={this.props.documentsToBeAttached.btnid}
                  showSnackBarErr={this.showErrorSnackbar}
                  TVAargType={this.state.TVAargType}
                  TVAprogress={this.state.TVAprogress}
                />
              </Paper>
            </Modal>

            <Modal
              id="mdlAddFormModalFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.addFormModalOpen}
              onClose={() => this.props.toggleAddFormList()}
              disableBackdropClick={true}
            >
              <Paper
                elevation={5}
                style={isShortScreenRez ? styles.addFormModalShortScreen : styles.addFormModal}
              >
                <AddFormModal
                  activeTab={this.state.activeAddFormTab}
                  availableForms={this.props.addFormList}
                  availableActivities={this.props.activityList}
                  openAddFormList={(tab, index) => this.openAddFormList(tab, index)}
                  updateAddFormList={pkg => this.updateAddFormList(pkg)}
                  handleAddForm={form => this.handleAddForm(form)}
                  handleAddFormIndex={form => this.handleAddFormIndex(form)}
                  addAsset={asset => this.handleAddAsset(asset)}
                  handleCloseFormModal={() => this.props.toggleAddFormList()}
                  formList={this.props.formList}
                  tabListDefinitions={this.state.tabListDefinitions}
                  isSuperOrTechUserReadOnly={() => isSuperOrTechUserReadOnly(this.payload)}
                  isLocked={this.isLocked}
                  readyForReview={this.state.readyForReview}
                  isFeederOffice={this.props.officeProfile.is_feeder_office}
                  isBusiness={this.payload?.is_business}
                  availablePackages={this.state.availablePackages}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlQueueReturnModalFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.queueReturnModalOpen}
              onClose={this.closeQueueReturnModal}
            >
              <Paper elevation={5} style={styles.queueReturnModal}>
                <QueueReturn
                  transmitItemList={this.state.transmitItemList}
                  feeItemList={this.state.feeItemList}
                  feeTotal={this.state.feeTotal}
                  walletBalance={this.state.walletBalance}
                  wndwNote={this.state.wndwNote}
                  updateTransmitList={this.updateTransmitList}
                  closeQueueReturnModal={this.closeQueueReturnModal}
                  closeQueueReturnModalDemo={this.closeQueueReturnModalDemo}
                  closeReturn={this.isLocked() ? this.closeLockedReturn : this.closeAndSaveReturn}
                  submitReturnForReview={this.submitReturnReviewStatus}
                  isFeeder={get(this.props.officeProfile, 'is_feeder_office', false)}
                  transmissionBlocked={get(this.props.officeProfile, 'transmission_blocked', false)}
                />
              </Paper>
            </Modal>
            <SimpleDialog
              open={this.state.openDemoMessage}
              onConfirm={() => {
                this.setState({ openDemoMessage: false });
              }}
              confirmText="OK"
              styled={true}
              dialogTitle="Demo Version"
              contentText="You are currently using a demo version of the program."
              // eslint-disable-next-line react/no-children-prop
              children="Please contact sales for more information:"
              extendedBody={
                <div>
                  <div>
                    <b>Phone: </b>800.345.4337
                  </div>
                  <div>
                    <b>Fax: </b>209.835.2759
                  </div>
                  <div>
                    <b>Email: </b>sales@CrossLinkTax.com
                  </div>
                </div>
              }
            />
            <SimpleDialog
              open={this.props.pdfModalSuccess}
              onConfirm={() => {
                this.props.setEncryptedPDFSuccessDialog(false);
              }}
              confirmText="OK"
              styled={true}
              dialogTitle={this.props.sentSuccessTitle}
              contentText={this.props.sentSuccessMsg}
            />
            <SimpleDialog
              open={this.state.noPrintDemoModal}
              onConfirm={() => {
                this.setState({ noPrintDemoModal: false });
              }}
              confirmText="OK"
              styled={true}
              dialogTitle="Demo Version"
              contentText="You are currently using a demo version of the program. Print functionality is not enabled. Please contact sales for more information:"
              extendedBody={
                <div>
                  <div>
                    <b>Phone: </b>800.345.4337
                  </div>
                  <div>
                    <b>Fax: </b>209.835.2759
                  </div>
                  <div>
                    <b>Email: </b>sales@CrossLinkTax.com
                  </div>
                </div>
              }
            />
            <Modal
              id="mdlAttachDocumentFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.addAttachmentModalOpen}
              onClose={this.closeAddAttachmentModal}
            >
              <Paper elevation={5} classes={{ root: classes.addDocumentModal }}>
                <AttachDocumentModal
                  addOrAttachDocument={this.addOrAttachDocument}
                  handleCloseAttachDocumentModal={this.closeAddAttachmentModal}
                  documentsToBeAttached={this.props.documentsToBeAttached.Attachments}
                  buttonID={this.props.documentsToBeAttached.btnid}
                  attachments={this.props.attachments}
                  showSnackBarErr={this.showErrorSnackbar}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlImportStockTransactions"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.showImportStock}
              onClose={() =>
                this.setState({
                  showImportStock: false,
                })
              }
              disableBackdropClick={true}
            >
              <Paper elevation={5} classes={{ root: classes.addDocumentModal }}>
                <ImportStockTransactions
                  closeModal={() => this.setState({ showImportStock: false })}
                  showImportMsg={msg => this.setState({ importStockMsg: msg })}
                  uploadCSV={(file, spouse) => this.calcAPI.ReturnUploadCSV(file, spouse)}
                  menuCommandResp={this.state.menuCommandResp}
                  importStockMsg={this.state.importStockMsg}
                  toggleBtnSpinner={toggle => this.setState({ btnClickSpinner: toggle })}
                  btnClickSpinner={this.state.btnClickSpinner}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlChoiceListModalFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.choiceListModalOpen}
              onClose={() => this.props.toggleChoiceList(false)}
              disableBackdropClick={true}
            >
              <Paper elevation={5} style={styles.choiceListModal}>
                <ChoiceListModal
                  choiceListColumns={this.props.choiceListColumns}
                  choiceListData={this.props.choiceListData}
                  choiceListTitle={this.props.choiceListTitle}
                  handleChoiceListItem={(choiceItem, field) =>
                    this.handleChoiceListItem(choiceItem, field)
                  }
                  handleCloseChoiceModal={toggle => this.props.toggleChoiceList(toggle)}
                  choiceListIniField={this.props.choiceListIniField}
                />
              </Paper>
            </Modal>

            <Modal
              id="mdlremoteInvoiceModalFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.remoteInvoiceModalOpen}
              disableBackdropClick={true}
            >
              <Paper elevation={5} style={styles.remoteInvoiceModal}>
                <RemoteInvoiceModal
                  sendRemotePayment={this.props.sendRemotePayment}
                  onClose={() => store.dispatch(formViewerActions.toggleRemoteInvoiceModal(false))}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlAddReturnModalFormViewer"
              style={styles.addReturnModals}
              open={this.props.isTransferDataModalOpen}
              onClose={this.props.closeYTYDialog}
              disableBackdropClick={true}
            >
              <Paper elevation={5} style={styles.transferReturnModal}>
                <TransferData
                  toggleYearToYearOptions={this.state.useYtyOptions}
                  onYesTransferOption={this.onYesTransferDataOption}
                  onCancelTransferData={this.onCancelTransferData}
                  openCurrentYearModal={this.openCurrentYearModal}
                  onYesTransfer={this.onYesTransferData}
                  onNoTransfer={this.onNoTransferData}
                  pyReturns={this.props.pyReturns}
                  cyReturns={this.props.cyReturns}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlErrorRejectModalFormViewer"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.errorRejectModalOpen}
              onClose={this.closeErrorRejectModal}
            >
              <Paper elevation={5} style={styles.errorRejectModal}>
                <ErrorRejectModal
                  activeTab={this.state.activeErrorRejectTab}
                  verifyType={this.state.verifyType}
                  verifyList={this.props.verifyList}
                  rejectList={this.props.rejectList}
                  importErrorList={this.props.importErrorList}
                  bankRejectList={this.props.bankRejectList}
                  alertList={this.props.alertList}
                  errors={this.props.errorNumber}
                  warnings={this.props.warningNumber}
                  handleVerify={(form, stmCode, file, field, desc, tab) =>
                    this.handleVerify(form, stmCode, file, field, desc, tab)
                  }
                  handlePrint={(type, prefs, val, menuText) =>
                    this.handlePrint(type, prefs, val, menuText)
                  }
                  handleCloseFormModal={() => this.closeErrorRejectModal()}
                  showVerifyModalSuccessDlg={this.props.showVerifyModalSuccessDlg}
                  toggleVerifyModalDialog={this.props.toggleVerifyModalDialog}
                  queueReturn={this.queueReturn}
                  addFrontendNotification={this.props.addFrontendNotification}
                  returnID={this.props.returnID}
                  season={this.state.season}
                  loadSignaturePage={printFormName => {
                    this.saveReturn();
                    this.calcAPI.ReturnFormLoad(printFormName, '', '', '');
                  }}
                  toggleRequestDocsModal={this.toggleRequestDocsModal}
                  saveReturn={this.saveReturn}
                  getTaxForm={this.getTaxForm}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlPrintComponentsModalFormViewer"
              aria-labelledby="print-components-title"
              aria-describedby="print-components-description"
              open={this.state.printComponentsModal}
              onClose={this.closePrintComponentsModal}
            >
              <Paper elevation={5} style={styles.printComponentsModal}>
                <PrintComponents
                  isBusiness={this.payload?.is_business}
                  prefs={this.state.print.prefs}
                  handleClose={this.closePrintComponentsModal}
                  handlePrint={(type, prefs, val, menuText) =>
                    this.handlePrint(type, prefs, val, menuText)
                  }
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlPrintStateAcksModalFormViewer"
              aria-labelledby="print-state-acks-title"
              aria-describedby="print-state-acks-title"
              open={this.state.printStateAcksModal}
              onClose={this.closePrintStateAcksModal}
            >
              <Paper elevation={5} style={styles.printStateAcksModal}>
                <PrintStateAcks
                  stateAcks={this.props.stateAcks}
                  isPrintStateAcksAmended={this.state.isPrintStateAcksAmended}
                  handleClose={this.closePrintStateAcksModal}
                  handlePrint={(type, prefs, val, menuText) =>
                    this.handlePrint(type, prefs, val, menuText)
                  }
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlPrintStateClientLettersFormViewer"
              aria-labelledby="print-state-client-letters-title"
              aria-describedby="print-state-client-letters-title"
              open={this.state.printStateClientLettersModal}
              onClose={this.closePrintStateClientLetters}
            >
              <Paper elevation={5} style={styles.printStateAcksModal}>
                <PrintStateClientLetters
                  states={this.props.attachedStates}
                  handleClose={this.closePrintStateClientLetters}
                  handlePrint={(type, prefs, val, menuText) =>
                    this.handlePrint(type, prefs, val, menuText)
                  }
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlEventLogFormViewer"
              aria-labelledby="event-log-title"
              open={this.props.eventLogModal}
              onClose={() => this.props.toggleEventLogModal(false)}
            >
              <Paper elevation={5} style={styles.eventLogModal}>
                <EventLog
                  returnID={this.props.returnID}
                  pssn={this.props.primarySSNEIN}
                  toggleModal={toggle => this.props.toggleEventLogModal(toggle)}
                  locked={this.payload.is_tech_support_and_impersonating}
                  readyForReview={this.state.readyForReview}
                  isFeederOffice={this.props.officeProfile.is_feeder_office}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlK1ManagerModalFormViewer"
              aria-labelledby="k1Manager-modal-title"
              open={this.props.isK1ManagerModalOpen}
              onClose={() => this.props.toggleK1ManagerModal(false)}
              disableBackdropClick={true}
            >
              <Paper elevation={5} style={styles.k1ManagerModal}>
                <K1ManagerModal
                  open={this.props.isK1ManagerModalOpen}
                  toggleModal={toggle => this.handleToggleK1ManagerModal(toggle)}
                  k1List={this.state.k1List}
                  k1pkgList={this.state.k1pkgList}
                  handleListK1s={pkg => this.calcAPI.ReturnListK1s(pkg)}
                  handleAddK1={() => this.calcAPI.ReturnAddK1()}
                  handleChangeHideK1s={flag => this.handleChangeHideK1s(flag)}
                  handleDeleteK1={ocr => this.calcAPI.ReturnDeleteK1(ocr)}
                  handleEmailK1={ocr => this.calcAPI.ReturnEmailK1(ocr)}
                  navToK1={ocr => this.calcAPI.ReturnFormLoad(ocr, '', '', '')}
                  hideK1Flag={this.state.hideK1Flag}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlAddAsset"
              open={this.props.addAssetModal}
              onClose={() => this.props.toggleAddAssetModal(false)}
            >
              <Paper elevation={5} style={styles.addAssetModal}>
                <AddAsset
                  toggleModal={toggle => this.props.toggleAddAssetModal(toggle)}
                  assetDetails={this.state.selectedAsset}
                  addNewAsset={(id, type, desc, date, assetCode) =>
                    this.addNewAsset(id, type, desc, date, assetCode)
                  }
                />
              </Paper>
            </Modal>
            <Dialog
              id="mdlPrintDialog"
              aria-labelledby="print-dialog-modal-title"
              aria-describedby="print-dialog-modal-description"
              open={!!this.props.printDialog}
              onClose={() => this.props.togglePrintDialog(false)}
              PaperProps={{ tabIndex: -1 }}
              disableAutoFocus={true}
              disableBackdropClick={true}
            >
              <PrintDialog
                style={styles.printDialog}
                pType={this.state.print.pType}
                formOccur={this.state.print.formOccur}
                prefs={this.state.print.prefs}
                reqSigs={this.state.print.reqSigs}
                pActionEligible={this.state.print.pActionEligible}
                menuText={this.state.print.menuText}
                handleClose={() => this.props.togglePrintDialog(false)}
                handlePrintSelected={() => this.handlePrintActionsPrintSelected()}
                handleSigningInOffice={() => this.handleSigningInOffice()}
                handleRemoteSignSelected={() => this.handleRemoteSignSelected()}
                handleEncryptedPDFSelected={() => this.handleEncryptedPDFSelected()}
                printPreferences={this.state.print.prefs}
                onScreen={this.props.onScreen}
                scriptel={this.props.scriptel}
                topaz={this.props.topaz}
                setPreferredSignaturePad={signaturePadType =>
                  this.props.setPreferredSignaturePad(signaturePadType)
                }
                handlePrintPDFWarningMessage={this.handlePrintPDFWarningMessage}
                printPDFWarningMessage={this.props.printPDFWarningMessage}
                loginID={this.props.loginID}
                returnStatus={this.props.returnStatus}
              />
            </Dialog>
            <Modal
              id="mdlSignatureCapture"
              aria-labelledby="signature-capture-modal-title"
              aria-describedby="signature-capture-modal-description"
              open={this.props.signatureCapture}
              onClose={() => {
                this.props.toggleSignatureCaptureModal(false);
                store.dispatch(formViewerActions.setRMSflag(false));
              }}
            >
              <DialogContent>
                <SignatureCapture
                  currentSig={this.state.print.currentSig}
                  handleSignatureCapture={(sigType, sigValue, padType) =>
                    this.handleSignatureCapture(sigType, sigValue, padType)
                  }
                  handleClose={() => {
                    this.props.toggleSignatureCaptureModal(false);
                    store.dispatch(formViewerActions.setRMSflag(false));
                  }}
                />
              </DialogContent>
            </Modal>
            <Modal
              id="mdlTopazSignatureCapture"
              aria-labelledby="topaz-signature-capture-modal-title"
              aria-describedby="topaz-signature-capture-modal-description"
              open={this.props.topazSignatureCapture}
              onClose={() => {
                this.props.toggleTopazSignatureCaptureModal(false);
                store.dispatch(formViewerActions.setRMSflag(false));
              }}
              style={{ top: '20%' }}
            >
              <TopazSignatureCapture
                currentSig={this.state.print.currentSig}
                handleSignatureCapture={(sigType, sigValue, padType) =>
                  this.handleSignatureCapture(sigType, sigValue, padType)
                }
                handleClose={() => {
                  this.props.toggleTopazSignatureCaptureModal(false);
                  store.dispatch(formViewerActions.setRMSflag(false));
                }}
              />
            </Modal>
            <Modal
              id="mdlScriptelSignatureCapture"
              aria-labelledby="scriptel-signature-capture-modal-title"
              aria-describedby="scriptel-signature-capture-modal-description"
              open={this.props.scriptelSignatureCapture}
              onClose={() => {
                this.props.toggleScriptelSignatureCaptureModal(false);
                store.dispatch(formViewerActions.setRMSflag(false));
              }}
              style={{ top: '20%' }}
            >
              <ScriptelSignatureCapture
                currentSig={this.state.print.currentSig}
                handleSignatureCapture={(sigType, sigValue, padType) =>
                  this.handleSignatureCapture(sigType, sigValue, padType)
                }
                handleClose={() => {
                  this.props.toggleScriptelSignatureCaptureModal(false);
                  store.dispatch(formViewerActions.setRMSflag(false));
                }}
              />
            </Modal>
            <Modal
              id="mdlSendTextLinkMessage"
              open={!!this.props.textLinkModalOpen}
              onClose={() => this.props.toggleTextLinkSendModal(false, '')}
              style={{ top: '20%' }}
            >
              <Paper
                elevation={5}
                style={{
                  width: '40vw',
                  padding: '0',
                  position: 'relative',
                  margin: 'auto',
                  overflow: 'auto',
                }}
              >
                <TextLinkSendModal
                  toggleTextLinkSendModal={() => this.props.toggleTextLinkSendModal(false, '')}
                  textLinkModalContent={
                    this.props.textLinkModalContentOriginal || this.props.textLinkModalContent
                  }
                  handleSendTextLinkMessage={(name, number, carrier, message, carrierDomain) =>
                    this.handleSendTextLinkMessage(name, number, carrier, message, carrierDomain)
                  }
                  returnID={this.props.returnID}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlReceivedTextLinkMessages"
              open={!!this.props.textLinkReceivedModalOpen}
              onClose={() => this.props.toggleTextLinkReceivedModal(false)}
              style={{ top: '20%' }}
            >
              <Paper
                elevation={5}
                style={{
                  width: '60vw',
                  padding: '0',
                  position: 'relative',
                  margin: 'auto',
                  overflow: 'auto',
                }}
              >
                <TextLinkReceivedModal
                  toggleTextLinkSendModal={this.props.toggleTextLinkSendModal}
                  handleSendTextLinkMessage={(name, number, carrier, message, carrierDomain) =>
                    this.handleSendTextLinkMessage(name, number, carrier, message, carrierDomain)
                  }
                  setUnreadTextMessageCount={data =>
                    this.setState({ unreadTextMessageCount: data })
                  }
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlReceivedTaxpassMessages"
              open={!!this.props.taxpassMessageModalOpen}
              onClose={() => this.props.toggleTaxpassMessageModal(false)}
              style={{ top: '20%' }}
              disableBackdropClick={true}
            >
              <Paper
                elevation={5}
                style={{
                  width: '20vw',
                  padding: '0',
                  position: 'relative',
                  margin: 'auto',
                  overflow: 'none',
                }}
              >
                <TaxpassMessageModal
                  returnID={this.props.returnID}
                  handleSendTaxpassMessage={this.handleSendTaxpassMessage}
                  closeModal={() => this.props.toggleTaxpassMessageModal(false)}
                  refreshTaxpassMessageFlag={this.state.toggleTaxpassReload}
                />
              </Paper>
            </Modal>
            <Modal
              id="mdlAddArchiveDocumentModalDocArchive"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isPreviewModalOpen}
              onClose={this.closePreviewModal}
              className={classes.modalSpacing}
            >
              <Paper className={classes.modal}>
                <Typography variant="body1" className={classes.modalTitle}>
                  Final Tax Return
                </Typography>
                <div className="preview-doc-archive-modal-container">
                  <table>
                    <tbody>
                      <tr>
                        <td className="preview-doc-archive-modal-container-table-td">
                          <a
                            className="preview-doc-archive-modal-container-table-td-a"
                            onClick={this.openRequestPDFModal}
                          >
                            <img
                              className="preview-doc-archive-modal-container-table-td-a-img"
                              src={downloadIcon}
                            />
                            <button
                              id="mdlDocumentArchiveDownloadDocBtn"
                              className="preview-doc-archive-modal-container-table-td-a-btn"
                            >
                              Download Document
                            </button>
                          </a>
                        </td>
                        <td>
                          <Button
                            id="mdlDocumentArchiveDownloadDocCloseBtn"
                            variant="contained"
                            color="primary"
                            className={classes.alertModalButton}
                            onClick={this.closePreviewModal}
                          >
                            Close
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <object
                              width="950"
                              height="650"
                              id="downloadArchiveDoc"
                              data={this.state.currentOpenedAttachment}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Modal>
            <Modal
              id="mdlAddArchiveDocumentModalDocArchive"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isPDFAlertModalOpen}
            >
              <Paper className={classes.modal}>
                <Typography className={classes.modalTitle} color="inherit" variant="body1">
                  Print PDF
                </Typography>
                <div className="preview-doc-archive-modal-container">
                  <Typography variant="body1" className={classes.alertModalBody}>
                    {REQUEST_PDF_MSG}
                  </Typography>
                  <div className={classes.alertModalButtons}>
                    <Button
                      id="mdlDocumentArchiveDownloadDocCloseBtn"
                      variant="contained"
                      color="primary"
                      className={classes.alertModalButton}
                      onClick={this.closeRequestPDFModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="mdlDocumentArchiveDownloadDocCloseBtn"
                      variant="contained"
                      color="primary"
                      className={classes.alertModalButton}
                      href={this.state.currentOpenedAttachment}
                      onClick={this.closeRequestPDFModal}
                      download="document"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Paper>
            </Modal>
            <Dialog
              open={!!this.props.textLinkNotActivatedDlg}
              onClose={() => this.props.toggleTextLinkNotActivatedDialog(false)}
              disableBackdropClick={true}
            >
              <DialogTitle>TextLink Not Configured</DialogTitle>
              <DialogContent>
                <p>
                  TextLink has not been configured for this office. Use the office setup pages to
                  configure this feature.
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  id="btnTextLinkNotActivated"
                  onClick={() => this.props.toggleTextLinkNotActivatedDialog(false)}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={!!this.props.textLinkNoClients}
              onClose={() => this.props.toggleShowNoTextLinkClientsDlg(false)}
              disableBackdropClick={true}
            >
              <DialogTitle>Missing Required Data</DialogTitle>
              <DialogContent>
                <p>
                  TextLink cannot be used until taxpayer and/or spouse cell phone information has
                  been provided on the Client Data Screen.
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  id="btnTextLinkNotNoClientsOK"
                  onClick={() => this.props.toggleShowNoTextLinkClientsDlg(false)}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.showSwitchReturnDlg}
              onClose={() => this.setState({ showSwitchReturnDlg: false })}
              disableBackdropClick={true}
            >
              <DialogTitle>Switch Returns</DialogTitle>
              <DialogContent>
                <p>
                  You are attempting to view another return. Do you want to save the current return
                  before you continue to the return?
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  id="btnFormViewerSwitchReturnsSave"
                  onClick={() => this.switchReturn(true)}
                >
                  Save and Continue
                </Button>
                <Button
                  autoFocus
                  id="btnFormViewerSwitchReturnsNoSave"
                  onClick={() => this.switchReturn(false)}
                >
                  Continue without Saving
                </Button>
                <Button
                  autoFocus
                  id="btnFormViewerSwitchReturnsCancel"
                  onClick={() =>
                    this.setState({
                      showSwitchReturnDlg: false,
                    })
                  }
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <BookmarkField
              bookmarks={this.state.bookmarks}
              desc={this.state.bookmarks.currBookmarkDesc}
              timestamp={this.state.bookmarks.currBookmarkTimestamp}
              fieldID={this.state.bookmarks.bookmarkFldID}
              setBookmarkFldEditor={this.setBookmarkFldEditor}
              setBookmark={this.setBookmarkByFldID}
              snackbarToggle={this.showPromptSnackbar}
              isEditable={this.state.activeForm !== 'retn18'}
            />
            <Dialog
              open={this.state.requireFilingStatusDlg}
              onClose={() => this.setState({ requireFilingStatusDlg: false })}
              disableBackdropClick={true}
            >
              <DialogTitle>Filing Status Required</DialogTitle>
              <DialogContent>Please provide a filing status to continue.</DialogContent>
              <DialogActions>
                <Button
                  id="btnRequireFLST"
                  onClick={() =>
                    this.setState({
                      requireFilingStatusDlg: false,
                    })
                  }
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.confirmDeleteDialogOpen}
              onClose={() => this.toggleDeleteDialog(null, null)}
              disableBackdropClick={true}
              aria-labelledby="confirm-delete-title"
              aria-describedby="confirm-delete-desc"
            >
              <DialogTitle id="confirm-delete-title">
                Remove {this.state.removeFormDesc}
              </DialogTitle>
              <DialogContent id="confirm-delete-desc">{this.state.removeFormDlg}</DialogContent>
              <DialogActions>
                {this.state.removeFormIrremovable ? (
                  <>
                    <Button
                      id="btnCancelDeleteFormViewer"
                      onClick={() =>
                        this.setState({
                          confirmDeleteDialogOpen: false,
                        })
                      }
                    >
                      OK
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      id="btnCancelDeleteFormViewer"
                      onClick={() =>
                        this.setState({
                          confirmDeleteDialogOpen: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      id="btnDoDeleteFormViewer"
                      onClick={() => this.handleDeleteForm(this.state.removeForm)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.confirmAssetDelete}
              onClose={() => this.toggleAssetDeleteDialog()}
              disableBackdropClick={true}
              aria-labelledby="confirm-asset-delete-title"
              aria-describedby="confirm-asset-delete-desc"
            >
              <DialogTitle id="confirm-asset-delete-title">
                Delete Activity Item: {this.state.removeFormDesc}
              </DialogTitle>
              <DialogContent id="confirm-asset-delete-desc">
                Preparing to delete ALL assets and related forms associated with this activity. This
                cannot be undone.
              </DialogContent>
              <DialogActions>
                <Button
                  id="btnCancelDeleteAssetFormViewer"
                  onClick={() => this.toggleAssetDeleteDialog()}
                >
                  Cancel
                </Button>
                <Button
                  id="btnDoDeleteAssetFormViewer"
                  onClick={() => this.handleDeleteActivityForm(this.state.removeForm)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <SimpleDialog
              open={this.state.confirmPkgDelete}
              disableBackdropClick={true}
              onClose={() => this.toggleDeletePkgDialog(null, null)}
              onConfirm={() => this.handleDeletePkg(this.state.removePkgId)}
              confirmText="Delete"
              styled={true}
              dialogTitle="Remove State"
              extendedBody={
                <div>
                  {this.state.removePkgDesc} has been selected for removal. This cannot be undone.
                </div>
              }
            />
            <SimpleDialog
              open={this.props.missingInfoModalOpen}
              onConfirm={() => {
                this.props.toggleMissingInfoModal(false);
              }}
              confirmText="OK"
              styled={true}
              dialogTitle="Information Missing"
              extendedBody={
                <div>
                  <div style={{ marginTop: '0.5vh' }}>
                    <div>
                      <b>Taxpayer Information Missing</b>
                    </div>
                    <div>{ENCRYPTED_PDF_ERROR_MESSAGES.MISSING_TAXPAYER_INFO_EMAIL}</div>
                  </div>
                  {this.props.spouseInfoAvailable && (
                    <div style={{ marginTop: '0.5vh' }}>
                      <div>
                        <b>Spouse Information Missing</b>
                      </div>
                      <div>{ENCRYPTED_PDF_ERROR_MESSAGES.MISSING_SPOUSE_INFO_EMAIL}</div>
                    </div>
                  )}
                </div>
              }
            />
          </div>
          <Dialog
            open={this.props.messageDialogOpen}
            disableBackdropClick={true}
            onClose={this.props.closeMsgDialog}
          >
            <MessageDialog
              messageDialogInfo={this.props.messageDialogInfo}
              handleCloseDialog={() => this.props.closeMsgDialog()}
              handleResponseFromDialog={(e, a, b) => this.handleResponseFromDialog(e, a, b)}
            />
          </Dialog>
          <Dialog
            open={!!this.props.errorDialog}
            disableBackdropClick={true}
            onClose={this.props.hideErrorDialog}
          >
            <DialogTitle id="error-dialog-title">
              {this.props.errorDialogMessage === 'Form Imported or Not Supported'
                ? ''
                : this.props.errorDialogTitle}
            </DialogTitle>
            <DialogContent id="error-dialog-desc">{this.props.errorDialogMessage}</DialogContent>
            <DialogActions>
              <Button id="btnErrorDialogFormViewer" onClick={this.props.hideErrorDialog}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.showDblBlind}
            aria-labelledby="dblind-dialog-title"
            aria-describedby="dblind-dialog-description"
          >
            <DialogTitle id="dblindTitle">Double entry validation</DialogTitle>
            <DialogContent>
              <DialogContentText>Please reenter data</DialogContentText>
              <TextField
                autoFocus={true}
                margin="dense"
                id="dblindTextField"
                variant="filled"
                fullWidth
                onKeyPress={e => {
                  if (e.key === 'Enter') this.handleDblindOk();
                }}
                onBlur={() => this.handleDblindOk()}
              />
            </DialogContent>
            <DialogActions>
              <div style={styles.buttonGroup}>
                <Button
                  id="dblindOkButton"
                  color="primary"
                  style={styles.leftButton}
                  onClick={this.handleDblindOk}
                  onKeyPress={e => {
                    if (e.key === 'Enter') this.handleDblindOk();
                  }}
                >
                  Ok
                </Button>
                <Button
                  id="dblindCancelButton"
                  color="primary"
                  style={styles.button}
                  onClick={this.handleDblindCancel}
                  onKeyPress={e => {
                    if (e.key === 'Enter') this.handleDblindCancel();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.showConsentForm} disableBackdropClick={true}>
            <DialogTitle id="consent-dialog-title">
              {'Consent to receive tax office alerts via text messages?'}
            </DialogTitle>
            <DialogContent id="consent-dialog-desc">
              Taxpayer must be informed of the following:
              <br />
              • Message frequency varies per tax office.
              <br />
              • Message and data rates may apply.
              <br />
              • Text STOP to opt out or HELP for help.
              <br />
              <div
                className="terms-and-conditions-link"
                onClick={() =>
                  this.setState({
                    isTextLinkPDFOpen: true,
                    textlinkPDF: 'textTermsAndConditionsPDF',
                  })
                }
              >
                Click for Terms and Conditions
              </div>
              <div
                className="terms-and-conditions-link"
                onClick={() =>
                  this.setState({
                    isTextLinkPDFOpen: true,
                    textlinkPDF: 'textingPrivacyPolicyPDF',
                  })
                }
              >
                Click for Privacy Policy
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                id="agreeConsentBtn"
                color="primary"
                onClick={() => {
                  this.hideConsentModal();
                }}
              >
                Agree
              </Button>
              <Button
                id="disagreeConsentBtn"
                color="primary"
                onClick={() => {
                  this.hideConsentModal(true);
                }}
              >
                Disagree
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirmReturnClose}
            disableBackdropClick={true}
            onClose={this.hideConfirmClose}
          >
            <DialogTitle id="confirm-return-close">Close Return</DialogTitle>
            <DialogContent id="confirm-return-close-desc">
              {'Do you want to save changes to the Tax Return?'}
            </DialogContent>
            <DialogActions>
              <Button
                id="btnConfirmReturnClose"
                onClick={() => {
                  this.setState({ confirmReturnClose: false });
                  this.closeAndSaveReturn();
                }}
              >
                Save
              </Button>
              <Button
                id="btnConfirmReturnClose"
                onClick={() => {
                  this.setState({ confirmReturnClose: false });
                  this.closeReturn();
                }}
              >
                Don&apos;t Save
              </Button>
              <Button
                id="btnConfirmReturnClose"
                onClick={() => this.setState({ confirmReturnClose: false })}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirmDeleteReturn}
            disableBackdropClick={true}
            onClose={this.hideConfirmDeleteReturn}
          >
            <DialogTitle id="confirm-delete-return">Delete Return</DialogTitle>
            <DialogContent id="confirm-delete-return-desc">
              {'Are you sure you want to delete this return? This cannot be undone.'}
            </DialogContent>
            <DialogActions>
              <Button
                id="btnYesConfirmDeleteReturn"
                onClick={() => {
                  this.setState({ confirmDeleteReturn: false });
                  this.deleteReturn();
                }}
              >
                Yes
              </Button>
              <Button
                id="btnNoConfirmDeleteReturn"
                onClick={() => {
                  this.setState({ confirmDeleteReturn: false });
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.props.remoteSignSuccessful}
            onEnter={this.stopSpin}
            disableBackdropClick={true}
          >
            <DialogTitle id="confirm-return-close">Remote Signature Request</DialogTitle>
            <DialogContent id="confirm-return-close-desc">
              {'The remote signature request has been sent.'}
            </DialogContent>
            <DialogActions>
              <Button
                id="btnCompletedRemoteSignClose"
                onClick={() => this.props.remoteSignSuccessfulSet(false)}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.showTextLinkSendErrorDialog}
            onClose={() => this.setState({ showTextLinkSendErrorDialog: false })}
            disableBackdropClick={true}
          >
            <DialogTitle>Message Error</DialogTitle>
            <DialogContent>
              {this.state.textlinkErrorMessage && (
                <>
                  <div>{this.state.textlinkErrorMessage}</div>
                  <br></br>
                </>
              )}
              <div>{TEXTLINK_ERROR_MESSAGE}</div>
            </DialogContent>
            <DialogActions>
              <Button
                id="btnTextMessageFail"
                onClick={() => {
                  this.setState({
                    showTextLinkSendErrorDialog: false,
                  });
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={!!this.props.textlinkSendSuccess}
            onClose={() => this.props.toggleTextlinkConfirmSentDlg(false)}
            disableBackdropClick={true}
          >
            <DialogTitle>Message Sent</DialogTitle>
            <DialogContent>The message was sent successfully.</DialogContent>
            <DialogActions>
              <Button
                id="btnTextMessageSucceed"
                onClick={() => {
                  this.props.toggleTextlinkConfirmSentDlg(false);
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <SimpleDialog
            open={this.state.dupeSSNCopyRtnOpen}
            onClose={() => this.setState({ dupeSSNCopyRtnOpen: false })}
            onConfirm={() => {
              this.copyToTraining();
              this.setState({ dupeSSNCopyRtnOpen: false });
            }}
            dialogTitle="Copy To Training"
            contentText="The tax return already exists in the training database. Do you want to overwrite?"
          />
          <Dialog
            open={!!this.props.showVerifySuccessDlg}
            disableBackdropClick={true}
            onClose={() => {
              this.props.toggleVerifyDialog(false);
              this.props.closeErrorRejectModal();
            }}
          >
            <DialogTitle>Verify Success</DialogTitle>
            <DialogContent>{'The return has been verified successfully.'}</DialogContent>
            <DialogActions>
              <Button
                id="btnFormViewerVerifyOK"
                onClick={() => {
                  this.props.toggleVerifyDialog(false);
                  this.props.closeErrorRejectModal();
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <SimpleDialog
            open={this.state.dupeSSNCopyLiveRtnOpen}
            onClose={() => this.setState({ dupeSSNCopyLiveRtnOpen: false })}
            onConfirm={() => {
              this.copyToLive();
              this.setState({ dupeSSNCopyLiveRtnOpen: false });
            }}
            dialogTitle="Copy To Live"
            contentText="The tax return already exists in the live database. Do you want to overwrite?"
          />
          <SimpleDialog
            open={this.state.calcErrorPopup}
            onConfirm={() => {
              this.setState({
                calcErrorPopup: false,
                calcErrorTitle: '',
                calcErrorMsg: '',
              });
            }}
            confirmText="OK"
            dialogTitle={this.state.calcErrorTitle}
            contentText={this.state.calcErrorMsg}
          />
          <SimpleDialog
            open={!!this.props.submitForReviewDlg}
            onClose={() => this.props.toggleSubmitForReviewDlg(false)}
            onConfirm={this.handleConfirmSubmitForReview}
            confirmText="OK"
            dialogTitle={`${readyForReviewType} Return From Review`}
            contentText={`Are you sure you want to ${readyForReviewType} this return from review?`}
          />
          <SimpleDialog
            open={this.state.openPrintDemoMessage}
            onConfirm={() => {
              this.setState({ openPrintDemoMessage: false });
            }}
            confirmText="OK"
            styled={true}
            dialogTitle="Demo Version"
            contentText="You are currently using a demo version of the program. The print options are disabled."
            // eslint-disable-next-line react/no-children-prop
            children="Please contact sales for more information:"
            extendedBody={
              <div>
                <div>
                  <b>Phone: </b>800.345.4337
                </div>
                <div>
                  <b>Fax: </b>209.835.2759
                </div>
                <div>
                  <b>Email: </b>sales@CrossLinkTax.com
                </div>
              </div>
            }
          />
          <SimpleDialog
            open={this.state.openTVADisallow}
            onConfirm={() => {
              this.setState({ openTVADisallow: false });
            }}
            confirmText="OK"
            dialogTitle="Tax Vision Assistant"
            contentText="The Tax Vision Assist service is not available for your account."
          />
          {/* TODO -> */}
          {/* <SimpleDialog
        open={this.state.walletMessage}
        onConfirm={() => {
          this.gotoWallet();
        }}
        onClose={() => {
          this.setState({ walletMessage: false });
        }}
        confirmText="Go To Wallet"
        styled={true}
        dialogTitle="Insufficient Funds"
        contentText="Insufficient wallet funds. Would you like to open your wallet? Your return will remain open."
      /> */}
          <Dialog
            id="mdlEmailEncryptedPdfSaveDialog"
            aria-labelledby="encrypted-pdf-save-modal-title"
            aria-describedby="encrypted-pdf-save-modal-description"
            disableBackdropClick={true}
            open={this.props.suggestedPswSaveDialog}
            onClose={() => this.props.toggleSaveSuggestedPassword(false)}
            PaperProps={{ tabIndex: -1 }}
            disableAutoFocus={true}
          >
            <Paper elevation={5}>
              <SuggestedPasswordSaveDialog
                handleClose={() => this.props.toggleSaveSuggestedPassword(false)}
                handleSaveSuggestedPassword={() => this.handleSaveSuggestedPassword()}
              />
            </Paper>
          </Dialog>
          <Dialog
            id="mdlEmailEncryptedPdfDialog"
            aria-labelledby="encrypted-pdf-modal-title"
            aria-describedby="encrypted-pdf-modal-description"
            disableBackdropClick={true}
            open={this.props.suggestedPswDialog}
            onClose={() => this.props.toggleSuggestedPassword(false)}
            PaperProps={{ tabIndex: -1 }}
            disableAutoFocus={true}
          >
            <Paper elevation={5}>
              <SuggestedPasswordDialog
                encryptedPwd={
                  this.props.encryptedPassword !== undefined ? this.props.encryptedPassword : ''
                }
                encryptedTitle={
                  this.props.encryptedPdfTitle !== undefined ? this.props.encryptedPdfTitle : ''
                }
                taxpayerEmail={
                  this.props.taxpayerEmailForPdf !== undefined ? this.props.taxpayerEmailForPdf : ''
                }
                tpCellPhoneNumber={
                  this.props.taxpayerCellPhoneNumber !== undefined
                    ? this.props.taxpayerCellPhoneNumber
                    : ''
                }
                tpCellPhoneDomain={
                  this.props.taxpayerCellPhoneDomain !== undefined
                    ? this.props.taxpayerCellPhoneDomain
                    : ''
                }
                tpCellPhoneCarrier={
                  this.props.taxpayerCellPhoneCarrier !== undefined
                    ? this.props.taxpayerCellPhoneCarrier
                    : ''
                }
                tpAllowText={
                  this.props.taxpayerAllowText !== undefined ? this.props.taxpayerAllowText : ''
                }
                spAllowText={
                  this.props.spouseAllowText !== undefined ? this.props.spouseAllowText : ''
                }
                spouseEmail={
                  this.props.spouseEmailForPdf !== undefined ? this.props.spouseEmailForPdf : ''
                }
                spCellPhoneNumber={
                  this.props.spouseCellPhoneNumber !== undefined
                    ? this.props.spouseCellPhoneNumber
                    : ''
                }
                spCellPhoneCarrier={
                  this.props.spouseCellPhoneCarrier !== undefined
                    ? this.props.spouseCellPhoneCarrier
                    : ''
                }
                spCellPhoneDomain={
                  this.props.spouseCellPhoneDomain !== undefined
                    ? this.props.spouseCellPhoneDomain
                    : ''
                }
                showSpouseOption={
                  this.props.spouseInfoAvailable !== undefined
                    ? this.props.spouseInfoAvailable
                    : false
                }
                preparerEmail={
                  this.props.preparerEmailForPdf !== undefined ? this.props.preparerEmailForPdf : ''
                }
                isTextLinkOn={
                  this.props.isTextLinkActivated !== undefined
                    ? this.props.isTextLinkActivated
                    : false
                }
                handleClose={() => this.props.toggleSuggestedPassword(false)}
                handleOptionNotAvailable={() => {
                  this.props.toggleSuggestedPassword(false);
                  this.props.toggleMissingInfoModal(true);
                }}
                setEncryptedPassword={(
                  newEncryptedPassword,
                  encryptedPdfTitle,
                  taxpayerSelected,
                  spouseSelected,
                  representativeSelected,
                  taxpayerSelectedEPass,
                  spouseSelectedEPass,
                  representativeSelectedEPass,
                  taxpayerSelectedText,
                  spouseSelectedText,
                  representativeSelectedText,
                ) =>
                  this.handleNewEncryptedPassword(
                    newEncryptedPassword,
                    encryptedPdfTitle,
                    taxpayerSelected,
                    spouseSelected,
                    representativeSelected,
                    taxpayerSelectedEPass,
                    spouseSelectedEPass,
                    representativeSelectedEPass,
                    taxpayerSelectedText,
                    spouseSelectedText,
                    representativeSelectedText,
                  )
                }
              />
            </Paper>
          </Dialog>
          <Dialog
            id="mdlEmailEncryptedPdfSaveDialog"
            aria-labelledby="encrypted-pdf-send-modal-title"
            aria-describedby="encrypted-pdf-send-modal-description"
            disableBackdropClick={true}
            open={this.props.encryptedPdfDialogModalOpen}
            onClose={() => this.props.toggleEncryptedPdf(false)}
            PaperProps={{ tabIndex: -1 }}
            disableAutoFocus={true}
          >
            <Paper elevation={5}>
              <SuggestedPasswordDoc
                handleClose={() => {
                  this.props.toggleEncryptedPdf(false);
                  this.setState({ showFinalPDF: true });
                }}
                handleSaveSuggestedPasswordDoc={() => this.handleSaveSuggestedPasswordDoc()}
                htmlPreview={this.props.emailBlob}
                isOpen={this.props.encryptedPdfDialogModalOpen}
              />
            </Paper>
          </Dialog>
          <PreviewTermsAndConditions
            isPDFOpen={!!this.state.isTextLinkPDFOpen}
            toggleModal={() =>
              this.setState({
                isTextLinkPDFOpen: false,
                textlinkPDF: '',
              })
            }
            textlinkPDF={this.state.textlinkPDF}
          />
          <Snackbar
            open={this.state.promptSnackbar}
            onClose={() => this.hidePromptSnackbar()}
            ContentProps={{
              'aria-describedby': 'prompt-msg',
              className: 'snackbar-prompt',
            }}
            message={<div id="prompt-msg">{this.state.promptSnackbarMessage}</div>}
          />
          <Snackbar
            open={this.state.notesAlert}
            onClose={() =>
              this.setState({
                notesAlert: false,
                notesFlag: false,
              })
            }
            ContentProps={{
              'aria-describedby': 'prompt-msg',
              className: 'snackbar-info',
            }}
            message={<div id="prompt-msg">{NOTES_REMINDER}</div>}
          />
          <SimpleDialog
            open={this.state.addInterviewModalFlag}
            onConfirm={() => this.addInterviewConfirm()}
            confirmText="Confirm"
            onClose={() => this.addInterviewCancel()}
            styled={true}
            dialogTitle="Confirm Inteview Forms"
            contentText="Please confirm you want to add interview forms to the return. Otherwise, click cancel."
          />
        </div>

        <RefreshToken socketWorker={this.socketWorker} />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormViewer)),
);
