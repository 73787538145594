import React, { useEffect } from 'react';
// Internal imports
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import instructionsIcon from '~/images/icons/icons8-user_manual.png';
import AddDocumentModal from '~/app/Pages/Returns/components/Document/ArchivedDocuments/Modals/AddDocumentModal.jsx';
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import {
  Divider,
  Input,
  FormControl,
  FormHelperText,
  withStyles,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
// Styling imports
import '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.css'; // Reuse available styling
import { styles } from '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.js'; // Reuse available styling

/**
 * This component renders the Vision Assist Modal, works similar to Attach Document Modal
 *
 * @component VisionAssistModal
 * @category FormViewer
 **/

const VisionAssistModal = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    image: null,
    imageName: '',
    imageDescription: '',
    imageType: props.TVAargType,
    isAddDocumentModalOpen: false,
    isRunningScan: false,
    buttonID: props.buttonID, // for use in case we want to know where the request orignated from, currently unused
    progressMessage: 'Upload Document     ',
  });

  useEffect(() => {
    let progressMessage;
    switch (props.TVAprogress) {
      case 25:
        progressMessage = 'Uploading Document...';
        break;
      case 50:
        progressMessage = 'Analyzing Document...';
        break;
      case 75:
        progressMessage = 'Writing Information to Return...';
        break;
      case 100:
        progressMessage = 'Scan Complete!';
        break;
      default:
        progressMessage = 'Upload Document...';
    }
    setState({
      progressMessage,
    });
  }, [props.TVAprogress]);

  const instructions = `Thank you for using Tax Vision Assist (Beta).  
  When utilizing Tax Vision Assist please make sure images are oriented correctly and that only one ID or form is included in uploaded images.  This will yield the best results.  
  Always double check import results and validate tax return information after Tax Vision Assist document import.`;

  const setImage = imageItem => {
    setState({
      image: imageItem.image,
      imageName: imageItem.image_name,
      imageDescription: imageItem.image_description,
    });
  };

  /**
   * For passing image to parent FormViewer where runVisionAssist scans image
   *
   */
  const handleScan = async () => {
    if (state.imageType === -1 || state.image === null) {
      props.showSnackBarErr('An image must be uploaded and a document type must be selected.');
      return;
    }

    await props.runTVASend(
      state.image,
      state.imageName,
      state.imageDescription.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      state.imageType,
    );

    setState({
      isRunningScan: true,
    });
  };

  /** Handles opening/closing the add document modal */
  const toggleAddDocumentModal = () => {
    setState({ isAddDocumentModalOpen: !state.isAddDocumentModalOpen });
  };

  return (
    <>
      <AddDocumentModal
        showSnackBarErr={props.showSnackBarErr}
        isAddDocumentModalOpen={state.isAddDocumentModalOpen}
        toggleAddDocumentModal={toggleAddDocumentModal}
        isVisionAssist={true}
        setImage={setImage}
      />
      <Typography
        variant="body2"
        id="simple-modal-title"
        classes={{ root: classes.styledTypography }}
      >
        <span className="attach-doc-header">Vision Assist</span>
      </Typography>
      <div className="attach-doc-div-one">
        <div className="attach-doc-div-two">
          <Grid container>
            {state.isRunningScan && (
              <Grid
                container
                spacing={8}
                alignContent="flex-start"
                justify="flex-start"
                alignItems="baseline"
                className="tva-progress-bar"
              >
                <Grid container className="tva-progress-header">
                  <Grid item>
                    <Typography variant="body1">{state.progressMessage}</Typography>
                  </Grid>
                  {props.TVAprogress !== 100 && (
                    <Grid item className="tva-progress-wheel ">
                      <CircularProgress size={20} />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress variant="determinate" value={props.TVAprogress} />
                </Grid>
              </Grid>
            )}
            {!state.isRunningScan && (
              <Grid item xs={9} classes={{ item: classes.instructionsContainer }}>
                <div className="attach-doc-instructions-img-container">
                  <img
                    id="instructionsIcon"
                    className="attach-doc-instructions-img"
                    src={instructionsIcon}
                  />
                </div>
                <div className="attach-doc-instructions-text-container">
                  <Typography
                    classes={{ root: classes.instructionsTitle }}
                    variant="title"
                    gutterBottom
                  >
                    Instructions:
                  </Typography>
                  <Typography classes={{ root: classes.instructions }} variant="body1">
                    {instructions}
                  </Typography>
                </div>
              </Grid>
            )}

            {!state.isRunningScan && (
              <>
                <Grid item xs={9} className={classes.styledFlexGrid}>
                  <Typography classes={{ root: classes.docTitle }} variant="title" gutterBottom>
                    Attach Image
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.styledFlexGrid}>
                  <Grid container justify="flex-end">
                    <Button
                      id="btnOpenAttachModalDocArchive"
                      size="medium"
                      color="primary"
                      classes={{ root: classes.addDocumentBtn }}
                      onClick={toggleAddDocumentModal}
                    >
                      Add Image
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.styledGridWithPadding}>
                  <Grid container justify="center">
                    <FormControl
                      fullWidth={true}
                      classes={{ root: classes.styledFormControlMarginBottom }}
                    >
                      <FormControl classes={{ root: classes.styledFormControl }}>
                        <Input
                          disableUnderline
                          disabled
                          placeholder="Upload Image"
                          classes={{ root: classes.styledInput }}
                          value={state.imageName}
                        />
                      </FormControl>
                      <FormHelperText>
                        Only Image files can be scanned via Vision Assist
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  classes={{
                    item: classes.styledFlexGridDivider,
                  }}
                >
                  <Grid container justify="flex-start">
                    <Divider
                      classes={{
                        root: classes.documentDivider,
                      }}
                      variant="fullWidth"
                      light={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={9} className={classes.styledFlexGrid}>
                  <Typography classes={{ root: classes.docTitle }} variant="title" gutterBottom>
                    Document Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={8}
                  alignContent="flex-start"
                  justify="flex-start"
                  alignItems="baseline"
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={state.imageType}
                      onChange={e => {
                        setState({ imageType: e.target.value });
                      }}
                    >
                      {/* FormControlLabels would be better created in a function but they do not work correctly when doing so */}
                      <FormControlLabel value="1" control={<Radio />} label="Taxpayer ID" />
                      <FormControlLabel value="2" control={<Radio />} label="Spouse ID" />
                      <FormControlLabel value="3" control={<Radio />} label="TaxPayer W-2" />
                      <FormControlLabel value="4" control={<Radio />} label="Spouse W-2" />
                      <FormControlLabel value="5" control={<Radio />} label="TaxPayer 1099k" />
                      <FormControlLabel value="6" control={<Radio />} label="Spouse 1099k" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  classes={{
                    item: classes.styledFlexGridDivider,
                  }}
                >
                  <Grid container justify="flex-start">
                    <Divider
                      classes={{
                        root: classes.documentDivider,
                      }}
                      variant="fullWidth"
                      light={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} classes={{ item: classes.styledGridWithMargin }}>
                  <Grid container justify="flex-end">
                    <div className="attach-doc-div-four">
                      <Button
                        classes={{ root: classes.styleButtonMarginRight }}
                        size="small"
                        color="default"
                        onClick={props.handleCloseVisionModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        id="btnAttachAddDocModal"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => handleScan()}
                      >
                        Scan
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(VisionAssistModal);
