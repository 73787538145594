// Internal imports
import {
  HIERARCHY_TYPE,
  LOGIN_SETTINGS,
  DASHBOARD_TITLE_TABS,
  LATEST_SEASON,
} from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers from '~/app/webHelpers.js';
// Redux imports
import {
  actions,
  FETCH_LOGINS_LIST,
  FETCH_ACCESSLEVEL_LIST,
  FETCH_LINKABLE_PREPARERS,
  FETCH_ACCESSROLE_LIST,
  LOGINMANAGEMENT_TOGGLE_TRAINING_MODE,
  FETCH_LOGIN_SETTINGS,
  UPDATE_LOGIN_SETTINGS,
  ON_FINALIZE_LOGIN,
  FETCH_MOBILE_APP_INTEGRATION,
  GENERATE_MOBILE_APP_ID,
  IMPERSONATE_LOGIN,
} from './duck';
import { actions as appActions } from '~/app/redux/modules/app.js';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck.js';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck.js';
import { actions as overviewActions } from '~/app/redux/modules/overview.js';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck.js';
import { takeLatest, call, put } from 'redux-saga/effects';

export function* watchLoginSetup() {
  yield takeLatest(FETCH_LOGINS_LIST, fetchLogins);
  yield takeLatest(FETCH_ACCESSLEVEL_LIST, fetchAccessLevels);
  yield takeLatest(FETCH_ACCESSROLE_LIST, fetchAccessRoles);
  yield takeLatest(FETCH_LINKABLE_PREPARERS, fetchLinkablePreparers);
  yield takeLatest(LOGINMANAGEMENT_TOGGLE_TRAINING_MODE, toggleTrainingMode);
  yield takeLatest(FETCH_LOGIN_SETTINGS, fetchLoginSettings);
  yield takeLatest(UPDATE_LOGIN_SETTINGS, updateLoginSettings);
  yield takeLatest(FETCH_MOBILE_APP_INTEGRATION, fetchMobileAppIntegration);
  yield takeLatest(GENERATE_MOBILE_APP_ID, generateMobileAppID);
  yield takeLatest(ON_FINALIZE_LOGIN, finalizeLogin);
  yield takeLatest(IMPERSONATE_LOGIN, impersonateLogin);
}

export function* fetchLogins(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${XLINK_API_URL}/logins`;
    }

    if (payload.limit === undefined || payload.offset === undefined) {
      throw new Error('Missing page parameters');
    }

    const res = yield call(
      XlinkAPI.simplePagedRequest,
      payload.endpoint,
      payload.limit,
      payload.offset,
      {
        login_id: payload.loginID,
        loginNameFilter: payload.loginNameFilter,
      },
    );

    let page = 0;
    if (payload.offset !== 0) {
      page = payload.offset / payload.limit;
    }

    yield put(actions.setLoginsPage(page));
    yield put(actions.onFetchLoginsSuccess(res.data));
  } catch (error) {
    yield put(actions.generalError(error));
    ErrorHelpers.handleError('Error fetching logins', error);
  }
}

export function* fetchLinkablePreparers(payload) {
  try {
    const res = yield call(
      XlinkAPI.simpleGetRequest,
      `${XLINK_API_URL}/hierarchy/preparer/linkable/${payload.loginID}`,
    );
    yield put(actions.onFetchLinkablePreparersSuccess(res.data));
  } catch (error) {
    yield put(actions.generalError());
    ErrorHelpers.handleError('Error fetching linkable preparers', error);
  }
}

export function* fetchAccessLevels(payload) {
  try {
    const res = yield call(XlinkAPI.simplePagedRequest, `${BASE_URL}/authz/accessroles`, {
      role_id: payload.role_id,
    });
    yield put(actions.onFetchAccessLevelsSuccess(res.data));
  } catch (error) {
    yield put(actions.generalError());
    ErrorHelpers.handleError('Error fetching accessGroups', error);
  }
}

export function* fetchAccessRoles(payload) {
  try {
    const res = yield call(XlinkAPI.simplePostRequest, `${BASE_URL}/authz/accessroles`, {
      role_id: payload.role_id,
    });
    yield put(actions.onFetchAccessRolesSuccess(res.data));
  } catch (error) {
    yield put(actions.generalError());
    ErrorHelpers.handleError('Error fetching access roles', error);
  }
}

export function* toggleTrainingMode() {
  try {
    yield call(AuthAPI.toggleTrainingMode);
    yield call(finalizeLogin);
  } catch (error) {
    yield put(actions.generalError());
    const errorMessage = error?.response?.data?.error_message;
    if (errorMessage) {
      yield put(
        appActions.showSnackbarMessage(errorMessage, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
    ErrorHelpers.handleError('Error toggling training mode', error);
  }
}

export function* impersonateLogin(payload) {
  try {
    if (payload.identifier === '') {
      yield put(appActions.toggleImpersonation(false));
    }
    yield call(AuthAPI.impersonateLogin, payload.identifier);
    yield put(loginSetupActions.setFinalizeLogin(true));
    yield call(finalizeLogin);
  } catch (error) {
    ErrorHelpers.handleError('Unable to impersonate login', error);
  }
}

export function* finalizeLogin(payload) {
  try {
    yield put(loginSetupActions.setAuthenticationFlag(true));
    yield put(drilldownActions.clearDrilldownHistory());
    let displayName = '';
    let email = '';
    let setupComplete = false;
    let setupProgress = 0;

    if (!payload || !payload.login_id) payload = WebHelpers.getJWTPayload();

    let res = yield call(XlinkAPI.getUserProfile, payload.login_id);

    if (payload.impersonating) {
      yield put(appActions.toggleImpersonation(true));
    }
    displayName = res.data.display_name;
    email = res.data.email;
    setupComplete = res.data.is_office_setup_complete;
    setupProgress = res.data.setup_progress;
    yield put(actions.setActiveAccessLevels(res.data.active_permissions));
    yield put(actions.setTrainingMode(payload.training_returns_only));
    yield put(appActions.setAvatar(res.data.avatar));

    const showIRSFlag = sessionStorage.getItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE);
    yield put(actions.fetchLoginSettings(showIRSFlag));

    yield put(actions.fetchMobileAppIntegration());

    const messageCount = yield call(XlinkAPI.getUnreadMessagesCount);
    yield put(overviewActions.setMessageBadgeCount(messageCount.data));

    yield put(appActions.setTopMostParentRole(res.data.TopMostParentRole));

    yield put(cobrandActions.fetchLogo());

    // OFFICE LEVEL ONLY - We need to check the payload season that is coming in, if it does not match the current season, this means they do not own the latest season and should purchase a license for it.
    if (payload?.season !== LATEST_SEASON) {
      yield put(actions.setShowNewSeasonReminder(true));
    }

    // Set status of training mode based on what is coming from the payload. We should have already received an updated payload at this point.

    // TODO --> This should be removed once we start pinging our open websocket connections.
    // safeguard to keep returns from being locked after severe errors
    yield call(XlinkAPI.clearMyLocks);
    const primaryRole = payload.hierarchy_type_id;

    switch (primaryRole) {
      case HIERARCHY_TYPE.PREPARER:
        // if we're a preparer without an active office, choose one
        if (!payload.preparer_info.active_efin_id) {
          // are we linked to ANY office?
          if (!payload.preparer_info.offices || payload.preparer_info.offices.length === 0) {
            yield put(appActions.onLogout());
            ErrorHelpers.handleError(
              'Error selecting office',
              'No offices are linked to your login, please contact support',
            );
            return;
          }
          yield put(appActions.setAuthStagePickOffice());
          return;
        }
        yield put(
          appActions.onLogin({
            first_name: payload.preparer_info.preparer_name,
            last_name: '',
            title: payload.roles !== undefined ? payload.roles[0].access_friendly_role_name : '',
            login_id: payload.login_id,
            display_name: displayName,
            email,
          }),
        );
        yield put(
          drilldownActions.setCurrentView({
            loginID: payload.login_id,
            preparerID: payload.preparer_info.preparer_id,
            role: primaryRole,
            name: payload.preparer_info.preparer_name,
            isOfficeSetupComplete: setupComplete,
          }),
        );
        return;
      default:
        // TODO: this is the old way, WIP to transition
        res = yield call(XlinkAPI.getEntityName, payload.login_id, payload.hierarchy_type_id);

        yield put(
          appActions.onLogin({
            first_name: payload.identifier,
            last_name: payload.login_id,
            title: payload.roles !== undefined ? payload.roles[0].access_friendly_role_name : '',
            login_id: payload.login_id,
            display_name: displayName,
            email,
          }),
        );

        yield put(appActions.onSelectTitleTab(DASHBOARD_TITLE_TABS.OVERVIEW));
        // reset title tabs on refresh and year switch
        yield put(
          drilldownActions.setCurrentView({
            loginID: payload.login_id,
            role: primaryRole,
            name: res.data,
            isOfficeSetupComplete: setupComplete,
            setupProgress,
            preparerID: payload.preparer_info?.preparer_id,
          }),
        );
    }
  } catch (err) {
    yield put(actions.generalError());
    ErrorHelpers.handleError('Fetch Error', err);
  } finally {
    // We've finished the authentication request.
    yield put(loginSetupActions.setAuthenticationFlag(false));
  }
}

export function* fetchReturnProfileAsync(payload) {
  try {
    yield put(actions.requestReturnProfile());

    const res = yield call(XlinkAPI.fetchReturnProfile, payload.returnID);
    const lockOwner = yield call(XlinkAPI.whoHasReturnLock, payload.returnID);

    if (
      lockOwner.data &&
      lockOwner.data.identifier &&
      lockOwner.data.identifier !== '' &&
      lockOwner.data.lockedByLoginID > 0
    ) {
      yield put(actions.lockReturn(lockOwner.data.identifier, lockOwner.data.lockedByLoginID));
    }

    yield put(actions.requestReturnProfileSuccess(res.data.arg.NavPanelItems, res.data.arg.notes));
  } catch (error) {
    ErrorHelpers.handleError('Error fetching return profile', error);
    yield put(actions.requestReturnProfileError());
  }
}

export function* fetchLoginSettings(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/account/settings/${payload.IRSFlag}`;
    const res = yield call(XlinkAPI.simpleGetRequest, endpoint);
    if (res.data !== null) {
      yield put(actions.setLoginSettings(res.data));
    }
  } catch (error) {
    ErrorHelpers.handleError('Unable to load login settings', error);
  }
}

export function* updateLoginSettings(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/account/settings/UpdateAccountLoginSettings`;
    yield call(XlinkAPI.simplePostRequest, endpoint, {
      loginSettings: payload.settings,
    });
  } catch (error) {
    ErrorHelpers.handleError('Unable to save login settings', error);
  }
}

export function* fetchMobileAppIntegration(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/mobile/integration`;
    const res = yield call(XlinkAPI.simpleGetRequest, endpoint);
    if (res.data !== null) {
      const mobileAppID = res.data.mobileID;
      const mobileAppQRCode = res.data.QRCode;
      const mobileAppEmail = res.data.email;
      yield put(actions.setMobileAppIntegration(mobileAppID, mobileAppQRCode, mobileAppEmail));
    }
  } catch (error) {
    ErrorHelpers.handleError('Unable to fetch TaxPass Site ID', error);
  }
}

export function* generateMobileAppID(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/mobile/integration/id`;
    const res = yield call(XlinkAPI.post, endpoint);
    if (res.data !== null) {
      const mobileAppID = res.data.mobileID;
      const mobileAppQRCode = res.data.QRCode;
      yield put(actions.setMobileAppID(mobileAppID, mobileAppQRCode));
    }
  } catch (error) {
    ErrorHelpers.handleError('Unable to generate TaxPass Site ID', error);
  }
}
