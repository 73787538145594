/**
 * Setup the multi-office data object for passing to PagedTable component.
 */
export const setupLicenseDataForPagedTable = moLicensingSetup => {
  if (Object.keys(moLicensingSetup).length) {
    const moData = [];

    moLicensingSetup.map((mo, index) => {
      const moInfo = {};

      const availableToGen =
        mo.licenses_available_to_generate < 0 ? 0 : mo.licenses_available_to_generate;

      console.log('mo: ', mo);

      mo.id = index;
      moInfo.data = mo;
      moInfo.columns = [
        mo.firmName,
        mo.licenses_able_to_generate,
        availableToGen,
        mo.licenses_generated,
        mo.licenses_consumed,
      ];
      moData.push(moInfo);
      return mo;
    });

    return moData;
  }
};

export const checkLicensingSetupData = (licensingSetup, selectedKey) => {
  if (licensingSetup && licensingSetup.licenseInfo && licensingSetup.licenseInfo[selectedKey]) {
    return licensingSetup.licenseInfo[selectedKey];
  } else {
    return { licenseID: '', season: '', officeID: 0 };
  }
};

export const licenseUsed = licensingSetup => {
  let licenseMsg;
  if (
    licensingSetup &&
    Number.isInteger(licensingSetup?.applied) &&
    Number.isInteger(licensingSetup?.unapplied)
  ) {
    licenseMsg = `${licensingSetup.applied} out of
    ${licensingSetup.applied + licensingSetup.unapplied}
    Licenses Used`;
  } else {
    licenseMsg = 'Licenses Used';
  }
  return licenseMsg;
};

export const getRemoveText = (licensingSetup, selectedKey, canRemove1040License) => {
  let text = '';

  if (!canRemove1040License) {
    text = `Cannot remove license ${licensingSetup.licenseInfo[selectedKey].licenseID} from
    ${licensingSetup.licenseInfo[selectedKey].officeName}. A business license is assigned to this office.
    `;
  } else if (licensingSetup?.licenseInfo[selectedKey]) {
    text = `Remove License ${licensingSetup.licenseInfo[selectedKey].licenseID} from
  ${licensingSetup.licenseInfo[selectedKey].officeName}?
  `;
  }
  return text;
};

// helper constants for labeling license type
export const INDIVIDUAL = 'INDIVIDUAL';
export const BUSINESS = 'BUSINESS';
