export const styles = {
  pagedTableBodyCell: {
    whiteSpace: 'nowrap',
  },
  gridColumnHeader: {
    width: '12%',
    color: '#1A173B',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
  },
  gridColumnData: {
    width: '12%',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  gridTableHeader: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    paddingTop: '25px',
    paddingBottom: '15px',
    marginLeft: '15px',
  },
  paperTitle: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.14px',
    color: '#1A173B',
  },
  gridDivider: {
    paddingTop: '10px',
  },
  divider: {
    marginBottom: '1.5rem',
  },
  gridSubTable: {
    padding: '15px',
  },
  parentGridContainer: {
    marginBottom: '1rem',
  },
  footerGridContainer: {
    marginTop: '50px',
  },
  tableDivider: {
    paddingBottom: '10px',
  },
  modalDivider: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  footerDivider: {
    paddingBottom: '10px',
  },
  footerDividerMO: {
    paddingBottom: '10px',
    paddingRight: '10px',
  },
  activeBubbleText: {
    borderRadius: '25px',
    background: '#A6EBC2',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '7px',
    paddingRight: '7px',
    opacity: '35%',
  },
  unUsedBubbleText: {
    borderRadius: '25px',
    background: '#FFE06E',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '7px',
    paddingRight: '7px',
    opacity: '35%',
  },
  editButton: {
    height: '30px',
    width: '70px',
    position: 'relative',
    top: '-10px',
  },
  settingsModals: {
    width: '600px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  multiOfficeManagementModal: {
    width: '400px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  moContainer: {
    padding: '10px',
  },
  closeIconModal: {
    position: 'relative',
    left: '400px',
    color: '#FFFFFF',
  },
  officeDropdown: {
    width: '100%',
    paddingBottom: '2rem',
  },
  licenseDropdown: {
    width: '100%',
  },
  hundredWidth: { width: '100%' },
  justifyRight: {
    textAlign: 'right',
    paddingRight: '10px',
  },
  manageLicenseCreateLicenseBtnGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalHeaderBar: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '18px',
    paddingTop: '2%',
    paddingLeft: '4%',
  },
};
