// External imports
import React from 'react';
import { Button, Divider, Grid, Paper } from '@material-ui/core';
import {
  BUSINESS_SETUP_PAGES,
  EXTENDED_SETUP_PAGES,
  REQUIRED_SETUP_PAGES,
} from '~/app/constants.js';
import WebHelpers from '~/app/webHelpers.js';
import { checkOfficeLicense, canAccessCorp, determineProductCodeType } from '~/app/Utility/general';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import { styles } from '~/app/Pages/Setup/LicencingSetup/css/licencingSetup.js';

const LicencingSetup = props => {
  const payload = WebHelpers.getJWTPayload();
  const { officeProfile } = useSelector(state => ({
    officeProfile: state.officeProfile,
  }));

  const setupInhertedLicense = (license, season, licenseType = '', index) => {
    let statusStyle = styles.inActiveBubbleText;
    let statusText = 'InActive';

    if (license.status) {
      statusStyle = styles.activeBubbleText;
      statusText = 'Active';
    }

    const corpProductCodeType = determineProductCodeType(license.productCode);

    return (
      <React.Fragment key={index}>
        <Grid item xs={2} style={styles.gridColumnData}>
          {season}
        </Grid>
        <Grid item xs={4} style={styles.gridColumnData}>
          {license.licenseID}
        </Grid>
        <Grid item xs={3} style={styles.gridColumnData}>
          {`${licenseType || license.productCode} ${corpProductCodeType}`}
        </Grid>
        <Grid item xs={3} style={styles.gridColumnData}>
          <span style={statusStyle}>{statusText}</span>
        </Grid>
      </React.Fragment>
    );
  };

  // createLicenseTable - return the sub table based on data passed in to props.licencingSetup
  const createLicenseTable = () => {
    if (!props.licencingSetup) {
      return;
    }

    const table = [];
    props.licencingSetup.map((license, index) => {
      table.push(setupInhertedLicense(license, license.season, '', index));
      return table;
    });

    if (officeProfile.has1040License || officeProfile.hasSubsequentLicense) {
      if (officeProfile) {
        const licenseObj = checkOfficeLicense(officeProfile);
        officeProfile.oldestLicenseSeason = licenseObj.oldestLicenseSeason;
      }

      // For selected Tax Year where we provide 2 previous season,
      // this display the oldest license purchase indicating which season
      // with the license type of inherited indicating which season
      // this free tax year is referencing the license from.
      if (payload.season < officeProfile.oldestLicenseSeason) {
        officeProfile.licenseInfo.map((license, index) => {
          // Check for oldest license first before creating the display for the
          // prevous free season inherited license.
          if (license.season === officeProfile.oldestLicenseSeason) {
            table.push(setupInhertedLicense(license, license.season, 'Inherited', index));
          }
          return table;
        });
      }
      // We may have a season for the office where they did not have a license for
      // and it will need to have an inherited license.
      //
      // Ex:  office 123 has license for (23, 22 and 20) but does not have for season 21
      // In this case, season 21 license would be an inherited license
      else if (!props.licencingSetup.length) {
        officeProfile.licenseInfo.map((license, index) => {
          // We check the current season where the office does not have a license and
          // add 1 to see if it equals a license the office has. If there is match,
          // the current season needs to have an inherited license.
          if (license.season === payload.season + 1) {
            table.push(setupInhertedLicense(license, payload.season, 'Inherited', index));
          }
          return table;
        });
      }
    }

    return table;
  };

  const handleNext = () => {
    let page = EXTENDED_SETUP_PAGES.EFILING.NAME;

    if (canAccessCorp(payload, officeProfile, props.currentView)) {
      page = BUSINESS_SETUP_PAGES.CLIENT_LETTERS.NAME;
    }
    props.loadSetupComponent(page);
  };

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Paper>
        <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
          <div
            style={{
              textAlign: 'left',
              paddingTop: '3vh',
              paddingBottom: '1vh',
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid container spacing={8} style={styles.parentGridContainer}>
              <Grid item xs={12} style={styles.paperTitle}>
                Office Licenses
              </Grid>
              <Grid item xs={12} style={styles.gridDivider}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={styles.gridTableHeader}>
                License History
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={8} style={styles.gridSubTable}>
                  <Grid item xs={2} style={styles.gridColumnHeader}>
                    SEASON
                  </Grid>
                  <Grid item xs={4} style={styles.gridColumnHeader}>
                    LICENSE ID
                  </Grid>
                  <Grid item xs={3} style={styles.gridColumnHeader}>
                    LICENSE TYPE
                  </Grid>
                  <Grid item xs={3} style={styles.gridColumnHeader}>
                    STATUS
                  </Grid>
                  <Grid item xs={12} style={styles.tableDivider}>
                    <Divider />
                  </Grid>

                  {/* Loop over the data */}
                  {createLicenseTable()}
                </Grid>
              </Grid>

              {/* Bottom Previous and Next */}
              <Grid item xs={12}>
                <Grid container spacing={8} style={styles.footerGridContainer}>
                  <Grid item xs={12} style={styles.footerDivider}>
                    <Divider />
                  </Grid>
                  <Grid item xs={10}>
                    {/** Just a spacer to push the buttons right */}
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      id="btnLicencingSetupPrev"
                      color="primary"
                      onClick={() => props.loadSetupComponent(REQUIRED_SETUP_PAGES.BILLING.NAME)}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button id="btnLicencingSetupNext" color="primary" onClick={handleNext}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default LicencingSetup;
