export const styles = {
  instructionsTitle: {
    display: 'inline',
    marginTop: '1em',
    fontSize: '0.9em',
    cursor: 'default',
  },
  instructions: {
    display: 'inline',
    marginTop: '1em',
    paddingLeft: '0.3em',
    fontSize: '0.73em',
    cursor: 'default',
    fontWeight: 'lighter',
  },
  instructionsContainer: {
    backgroundColor: '#EBEDF8',
    borderRadius: '5px',
    marginTop: '2em',
    marginBottom: '1em',
    maxWidth: '100%',
    flexBasis: '100%',
    padding: '0.7em',
  },
  docTitle: {
    color: '#1A173B',
    marginTop: '1em',
    fontWeight: 'normal',
  },
  documentDivider: {
    width: '100%',
    marginLeft: '0',
    marginTop: '1em',
  },
  styledFlexGridDivider: {
    maxWidth: '47.5%',
    flexBasis: '47.666667%',
  },
  styledFlexGridImage: {
    maxWidth: '16.666667%',
    flexBasis: '3.666667%',
  },

  docTranspBtn: {
    color: '#0077FF',
  },
  docUploadInp: {
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
  docBodyAttachColor: {
    color: '#354052',
  },
  docIconClearBtn: {
    background: 'rgba(0,0,0,0.0)',
    '&:hover': {
      background: 'rgba(0,0,0,0.0)',
    },
    '&:focus': {
      border: '0',
      outline: '0',
      boxShadow: 'none',
    },
  },
  docIconClearBtnWithMargin: {
    marginRight: '-0.2em',
    background: 'rgba(0,0,0,0.0)',
    '&:hover': {
      background: 'rgba(0,0,0,0.0)',
    },
    '&:focus': {
      border: '0',
      outline: '0',
      boxShadow: 'none',
    },
  },
  addDocumentModal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'auto',
    width: '860px',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  addDocumentBtn: {
    marginTop: '1em',
    border: 'none',
    fontSize: '15px',
    fontWeight: 'normal',
  },
  addDocumentBtnOne: {
    marginTop: '1em',
    marginRight: '1em',
    border: 'none',
    fontSize: '15px',
    fontWeight: 'normal',
  },
  styledFlexGrid: {
    display: 'flex',
  },
  styledFormControl: {
    width: '100%',
    textAlign: 'left',
    fontSize: '14px',
  },
  styledFormLabelOne: {
    color: '#354052',
    textAlign: 'left',
  },
  styledFormLabelTwo: {
    textAlign: 'left',
    color: '#67727C',
    fontSize: '12px',
  },
  styledFormLabelThree: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#354052',
  },
  styledInput: {
    width: '100%',
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
  styledTypography: {
    paddingLeft: '3.5%',
    fontSize: '17px',
    fontFamily: 'Roboto',
    letterSpacing: '0.1px',
    lineHeight: '18px',
    fontWeight: 'lighter',
  },
  styledGridWithPadding: {
    display: 'flex',
    paddingTop: '1em',
  },
  styledFormControlMarginBottom: {
    marginBottom: '1em',
  },
  styledGridWithMargin: {
    display: 'flex',
    marginTop: '1em',
  },
  styleButtonMarginRight: {
    marginRight: '2%',
  },
  scanDocPage: {
    transform: 'translate(-50%,-50%) !important',
    backgroundColor: 'white',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: '4',
  },
  styledAddDocFormControl: {
    textAlign: 'left',
    fontSize: '14px',
  },
  styledNativeSelect: {
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
  styledLeftFormControl: {
    textAlign: 'left',
  },
  styledInput: {
    width: '100%',
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
  styledInputTwo: {
    width: '90%',
    marginTop: '0px',
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
};
