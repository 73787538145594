/**
 * Note this file was initially created to replace FormViewer.jsx in some way. However it never got there
 * and so currently this is only utilized to support the Refund Calculator
 */
// External imports
import React from 'react';
import $ from 'jquery';
import update from 'immutability-helper';
import 'jquery-ui/ui/widgets/autocomplete';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EventEmitter } from 'events';
// Internal imports
import CalcAPI from '~/app/api/calcAPI';
import SocketWorker from '~/socket.worker.js';
import { ASSET_FORM_REG_EXP, CALC_TYPE, FORM_NAMES, TABLE_REG_EXP } from '~/app/constants';
// Redux imports
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as overviewActions } from '~/app/redux/modules/overview';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';

const assetFormRegExp = /^[A]{1}[0-9]{5}/;

const mapStateToProps = state => {
  return {
    alert: state.app.alert,
    choiceListIniField: state.formViewer.choiceListIniField,
    choiceListData: state.formViewer.choiceListData,
    attachedStates: state.formViewer.attachedStates,
    ddh: state.drilldown.drilldownHistory,
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...formViewerActions,
  ...returnProfileActions,
  ...overviewActions,
  ...drilldownActions,
  ...notificationActions,
};

// TODO: We can start to obsolete CalcAPI and rely on this class to manage calc flow.
//  There are still some dependencies that prevent us from doing this now.
class CalcMutator extends React.Component {
  constructor(props) {
    super(props);

    this.callerCtx = this.props.callerCtx;
    this.mutatorCtx = this;

    this.eventEmitter = new EventEmitter();
    this.socketWorker = undefined;
    this.calcAPI = undefined;

    this.socketWorker = new SocketWorker();
    this.socketWorker.onmessage = this.props.onSocketResponse;

    this.calcAPI = new CalcAPI(this.socketWorker, this.props.returnID, this.props.ddh);

    this.valueMap = {};
    this.currentEl = undefined; // current Element

    this.clientData = {
      first_name: '',
      last_name: '',
      filing_status: undefined,
    };

    this.state = {
      formStack: [],
    };
  }

  getCalcAPI() {
    return this.calcAPI;
  }

  // useful for external refund calculator
  switchReturnID = newID => {
    this.calcAPI.switchReturnID(newID);
  };

  returnInit = async (estimator = false) => {
    let blob;
    if (estimator) {
      blob = this.props.estimatorBlob;
    }
    await this.calcAPI.ReturnInit(this.props.loginID, estimator, blob);
  };

  returnFormLoad(name, stmType, frmFile, field) {
    this.calcAPI.ReturnFormLoad(name, stmType, frmFile, field);
  }

  returnCalc(formName, assetCalc, field, val, calcType) {
    this.calcAPI.ReturnCalc(formName, assetCalc, field, val, calcType);
  }

  closeSession() {
    this.calcAPI.CloseSession();
  }

  closeSocket() {
    this.calcAPI.CloseSocket();
  }

  copyToTrainingDupeCheck() {
    this.calcAPI.CopyToTrainingDupeCheck();
  }

  copyToLiveDupeCheck() {
    this.calcAPI.CopyToLiveDupeCheck();
  }

  copyToTraining() {
    this.calcAPI.CopyToTraining();
  }

  initializeSocket() {
    this.calcAPI.InitializeSocket();
  }

  retry() {
    this.calcAPI.Retry();
  }

  returnAddForm(formOccur, tors) {
    this.calcAPI.ReturnAddForm(formOccur, tors);
  }

  returnAttachedStates() {
    this.calcAPI.ReturnAttachedStates();
  }

  returnBookmarkQuery(formOccur, fieldID) {
    this.calcAPI.ReturnBookmarkQuery(formOccur, fieldID);
  }

  returnBookmarkUpsert(formOccur, fieldID, desc, timestamp, deleteBookmark) {
    this.calcAPI.ReturnBookmarkUpsert(formOccur, fieldID, desc, timestamp, deleteBookmark);
  }

  returnChoiceList(form, field, filter = '') {
    this.calcAPI.ReturnChoiceList(form, field, filter);
  }

  returnChoiceListCalc(formOccur, field, value) {
    this.calcAPI.ReturnChoiceListCalc(formOccur, field, value);
  }

  returnClose() {
    this.calcAPI.ReturnClose();
  }

  returnDelete() {
    this.calcAPI.ReturnDelete();
  }

  returnDeleteAsset(id) {
    this.calcAPI.ReturnDeleteAsset(id);
  }

  returnDetachDocument(docID, attachVar) {
    this.calcAPI.ReturnDetachDocument(docID, attachVar);
  }

  returnMessageResponse(resp, formOccur, labelName) {
    this.calcAPI.ReturnMessageResponse(resp, formOccur, labelName);
  }

  returnNewAsset(id, type, desc, date, assetCode) {
    this.calcAPI.ReturnNewAsset(id, type, desc, date, assetCode);
  }

  returnNotesUpsert(note, deleteNote = false) {
    this.calcAPI.ReturnNotesUpsert(note, deleteNote);
  }

  returnPaymentQuery() {
    this.calcAPI.ReturnPaymentQuery();
  }

  returnPrint(type, prefs, formOccur, sigs, pAction, password) {
    this.calcAPI.ReturnPrint(type, prefs, formOccur, sigs, pAction, password);
  }

  returnPrintSignatures(formOccur, type, LPA5, LPB5, readOnly) {
    this.calcAPI.ReturnPrintSignatures(formOccur, type, LPA5, LPB5, readOnly);
  }

  rfdcalcCreateNewReturn(rtnId, rfndCalcBlob, ssn, rtnBlob = undefined) {
    this.calcAPI.RefundCalcCreateNewReturn(rtnId, rfndCalcBlob, ssn, rtnBlob);
  }

  returnProcessPaymentDetails(dateRcvd, rcvdFrom, payMethod, amt, refNum, memo, formOccur, guid) {
    this.calcAPI.ReturnProcessPaymentDetails(
      dateRcvd,
      rcvdFrom,
      payMethod,
      amt,
      refNum,
      memo,
      formOccur,
      guid,
    );
  }

  returnButtonPress(buttonID, formOccur) {
    // on the estimator, certain button events should be intercepted (not handled by calcserver)
    switch (buttonID) {
      case 'ZZ90280':
        this.props.onCloseEstimator();
        return;
      case 'ZZ90573':
        alert('start new return!');
        return;
    }
    this.calcAPI.ReturnButtonPress(buttonID, formOccur);
  }

  returnRaiseError(command, error, stacktrace) {
    this.calcAPI.ReturnRaiseError(command, error, stacktrace);
  }

  returnReassignAsset(astID, actvID) {
    this.calcAPI.ReturnReassignAsset(astID, actvID);
  }

  returnReassignAssetList(astID) {
    this.calcAPI.ReturnReassignAssetList(astID);
  }

  returnReloadBilling() {
    this.calcAPI.ReturnReloadBilling();
  }

  returnRemoveDocument(docID) {
    this.calcAPI.ReturnRemoveDocument(docID);
  }

  returnRemoveForm(formOccur) {
    this.calcAPI.ReturnRemoveForm(formOccur);
  }

  returnRemovePackage(pkg) {
    this.calcAPI.ReturnRemovePackage(pkg);
  }

  returnSave(objList) {
    this.calcAPI.ReturnSave(objList);
  }

  returnSaveAndClose(objList) {
    this.calcAPI.ReturnSaveAndClose(objList);
  }

  returnStateAck() {
    this.calcAPI.ReturnStateAck();
  }

  returnAmendedStateAck() {
    this.calcAPI.ReturnAmendedStateAck();
  }

  returnToggleStatusLock() {
    this.calcAPI.ReturnToggleStatusLock();
  }

  returnVerify() {
    this.calcAPI.ReturnVerify();
  }

  returnViewAsset(id) {
    this.calcAPI.ReturnViewAsset(id);
  }

  returnYearToYear(formOccur, priorID) {
    this.calcAPI.ReturnYearToYear(formOccur, priorID);
  }

  returnYearToYearOption(formOccur, priorID, option) {
    this.calcAPI.ReturnYearToYearOption(formOccur, priorID, option);
  }

  sendTextLinkMessage(msg) {
    this.calcAPI.SendTextLinkMessage(msg);
  }

  // DOM Methods

  getCurrentElement = () => {
    return this.currentEl;
  };

  handleMenuItemHighlight = formName => {
    if (formName === '000000') {
      if (this.callerCtx.state.activeForm === FORM_NAMES.CDS) {
        return true;
      }
      return false;
    }
    if (this.callerCtx.state.activeForm === formName) {
      return true;
    }
    return false;
  };

  updateFormStack = callerCtx => {
    const formStack = this.state.formStack;
    const lastForm = formStack[formStack.length - 1];
    if (lastForm === callerCtx.state.activeForm) {
      return;
    }
    formStack.push(callerCtx.state.activeForm);
    if (formStack.length > 5) {
      // keep track of last 5 forms, remove bottom of stak
      formStack.shift();
    }
    this.setState({
      formStack,
    });
  };

  getPreviousForm = () => {
    const formStack = this.state.formStack;
    const previousForm = formStack.pop();
    this.setState({
      formStack,
    });
    return previousForm;
  };

  hideFormButton = () => {
    const el = document.getElementById('buttonMenuForReturns');
    if (el) {
      el.style.setProperty('display', 'none');
    }
  };

  onUpdateFormError = data => {
    const field = data.initialField;
    const element = $('#' + field);
    const pval = this.valueMap[field].value;
    const pNextField = this.valueMap[field].nxtFld;
    element[0].value = pval;

    element.focus();
    element.addClass('error');

    this.updateFieldValueCache(field, pval, pNextField);
  };

  // TODO --> Insert element within the grid instead of calculating position. It may increase performance by removing the listener on window resize.
  renderButtonChoice = fieldID => {
    this.formContainerProps = document
      .getElementsByClassName('formContainer')[0]
      .getBoundingClientRect();
    this.formContainerProps.scrollTop = document.getElementsByClassName(
      'formContainer',
    )[0].scrollTop;
    const messageButton = document.getElementById('buttonMenuForReturns');
    if (messageButton) {
      let field = null;
      // eslint-disable-next-line valid-typeof
      if (typeof fieldID !== String) {
        const focusedFldID = messageButton.getAttribute('focusedField');
        field = document.getElementById(focusedFldID);
        if (focusedFldID === null || focusedFldID === '') {
          return; // Do Nothing
        }
      } else {
        field = document.getElementById(fieldID);
      }
      const coords = field.getBoundingClientRect();
      const left = coords.left - this.formContainerProps.x + coords.width + 'px';
      const top = coords.top + this.formContainerProps.scrollTop - this.formContainerProps.y + 'px';
      messageButton.style.left = left;
      messageButton.style.top = top;
      messageButton.style.height = coords.height + 'px';
      messageButton.style.setProperty('display', 'flex');
    }
  };

  // Handle change focus behavior, requires focused field ID + focused element
  // if we've just tabbed onto then off the field (field value === cache value), handle next field/focus behavior
  // if we're editing the field, (field value !=== cache value), blur() off the field to trigger calc, next field/focus behavior is handled in calc response handler
  handleFocusBehavior = (fieldID, focusedEl) => {
    if (this.valueMap[fieldID]) {
      const currValue = focusedEl.value;
      const currCacheValue = this.valueMap[fieldID].value;
      const isEdit = currValue !== currCacheValue;
      if (isEdit) {
        $(focusedEl).blur();
      } else {
        if (this.valueMap[fieldID].nxtFld.length > 0) {
          this.handleNextFld(this.currentEl);
        } else {
          this.switchFocusToNext(this.currentEl);
        }
      }
    }
  };

  // Focus on next field found in 'nextfld' attribute of a field
  handleNextFld = el => {
    const nextField = this.valueMap[el.id].nxtFld;

    if (nextField.length > 0) {
      $('#' + nextField).focus();
    } else {
      this.switchFocusToNext(el);
    }
  };

  // Focus on next cellitem input, given that you are currently focused in a cellitem input
  switchFocusToNext = el => {
    let nextInput = $(el).parent().nextUntil('.form-cellitem').children('.form_field')[0];
    let i = 1;
    if (nextInput !== undefined) {
      while (nextInput.classList.contains('hide')) {
        // Handle cases where the next element is hidden with the 'hide' class
        nextInput = $(el).parent().nextUntil('.form-cellitem').children('.form_field')[i];
        i++;
      }
      nextInput.focus();
    }
  };

  switchFocusToPrev = el => {
    let prevInput = $(el).parent().prevUntil('.form-cellitem').children('.form_field')[0];
    let i = 1;
    if (prevInput !== undefined) {
      while (prevInput.classList.contains('hide')) {
        // Handle cases where the next element is hidden with the 'hide' class
        prevInput = $(el).parent().prevUntil('.form-cellitem').children('.form_field')[i];
        i++;
      }
      prevInput.focus();
    }
  };

  // Autocomplete (similar to crosslink "tracking" feature)
  initAutocomplete = fldData => {
    const choices = fldData.Data;
    const suggestions = choices.map(data => {
      return data.ListItem[0].COL;
    });
    const _ = this;
    $('#' + fldData.InitialField.value).autocomplete({
      source: suggestions,
      change: event => {
        // only run if value has changed (field value != cache value)
        if (event.target.value !== _.valueMap[event.target.id].value) {
          const selectedIndex = suggestions.indexOf(event.target.value);
          _.getChoicelistRow(choices, selectedIndex);
        }
      },
    });
  };

  getChoicelistRow = (choiceListData, selectedIndex) => {
    const selectedRow = choiceListData[selectedIndex];
    if (typeof selectedRow !== 'undefined') {
      this.handleChoiceListItem(selectedRow.ListItem);
    }
  };

  handleChoiceListItem = choiceItem => {
    let tempString = '';
    choiceItem.forEach(data => {
      tempString = tempString + data.COL + '\t';
    });
    // last row, remove last two characters "\t"
    tempString = tempString.slice(0, -1);

    this.returnChoiceListCalc(
      this.callerCtx.state.activeForm,
      this.props.choiceListIniField,
      tempString,
    );
    this.props.toggleChoiceList(false);
  };

  resetFieldOnKeyPress = () => {
    $('.form_field').one('keydown', e => {
      if (e.target.classList.contains('read-only') || e.target.classList.contains('restricted')) {
        return;
      }
      // no clear on tab, enter, alt, shift, esc
      const keys = [9, 13, 18, 16, 27];
      if (!keys.includes(e.which)) {
        e.target.value = '';
      }
      if (e.which === 13) {
        $('#' + e.target.id).data('val', $('#' + e.target.id).val());
      }
    });
  };

  hideGroups = argString => {
    const args = argString.split(':');
    for (let i = 0; i < args.length; i++) {
      this.setGroupVisibility(args[i], false);
    }
  };

  // Guarantee all groups have some initial value set
  //  DefaultGroupState is a map of the form [groupID]->{checked: [true or false], args: []groupID }
  initializeGroups = defaultGroupState => {
    // iterate through all group trees to search for some checked value
    //  if no default exists in the group, default to the first member
    const excluded = [];
    for (const key in defaultGroupState) {
      if (excluded.includes(key)) continue; // we already checked this key, either directly or in an arg chain
      const value = defaultGroupState[key];
      const args = value.args.split(':');
      // is this value checked?
      if (value.checked) {
        // yes, so the entire group has a default - exclude all of them
        excluded.push(key);
        args.forEach(a => excluded.push(a));
        continue;
      } else {
        // no, are any of our args checked?
        let argchecked = false;
        for (const argIdx in args) {
          const argField = $('#' + args[argIdx]);
          if (argField.length) {
            if (argField[0].checked) {
              argchecked = true;
              break;
            }
          }
        }
        if (argchecked) {
          // yes, so the entire group has a default - exclude all of them
          excluded.push(key);
          args.forEach(a => excluded.push(a));
        } else {
          // no, so this group has no default - choose the default and exclude our args
          const field = $('#' + key)[0];
          field.click();
          args.forEach(a => excluded.push(a));
        }
      }
    }
  };

  // Toggles group when tax form is set
  toggleGroup = (e, toggleGroupNumber, event) => {
    if (event.target.value !== 'x' && event.target.value !== 'X' && event.target.value !== '') {
      // Reset Value to blank if not an x.
      const grp = document.getElementById(event.target.id);
      grp.value = '';
      this.showErrorSnackbar('Invalid Keypress. Enter X or Blank Only.');
      return;
    }
    if (e) {
      const args = e.getAttribute('args').split(':');
      // uncheck all groups in args
      for (let i = 0; i < args.length; i++) {
        this.setGroupVisibility(args[i], false);
      }
      // toggle this group
      const members = $('.form-group-' + toggleGroupNumber);
      if (event.target.value === 'X') {
        members.addClass('hide');
        // if we unchecked everything, set default to the next exclusive group
        this.setGroupVisibility(args[0], true);
      } else {
        members.removeClass('hide');
      }
    }
  };

  // Sets visibility of group items
  setGroupVisibility(groupID, isVisible) {
    const grpNum = groupID.substring(3, 4); // use grp number (grp<num><occurence>)
    const members = $('.form-group-' + grpNum);
    if (isVisible) {
      members.removeClass('hide');
    } else {
      members.addClass('hide');
    }
  }

  handleGroupFields = (field, value) => {
    if (value === 'X') {
      this.setGroupVisibility(field, true);
    } else {
      this.setGroupVisibility(field, false);
    }
  };

  updateFieldValueCache = (field, value, nextField) => {
    const vMap = this.valueMap;
    if (vMap[field] === undefined) {
      this.valueMap = {
        ...vMap,
        [field]: {
          previousValue: '',
          value,
          nxtFld: nextField,
        },
      };
    } else {
      const pVal = vMap[field].value;
      const pNxtFld = vMap[field].nxtFld;
      if (pVal === value && pNxtFld === nextField) return; // do nothing on no update
      vMap[field] = {
        previousValue: pVal,
        value,
        nxtFld: nextField,
      };
      this.valueMap = vMap;
    }
  };

  getFieldValueCache = () => {
    return this.valueMap;
  };

  // accepts array of {field:string, value:string}
  UpdateForm = data => {
    // document.querySelectorAll(".form_field").forEach((function(obj, i){obj.style.setProperty("--background","green")}))
    console.log('UpdateForm() called with data: ' + JSON.stringify(data));
    if (!data.fields) {
      console.log('Nothing to update');
      return;
    }
    let popCount = 0;
    let skipCount = 0;
    for (const fld in data.fields) {
      const field = fld;
      const value = data.fields[fld].value;
      let nxtFld = data.fields[fld].nextFld;
      if (typeof nxtFld === 'undefined') {
        nxtFld = '';
      }
      this.updateFieldValueCache(field, value, nxtFld);
      this.InspectFormData(field, value);
      const element = document.getElementById(field);
      if (element) {
        popCount++;
        element.value = value;
        element.style.setProperty('--background', this.callerCtx.colorMap[data.fields[fld].color]);
        const containsButtonIconClass = element.classList.contains('form-button-icon');
        if (containsButtonIconClass) {
          const svgid = element.getAttribute('svgid');
          element.querySelector('svg').setAttribute('width', '100%');
          // $(`#${svgid} text`).attr("font-size", "11");
          // element
          //   .querySelector("#" + svgid + " text")
          //   .setAttribute("font-size", "11");
          const isWide = element.getAttribute('wide');
          if (isWide) {
            if (element.value === 'X') {
              element.classList.add('form-button-icon-active-wide');
              document
                .querySelector(`#${svgid} g g g path`)
                .setAttribute('transform', 'rotate(90)  translate(-5, -10)');
            } else {
              element.classList.remove('form-button-icon-active-wide');
            }
          } else {
            element.value === 'X'
              ? element.classList.add('form-button-icon-active')
              : element.classList.remove('form-button-icon-active');
          }
          const containsActiveClass =
            element.classList.contains('form-button-icon-active') ||
            element.classList.contains('form-button-icon-active-wide');
          if (containsActiveClass) {
            if (isWide) {
              document
                .querySelector(`#${svgid} g g g path`)
                .setAttribute('transform', 'rotate(90)  translate(-5, -10)');
            } else {
              $(`#${svgid} g`).attr('fill', '#d7ecf7');
              // document
              //   .querySelector("#" + svgid + " g")
              //   .setAttribute("fill", "#d7ecf7"); //Set to selected color
            }
          } else {
            if (!isWide) {
              $(`#${svgid} g`).attr('fill', '#FEFEFE');
              // document
              //   .querySelector("#" + svgid + " g")
              //   .setAttribute("fill", "#FEFEFE"); //Set back to defualt Color
            }
          }
        }
        // handle grp items on form update - should not need to call initializeGroups() since CS should return all necessary values on form updates
        if (field.includes('grp')) {
          this.handleGroupFields(field, value);
        }
        // Keep track of the max table row for subFrm type forms - used for automatic form_load for autoextend forms
        if (TABLE_REG_EXP.test(field) && this.subFrm !== '') {
          const curTableRow = parseInt(field.substring(2, 4));
          if (curTableRow > this.maxTableRow) {
            this.maxTableRow = curTableRow;
          }
        }
        // handle grp items NOT on the form
      } else if (field.includes('grp')) {
        this.handleGroupFields(field, value);
      } else {
        skipCount++;
      }
    }
    console.log('Updated ' + popCount + ' fields, skipped ' + skipCount + ' fields');
  };

  PopulateForm = async data => {
    const flds = data.fields;
    let popCount = 0;
    let skipCount = 0;
    const defaultGroupState = {};
    for (const fld in flds) {
      const elementID = fld;
      const aVAL = flds[fld].value;
      let nxtFld = flds[fld].nextFld;
      if (typeof nxtFld === 'undefined') {
        nxtFld = '';
      }
      this.InspectFormData(elementID, aVAL);
      // NOTE: elementID MUST be string-quoted to succesfully select IDs with dollar signs
      const element = $(`[id="${elementID}"]`)[0];
      if (element) {
        element.style.setProperty('--background', this.callerCtx.colorMap[flds[fld].color]);
        this.updateFieldValueCache(elementID, aVAL, nxtFld);
        popCount++;
        if (element.classList.contains('form-checkbox-input') || element.type === 'radio') {
          element.value = aVAL === 'X' ? 'X' : '';
          // Does this checkbox control a group?
          if (element.id.includes('grp')) {
            // If it's checked, make group visible by default
            if (element.value === 'X') {
              // this.hideGroups(element.getAttribute("args"))
              this.setGroupVisibility(element.id, true);
              defaultGroupState[element.id] = {
                value: 'X',
                args: element.getAttribute('args'),
              };
            } else {
              defaultGroupState[element.id] = {
                value: '',
                args: element.getAttribute('args'),
              };
            }
          }
        }
        element.value = aVAL;
        // Keep track of the max table row for subFrm type forms - used for automatic form_load for autoextend forms
        if (TABLE_REG_EXP.test(elementID) && this.subFrm !== '') {
          const curTableRow = parseInt(elementID.substring(2, 4));
          if (curTableRow > this.maxTableRow) {
            this.maxTableRow = curTableRow;
          }
        }
        // handle group items NOT on form
      } else if (elementID.includes('grp')) {
        this.handleGroupFields(elementID, aVAL);
      } else {
        skipCount++;
        console.log('Skipping ' + elementID);
      }
    }
    this.initializeGroups(defaultGroupState);
    console.log('Populated ' + popCount + ' fields, skipped ' + skipCount + ' fields');
  };

  InspectFormData = (field, value) => {
    // store fname and lname for client data
    switch (field) {
      case 'FLST00':
        if ((value || value === '') && value !== this.clientData.filing_status) {
          this.clientData.filing_status = value;
          this.props.setClientData({
            filing_status: this.clientData.filing_status,
          });
        }
        break;
      case 'PNMA00':
        if (value !== this.clientData.last_name) {
          this.clientData.first_name = value;
          this.props.setClientData({
            first_name: value,
            last_name: this.clientData.last_name,
          });
        }
        break;
      case 'PNMC00':
        if (value !== this.clientData.last_name) {
          this.clientData.last_name = value;
          this.props.setClientData({
            first_name: this.clientData.first_name,
            last_name: value,
          });
        }
        break;
    }
  };

  bindFormEvents(callerCtx) {
    const mutatorCtx = this;
    // Prevents from firing off multiple times .off('click')
    $('.form-button-element')
      .off('click')
      .on('click', function (e) {
        e.preventDefault();
        mutatorCtx.updateFormStack(callerCtx);
        // This is being called on any button being pressed. We need to send the ID for it to map back to a command
        mutatorCtx.returnButtonPress(e.currentTarget.id, callerCtx.state.activeForm);
      });

    $('.form-button-icon')
      .off('click')
      .on('click', function (e) {
        e.preventDefault();
        const containsActiveClass =
          e.currentTarget.classList.contains('form-button-icon-active') ||
          e.currentTarget.classList.contains('form-button-icon-active-wide');
        const svgid = e.currentTarget.getAttribute('svgid');
        if (containsActiveClass) {
          $(e.currentTarget).val('');
          e.currentTarget.classList.remove('form-button-icon-active');
          e.currentTarget.classList.remove('form-button-icon-active-wide');
          // $(`#${svgid} g`).attr("fill", "#FEFEFE");
          // document
          //   .querySelector("#" + svgid + " g")
          //   .setAttribute("fill", "#FEFEFE"); //Set back to defualt Color
        } else {
          $(e.currentTarget).val('X');
          if (e.currentTarget.wide) {
            e.currentTarget.classList.add('form-button-icon-active-wide');
            document
              .querySelector(`#${svgid} g g g path`)
              .setAttribute('transform', 'rotate(90)  translate(-5, -10)');
          } else {
            e.currentTarget.classList.add('form-button-icon-active');
          }
          // $(`#${svgid} g`).attr("fill", "#d7ecf7");
          // document
          //   .querySelector("#" + svgid + " g")
          //   .setAttribute("fill", "#d7ecf7"); //Set to selected color
        }
        mutatorCtx.returnCalc(
          callerCtx.state.activeForm,
          ASSET_FORM_REG_EXP.test(callerCtx.state.activeForm) ? 'X' : '',
          e.currentTarget.id,
          $(e.currentTarget).val(),
          CALC_TYPE.FIELD_CALC,
        );
      });

    $('.field_lookup')
      .one('focus', e => {
        if (
          !(e.target.classList.contains('read-only') || e.target.classList.contains('restricted'))
        ) {
          mutatorCtx.returnChoiceList(callerCtx.state.activeForm, e.target.id);
        }
      })
      .keydown(function (e) {
        if (e.target.classList.contains('read-only') || e.target.classList.contains('restricted')) {
          return;
        }
        if (e.altKey && e.which === 67) {
          // alt+c
          callerCtx.openChoiceList(callerCtx.state.activeForm, e.target.id);
        }
      });

    // Worksheet handling
    $('.field_wks').keydown(function (e) {
      if (e.altKey && e.which === 87) {
        // alt+w
        const form = callerCtx.state.activeForm + e.target.getAttribute('stmcode');
        callerCtx.getTaxForm(form, 'wks', '', mutatorCtx.currentEl.id);
      }
    });

    // Statement handling
    $('.field_stm').keydown(function (e) {
      if (e.altKey && e.which === 87) {
        const form = callerCtx.state.activeForm + e.target.getAttribute('stmcode');
        callerCtx.getTaxForm(form, 'stm', '', mutatorCtx.currentEl.id);
      }
    });

    // Overflow handling
    $('.field_ovl').keydown(function (e) {
      if (e.altKey && e.which === 79) {
        // alt+o
        const hasStm = e.target.classList.contains('field_stm');
        const hasWks = e.target.classList.contains('field_wks');
        if (hasStm) {
          const form = callerCtx.state.activeForm + e.target.getAttribute('stmcode');
          callerCtx.getTaxForm(form, 'stm', '', mutatorCtx.currentEl.id);
        } else if (hasWks) {
          const form = callerCtx.state.activeForm + e.target.getAttribute('stmcode');
          callerCtx.getTaxForm(form, 'wks', '', mutatorCtx.currentEl.id);
        } else {
          callerCtx.getTaxForm(callerCtx.state.activeForm, 'ovl', '', mutatorCtx.currentEl.id);
        }
      }
    });

    // DoubleClick handling
    $('.form-checkbox-input').dblclick(e => {
      e.preventDefault();
      let value = '';
      if (e.target.value === '') {
        value = 'X';
      }
      this.getCalcAPI().ReturnCalc(
        callerCtx.state.activeForm,
        assetFormRegExp.test(callerCtx.state.activeForm) ? 'X' : '',
        e.target.id,
        value,
        CALC_TYPE.FIELD_CALC,
      );
    });

    // Form Fields Changing
    $('.form_field')
      .change(function (e) {
        callerCtx.setState({
          prevField: this.id,
          prevFocusedFieldVal: $('#' + e.target.id).data('val'),
          currFieldBeforeChangeVal: $('#' + e.target.id).val(),
        });
      })
      .focus(function () {
        mutatorCtx.resetFieldOnKeyPress();
      })
      .focusin(e => {
        const el = document.getElementById('buttonMenuForReturns');
        if (el) {
          el.style.setProperty('display', 'flex');
          el.setAttribute('focusedField', e.target.id);
        }
        callerCtx.setState({
          menuButtonsChoices: [
            {
              name: 'Clear Override',
              func: () =>
                mutatorCtx.returnCalc(
                  callerCtx.state.activeForm,
                  ASSET_FORM_REG_EXP.test(callerCtx.state.activeForm) ? 'X' : '',
                  e.target.id,
                  e.target.value,
                  CALC_TYPE.OVERRIDE_CLEAR,
                ),
            },
            {
              name: 'Bookmark Field',
              func: () => {
                mutatorCtx.returnBookmarkQuery(callerCtx.state.activeForm, e.target.id);
                callerCtx.setState({
                  bookmarks: {
                    ...callerCtx.state.bookmarks,
                    bookmarkFldID: e.target.id,
                  },
                });
              },
            },
          ],
        });
        // Display field prompt
        const spanEl = $(e.target).siblings('span');
        spanEl.addClass('transition');
        spanEl.parent('div').css('z-index', '6');
        callerCtx.nextField = $(e.target).attr('nextfld');
        mutatorCtx.currentEl = e.target;

        if ($(e.target).attr('stmcode') !== undefined) {
          callerCtx.stmcode = true;
        } else {
          callerCtx.stmcode = false;
        }
        if (e.target.getAttribute('stmcode') && this.subFrm === '') {
          const form = callerCtx.state.activeForm + e.target.getAttribute('stmcode');
          const stm = e.target.classList.contains('field_stm') ? 'stm' : 'wks';
          const newBtnState = update(callerCtx.state.menuButtonsChoices, {
            $push: [
              {
                name: 'Worksheet',
                func: () => callerCtx.getTaxForm(form, stm, '', this.currentEl.id),
              },
            ],
          });
          callerCtx.setState({
            menuButtonsChoices: newBtnState,
          });
          callerCtx.showPromptSnackbar('Press alt+w for worksheet');
        } else {
          callerCtx.hidePromptSnackbar();
        }
        if (
          e.target.getAttribute('choices') &&
          !(e.target.classList.contains('read-only') || e.target.classList.contains('restricted'))
        ) {
          const newBtnState = update(callerCtx.state.menuButtonsChoices, {
            $push: [
              {
                name: 'Choices',
                func: () => callerCtx.openChoiceList(callerCtx.state.activeForm, e.target.id),
              },
            ],
          });
          callerCtx.setState({
            menuButtonsChoices: newBtnState,
          });
          callerCtx.showPromptSnackbar('Press alt+c for choices');
          callerCtx.choices = true;
        } else {
          callerCtx.hidePromptSnackbar();
          callerCtx.choices = false;
        }
        $('#' + e.target.id).data('val', $('#' + e.target.id).val());
        // Move the form button to the target element
        mutatorCtx.renderButtonChoice(e.target.id);
      })
      .focusout(e => {
        callerCtx.previousEl = e.target;
        $('#' + e.target.id).removeClass('error'); // remove any error that may exist on the field
        // Hide field prompt
        const spanEl = $(e.target).siblings('span');
        spanEl.removeClass('transition');
        spanEl.parent('div').css('z-index', '1');
        const vMap = this.valueMap;
        const pVal = vMap[e.target.id].value;
        if (pVal !== e.target.value) {
          mutatorCtx.returnCalc(
            callerCtx.state.activeForm,
            ASSET_FORM_REG_EXP.test(callerCtx.state.activeForm) ? 'X' : '',
            e.target.id,
            e.target.value,
            CALC_TYPE.FIELD_CALC,
          );
          // If on a statement subform and editing the last table row of the form, reload the form to get autoextended rows if they exist
          let currentRow = 0;
          if (TABLE_REG_EXP.test(e.target.id)) {
            currentRow = parseInt(e.target.id.substring(2, 4));
          }
          if (
            callerCtx.subFrm !== '' &&
            callerCtx.subFrm !== 'ovl' &&
            TABLE_REG_EXP.test(e.target.id) &&
            currentRow === callerCtx.maxTableRow
          ) {
            const form = callerCtx.state.activeForm;
            callerCtx.getTaxForm(
              form,
              'stm',
              callerCtx.state.activeFile,
              mutatorCtx.currentEl.id,
              false,
            );
          }
        }

        callerCtx.stmcode = false;
        callerCtx.choices = false;
        callerCtx.hidePromptSnackbar();
        // Keypress handlers
      })
      .keydown(e => {
        const field = e.target.id;
        switch (e.which) {
          case 32: // spacebar
            if (e.target.classList.contains('form-checkbox-input')) {
              e.preventDefault();
              if (e.target.value === 'X') {
                $(e.target).val('');
                mutatorCtx.switchFocusToNext(e.target);
              } else {
                $(e.target).val('X');
                mutatorCtx.switchFocusToNext(e.target);
              }
            }
            break;
          case 9: // tab
            e.preventDefault();

            // shift + tab should focus backwards
            if (e.shiftKey) {
              mutatorCtx.switchFocusToPrev(e.target);
            } else {
              mutatorCtx.handleFocusBehavior(field, e.target);
            }
            break;
          case 13: // enter
            mutatorCtx.handleFocusBehavior(field, e.target);
            break;
          case 27: // esc
            // if we've just tabbed onto the field (field value === cache value), restore previous value
            // if we're editing the field, (field value !=== cache value), restore current cache value

            // eslint-disable-next-line no-case-declarations
            const element = $('#' + field);
            if (callerCtx.valueMap[field]) {
              const currValue = element[0].value;
              const currCacheValue = callerCtx.valueMap[field].value;
              const isEdit = currValue !== currCacheValue;
              if (isEdit) {
                element[0].value = currCacheValue;
              } else {
                element[0].value = callerCtx.valueMap[field].previousValue;
              }
            }
            // recalc occurs on focusout
            break;
          default:
            break;
        }
      });
  }

  unbindFormEvents() {
    $('.form-button-element').off();
    $('.form-button-icon').off();
    $('.field_lookup').off();
    $('.field_wks').off();
    $('.field_stm').off();
    $('.field_ovl').off();
    $('.form_field').off();
  }

  render() {
    return <input type="hidden" />;
  }
}

CalcMutator.propTypes = {
  loginID: PropTypes.number.isRequired,
  returnID: PropTypes.number.isRequired,
  onSocketResponse: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CalcMutator);
